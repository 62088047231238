import React, {Component} from "react";
import {Panel, Checkbox} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";

export default class GatewayModal extends Component {
    gewtGatewayInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onSelectChange,
            validationHandle,
            onCheckBoxChange
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "gatewayType"
                    controlId = "gatewayType"
                    label = {langText.Body.GatewayType}
                    type = "select"
                    value = {state.gatewayType}
                    onChange = {e => onSelectChange(e, "gatewayType")}
                    options = {state.gatewayTypeSelect}
                    isClearable = {true}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("gatewayType")}/>
            );
        } else {
            let gatewayTypeName = "";
            if (state.gatewayTypeSelect) {
                let tmpObj = state.gatewayTypeSelect.find(row => row.value === state.gatewayType);
                gatewayTypeName = tmpObj ? tmpObj.label : "";
            }

            itemArr.push(
                <InlineForm.static
                    key = "gatewayType"
                    controlId = 'gatewayType'
                    label = {langText.Body.GatewayType}>
                    {gatewayTypeName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "gatewayName"
                    controlId = "gatewayName"
                    label = {langText.Body.GatewayName}
                    type = "text"
                    value = {state.gatewayName}
                    onChange = {e =>onTextChange_Limit(e, "gatewayName",charaLimit.GW_gatewayName)}
                    validationState = {validationHandle("gatewayName")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "gatewayName"
                    controlId = 'gatewayName'
                    label = {langText.Body.GatewayName}>
                    {state.gatewayName}
                </InlineForm.static>
            );
        }
        
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "ipaddress"
                    controlId = "ipaddress"
                    label = {langText.Body.GatewayIpaddress}
                    type = "text"
                    value = {state.ipaddress}
                    onChange = {e =>onTextChange_Limit(e, "ipaddress",charaLimit.GW_ipaddress)}
                    validationState = {validationHandle("ipaddress")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "ipaddress"
                    controlId = 'ipaddress'
                    label = {langText.Body.GatewayIpaddress}>
                    {state.ipaddress}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "port"
                    controlId = "port"
                    label = {langText.Body.GatewayPort}
                    type = "text"
                    value = {state.port}
                    onChange = {onIntChange("port",charaLimit.GW_port)}
                    validationState = {validationHandle("port")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "port"
                    controlId = 'port'
                    label = {langText.Body.GatewayPort}>
                    {state.port ? state.port : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        if(state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "backNumberDial"
                    controlId = "backNumberDial"
                    label = {langText.Body.BackNumberDial}
                >
                    <Checkbox
                        key = "backNumberDial"
                        className = "backNumberDial"
                        checked = {state.backNumberDial}
                        onChange = {e => onCheckBoxChange(e, "backNumberDial")}
                    >
                        {langText.Body.BackNumberDialUse}
                    </Checkbox>
                </InlineForm>
            )
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "backNumberDial"
                    controlId = "backNumberDial"
                    label = {langText.Body.BackNumberDial}
                >
                    {
                        state.backNumberDial
                        ? langText.Body.BackNumberDialUse
                        : langText.SelectOption.Unset
                    }
                </InlineForm.static>
            )
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}
                    componentClass = "textarea"
                    value = {state.memo}
                    onChange = {e =>onTextChange_Limit(e, "memo",charaLimit.GW_memo)}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = 'memo'
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.GatewaySetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.gewtGatewayInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}