import React from "react";
import AscComponent from '../../../components/AscComponent';
import {Panel, Col} from "react-bootstrap";
import AscModal from "../../Elements/Modal/Modal";
import InlineForm from "../../Elements/FromGroup/InlineForm";
import "../../Elements/FromGroup/form_select.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CallAnalysisSummaryTemplateCheckModal from "./CallAnalysisSummaryTemplateCheckModal";


export default class CallAnalysisSummaryTemplateModal extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        }
    }

    componentDidMount() {
        this.setState({ modalType: this.props.state.modalType })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.state.modalType !== this.props.state.modalType) {
            this.setState({ modalType: this.props.state.modalType })
        }
    }

    getTemplateInfoItem = () => {
        let {
            state,
            langText,
            onTextChange_Limit,
            validationHandle,
            onSelectChange,
            charaLimit
        } = this.props;

        let itemArr = [];

        // テンプレート名
        itemArr.push(
            <InlineForm
                key="templateName"
                controlId="templateName"
                label={langText.Body.TemplateName}
                type="text"
                value={state.templateName}
                onChange={e => onTextChange_Limit(e, "templateName", charaLimit.Template_Name)}
                validationState={validationHandle("templateName")}/>
        );

        // 期間指定
        itemArr.push(
            <InlineForm
                key="periodSetting"
                controlId="periodSetting"
                label={langText.Body.PeriodSettring}
                validationState={validationHandle("periodSetting")}
            >
                <Select
                    value={state.periodSetting}
                    onChange={e => onSelectChange(e, "periodSetting")}
                    options={state.summary_period_select}
                />
            </InlineForm>
        );

        return itemArr;
    }

    getTemplateDateItem = () => {
        let {
            state,
            langText,
            validationHandle,
            onSelectChange,
            onMultiSelectChange,
            onKeyDownIntCheck,
            onTimeChange
        } = this.props;

        let itemArr = [];

        itemArr.push(
            <InlineForm
                key="date"
                controlId="date"
                validationState={validationHandle("date")}
            >
                <Col className="padding-left-0" xs={12} md={12}>
                    <Col className="padding-left-0" sm={12} md={4} lg={2}>
                        <DatePicker
                            popperPlacement="top"
                            onChange={e => onSelectChange(e, "start_datetime")}
                            selected={state.start_datetime ? state.start_datetime : null}
                            dateFormat="YYYY/MM/DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            placeholderText={langText.Body.StartDate}
                            maxDate={state.end_datetime ? state.end_datetime : null}
                            onKeyDown={e => {e.preventDefault()}}
                            disabled={state.periodSetting.value !== "custom"}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 from-to">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col className="padding-left-0" sm={12} md={4} lg={2}>
                        <DatePicker
                            popperPlacement="top"
                            onChange={e => onSelectChange(e, "end_datetime")}
                            selected={state.end_datetime ? state.end_datetime : null}
                            dateFormat="YYYY/MM/DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            placeholderText={langText.Body.EndDate}
                            minDate={state.start_datetime ? state.start_datetime : null}
                            onKeyDown={e => {e.preventDefault()}}
                            disabled={state.periodSetting.value !== "custom"}
                        />
                    </Col>
                    <Col sm={12} md={4} lg={6} className="padding-right-0">
                        <InlineForm 
                            key="SummaryPeriodWeekday"
                            controlId="SummaryPeriodWeekday"
                            type="select"
                            value={state.weekday_selected}
                            onChange={e => onMultiSelectChange(e, "weekday_selected", null, true)}
                            options={state.weekday_select}
                            isMulti={true}
                            placeholder={langText.SelectOption.Placeholder_Weekday_All_Select}
                            validationState={validationHandle("weekday_selected")}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        itemArr.push(
            <InlineForm
                key="time"
                controlId="time"
                validationState={validationHandle("time")}
                label={langText.Body.CallTimeZone}
            >
                <Col className="padding-left-0" xs={12} md={12}>
                    <Col className="padding-left-0" sm={4} md={2} lg={2}>
                        <InlineForm
                            key="start_ji"
                            controlId="start_ji"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "start_ji", null, true)}
                            value={state.start_ji}
                            validationState={validationHandle("template_start_ji", null)}
                            placeholder={langText.Body.Hour}
                        />
                    </Col>
                    <Col sm={12} md={1} lg={1} className="padding-left-0 padding-right-0 colon">
                        <span>{langText.Body.Colon}</span>
                    </Col> 
                    <Col className="padding-left-0" sm={4} md={2} lg={2}>
                        <InlineForm
                            key="start_hun"
                            controlId="start_hun"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "start_hun", null, true)}
                            value={state.start_hun}
                            validationState={validationHandle("template_start_hun", null)}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 from-to">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col className="padding-left-0" sm={4} md={2} lg={2}>
                        <InlineForm
                            key="end_ji"
                            controlId="end_ji"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "end_ji", null, true)}
                            value={state.end_ji}
                            validationState={validationHandle("template_end_ji", null)}
                            placeholder={langText.Body.Hour}
                        />
                    </Col>
                    <Col sm={12} md={1} lg={1} className="padding-left-0 padding-right-0 colon">
                        <span>{langText.Body.Colon}</span>
                    </Col> 
                    <Col className="padding-left-0" sm={4} md={2} lg={2}>
                        <InlineForm
                            key="end_hun"
                            controlId="end_hun"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "end_hun", null, true)}
                            value={state.end_hun}
                            validationState={validationHandle("template_end_hun", null)}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        return itemArr;
    }

    onClickHandle = () => {
        this.props.onClick(this.state.modalType, this.props.state.compare_flag);
        this.setState({
            show: false
        });
    }

    render() {
        return (
            <div className="CallAnalysisTemplateModal">
                <AscModal
                    state={this.props.state}
                    propSetState={this.props.propSetState}
                    langText={this.props.langText}
                    validationHandle={this.props.validationHandle}
                    customMsg={this.props.langText.Body.Check}
                    customFunc={() => {
                        this.setState({
                            show: true
                        });
                    }}    
                >
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.getTitle()}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            {this.getTemplateInfoItem()}
                            {this.getTemplateDateItem()}
                        </Panel.Body>
                    </Panel>
                </AscModal>
                <CallAnalysisSummaryTemplateCheckModal
                    userInfo={this.props.userInfo}
                    state={this.state}
                    propState={this.props.state}
                    propSetState={this.propSetState}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                    onClick={this.onClickHandle}
                    getFilterData={this.props.getFilterData}
                    currentPermission={this.props.currentPermission}
                    validationHandle={this.props.validationHandle}
                    onChange={this.props.onChange}
                    getScopeGreaterEqual={this.props.getScopeGreaterEqual}
                    reactContainerPath={this.props.reactContainerPath}
                    getTitle={this.props.getTitle}
                />
            </div>
        );
    }
}
