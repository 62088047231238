import React, { Component } from "react";
import {Button, Glyphicon} from "react-bootstrap";
import Select, { components } from "react-select";

export default class TemplateSelect extends Component {
    render() {
        const {
            state,
            onChange,
            langText,
            modalShow,
            compareFlag,
            ...props
        } = this.props;

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props} className="dropdown-indicator-container">
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={modalShow(null, "templateUpdate", compareFlag)}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={modalShow(null, "templateCopy", compareFlag)}
                            className="control-button"
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={modalShow(null, "templateDelete", compareFlag)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    </components.DropdownIndicator>
                )
            );
        }

        const selected = compareFlag ? state.compareTemplateSelected : state.templateSelected;
        return (
            <Select
                key="templateSelect"
                value={compareFlag ? state.compareTemplateSelected : state.templateSelected}
                isClearable
                onChange={
                    e => onChange(e, "templateSelected", compareFlag)
                }
                components={
                    selected ? { DropdownIndicator } : null
                }
                options={state.templateSelect}
                placeholder={langText.SelectOption.Placeholder_Template}
                {...props}
            />
        );
    }
}
