import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl, Dropdown} from 'react-bootstrap'
import InlineForm from "../Elements/FromGroup/InlineForm"
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import ExternalNumberModal from './ExternalNumberModal'
import * as GlobalConst from "../../components/AscConstants";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { CSVLink } from "react-csv";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";


export default class ExternalNumber extends AscComponent {
    constructor(props) {
        super(props)

        this.departmentInfoSet = this.departmentInfoSet.bind(this);
        this.getDepartmentSelect = this.getDepartmentSelect.bind(this);
        this.departmentInfos_onTextChange_Limit = this.departmentInfos_onTextChange_Limit.bind(this);

        // data
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            gatewaySelect: [],
            companySelect: [],
            departmentSelect: [],
            departmentSelectArr: [],
            csvDepartmentSelect: [],
            departmentInfos: [],
            floorSelect: [],
            csvFloorSelect: [],
            lineTypeSelect: [],
            inboundTypeSelect: this.getSelectOption('external_inbound_group_type', this.props.langText.SelectOption),
            inboundGroupSelect: [],
            businessCalendarSelect: [],
            waitNumberSoundSelect: [],
            inboundActionSelect: this.getSelectOption('inbound_action', this.props.langText.SelectOption),
            listSelect: [],
            listOutSoundSelect: [],
            callOverSoundSelect: [],
            callOverActionSelect: this.getSelectOption('call_over_action', this.props.langText.SelectOption),
            callOverActionControlSelect: this.getSelectOption('call_over_action_control', this.props.langText.SelectOption),
            beforeDialSoundSelect: [],
            UnconnectedActionSelect: this.getSelectOption('unconnected_action', this.props.langText.SelectOption),
            UnconnectedSoundSelect: [],
            timeoutPlaybackSelect: this.getSelectOption('timeout_playback', this.props.langText.SelectOption),
            DialRestrictionSelect : this.getSelectOption('dial_restriction', this.props.langText.SelectOption),
            timeoutSoundSelect: [],
            anonymousActionSelect: this.getSelectOption('anonymous_action', this.props.langText.SelectOption),
            anonymousSoundSelect: [],
            waitCallTimeoutSoundSelect: [],
            callResultsSelect: [],
            keywordGroupHintSelect: [],
            keywordGroupFillerSelect: [],

            // data item
            external_id: '',
            gateway_id: [],
            gateway_name: '',
            company_id: '',
            company_name: '',
            department_id: '',
            department_name: '',
            floor_id: '',
            floor_name: '',
            display_number: '',
            back_number: [],
            channel_number: '',
            wait_number: '',
            sound_id: '',
            prefix: '',
            memo: '',
            description: '',
            inbound_type: '',
            inbound_group_id: [],
            inbound_group_name: '',
            business_calendar_id: '',
            business_calendar_name: '',
            inboundGroupcount: 0,
            // prefix_searchflag: true,
            // prefix_duplicationflag: false,
            inbound_group_base: [],
            inbound_group_cm51ids: [],
            call_results: [],
            keyword_groups_hint: [],
            keyword_groups_filler: [],

            // button_disabled
            buttondisabledArr: {
                "gateway_id" : [],
                "company_id" : [],
                "department_id" : [],
                "inbound_group_id" : [],
                "business_calendar_id" : [],
                "floor_id" : [],
                "sound_id" : [],
                "list_id" : [],
                "call_results_id": [],
                "gateway_load" : [],
                "department_load" : [],
                "inbound_group_load" : [],
                "business_calendar_load" : [],
                "floor_load" : [],
                "sound_load" : [],
                "list_load" : [],
                "call_results_load": [],
                "keyword_groups_hint" : [],
                "keyword_groups_filler" : [],
                "keyword_groups_load": []
            },
            omni_phone_back_ground_display_color_picker: false,
            omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color,
            omni_phone_font_display_color_picker: false,
            omni_phone_font_color: GlobalConst.omni_phone_font_color,
            before_omni_phone_back_ground_color: '',
            before_omni_phone_font_color: '',

            listAppointInboundFlag: false,
            list_id: '',
            list_sound_id: '',
            list_out_action: '',
            list_name: '',
            list_count: 0,
            before_dial_sound_id: '',
            call_over_sound_id: '',
            call_over_action: '',
            display_number_validation_flag: true,
            back_number_validation_flag: true,
            previous_back_number: null,

            unconnected_sound_id: '',
            unconnected_action: '',
            anonymous_sound_id: '',
            anonymous_action: '',

            timeout_sec: '',
            cm35_id_timeout_sound: '',
            timeout_playback_flag: '',
            dial_restriction_flag: '',
            acd_flag: '',
            waitCallTimeoutUseFlag: false,
            waitCallTimeoutSeconds: '',
            waitCallTimeoutSoundId: '',
            cm35IdWaitCallTimeoutSound: '',

            extension_unconnected_sound_url: '',
            list_sound_url: '',
            timeout_sound_url: '',
            wait_number_sound_url: '',
            anonymous_sound_url: '',
            waitCallTimeoutSoundUrl: '',
            dispensing_time: null,
            dispensing_count: null,
            dispensing_flag: null,
            voice_log_expire_external_number_select: this.getSelectOption("voice_log_expire_external_number", this.props.langText.SelectOption),
            registeredBackNumber: [],

            // ACD
            addingOptionACD: false,

            // callAnalysis
            addingOptionCallAnalysis: false,
            callAnalysisFlag: false,
            addingFastOption: false,
            fastOptionFlag: false,
            addingOptionCallEval: false,
            callEvalFlag: false,

            // コール結果オプション
            addingOptionCallResult: false,

            // スケジュール内線グループ
            schedule_extension_group_infos: [],
            last_duplicate_id: 0,

            // スケジュール着信コントロール
            schedule_dispensing_infos: [],
            dispensing_last_duplicate_id: 0,

            // スケジュール待ち呼情報
            schedule_wait_call_infos: [],
            wait_call_infos_last_duplicate_id: 0,

            // スケジュール待ち呼超過時着信動作設定
            schedule_over_wait_call_infos: [],
            over_wait_call_infos_last_duplicate_id: 0,

            csv_company_id: this.props.userInfo.company_id,
            csv_department_id: [],
            csv_floor_id: [],
            csvData: [],
            csvHeader: [
                //会社名
                {
                    label: this.props.langText.Body.CompanyName,
                    key: "CompanyName"
                },
                //拠点名
                {
                    label: this.props.langText.Body.DepartmentName,
                    key: "DepartmentName"
                },
                //名称
                {
                    label: this.props.langText.Body.ExternalNumberName,
                    key: "ExternalNumberName"
                },
                //メモ
                {
                    label: this.props.langText.Body.Memo,
                    key: "Memo"
                },
                //表示番号
                {
                    label: this.props.langText.Body.DisplayNumber,
                    key: "DisplayNumber"
                },
                //プレフィックス
                {
                    label: this.props.langText.Body.Prefix,
                    key: "Prefix"
                },
                //内線グループ
                {
                    label: this.props.langText.Body.InboundGroupName,
                    key: "InboundGroupName"
                },
                //営業カレンダー
                {
                    label: this.props.langText.Body.BusinessCalendar,
                    key: "BusinessCalendar"
                },
                //フロア名
                {
                    label: this.props.langText.Body.FloorName,
                    key: "FloorName"
                },
                //GW名
                {
                    label: this.props.langText.Body.GatewayName,
                    key: "GatewayName"
                },
                //回線種別
                {
                    label: this.props.langText.Body.LineType,
                    key: "LineType"
                },
                //裏番号
                {
                    label: this.props.langText.Body.BackNumber,
                    key: "BackNumber"
                },
                //最大着信数
                {
                    label: this.props.langText.Body.ChannelNumber,
                    key: "ChannelNumber"
                },
                //最大待ち呼数
                {
                    label: this.props.langText.Body.WaitNumber,
                    key: "WaitNumber"
                },
                //待ち呼音声
                {
                    label: this.props.langText.Body.SoundNumber,
                    key: "SoundNumber"
                },
                //待ち呼制限時間（秒）
                {
                    label: this.props.langText.Body.WaitCallTimeOutSeconds,
                    key: "WaitCallTimeOutSeconds"
                },
                //待ち呼制限時間超過音声
                {
                    label: this.props.langText.Body.WaitCallTimeOutSound,
                    key: "WaitCallTimeOutSound"
                },
                //待ち呼超過時の着信動作
                {
                    label: this.props.langText.Body.ControlCallOverAction,
                    key: "ControlCallOverAction"
                },
                //待ち呼超過音声
                {
                    label: this.props.langText.Body.CallOverSound,
                    key: "CallOverSound"
                },
                //待ち呼超過時の着信ステータス
                {
                    label: this.props.langText.Body.CallOverAction,
                    key: "CallOverAction"
                },
                //内線タイプ
                {
                    label: this.props.langText.Body.InboundGroupType,
                    key: "InboundGroupType"
                },
                //通話成立時特殊音声再生
                {
                    label: this.props.langText.Body.AbjSound,
                    key: "AbjSound"
                },
                //通話開始前音声再生
                {
                    label: this.props.langText.Body.BeforeDialSound,
                    key: "BeforeDialSound"
                },
                //録音データ保管期限
                {
                    label: this.props.langText.Body.VoiceLogExpire,
                    key: "VoiceLogExpire"
                },
                //通話中の内線への着信
                {
                    label: this.props.langText.Body.DialRestrictionFlagSetting,
                    key: "DialRestrictionFlagSetting"
                },
                //ACD機能
                {
                    label: this.props.langText.Body.ACDSetting,
                    key: "ACDSetting"
                },
                //着信コントロール秒数
                {
                    label: this.props.langText.Body.Dispensing + this.props.langText.Body.SecCnt,
                    key: "DispensingSecCnt"
                },
                //着信コントロール着信数
                {
                    label: this.props.langText.Body.Dispensing + this.props.langText.Body.InboundCnt,
                    key: "DispensingInboundCnt"
                },
                //応答待ち時間
                {
                    label: this.props.langText.Body.TimeoutSec,
                    key: "TimeoutSec"
                },
                //応答待ち時間超過時の再生音声
                {
                    label: this.props.langText.Body.TimeoutAudio,
                    key: "TimeoutAudio"
                },
                //リスト
                {
                    label: this.props.langText.Body.List,
                    key: "List"
                },
                //リスト外着信時の動作
                {
                    label: this.props.langText.Body.OutOfListInboundAction,
                    key: "OutOfListInboundAction"
                },
                //リスト外着信時の再生音声
                {
                    label: this.props.langText.Body.OutOfListInboundPlayBack,
                    key: "OutOfListInboundPlayBack"
                },
                //非通知番号からの着信動作
                {
                    label: this.props.langText.Body.AnonymousAction,
                    key: "AnonymousAction"
                },
                //非通知番号からの着信時の再生音声
                {
                    label: this.props.langText.Body.AnonymousSoundPlayBack,
                    key: "AnonymousSoundPlayBack"
                },
                //全内線未接続時の動作
                {
                    label: this.props.langText.Body.UnconnectedAction,
                    key: "UnconnectedAction"
                },
                //全内線未接続時の再生音声
                {
                    label: this.props.langText.Body.UnconnectedSoundPlayBack,
                    key: "UnconnectedSoundPlayBack"
                },
                //通話品質分析機能
                {
                    label: this.props.langText.Body.CallAnalysisTitle,
                    key: "CallAnalysisTitle"
                },
                //キーワードグループ
                {
                    label: this.props.langText.Body.KeywordGroup,
                    key: "KeywordGroup"
                },
                //クイックモード
                {
                    label: this.props.langText.Body.QuickMode,
                    key: "fastOptionTitle"
                },
                //応対品質分析
                {
                    label: this.props.langText.Body.CallEvalTitle,
                    key: "CallEvalTitle"
                }
            ],
            validationMessage : []
        }
        this.floor_load_flag = false;
        this.group_load_flag_intype = false;
        this.group_load_flag_depart = false;
        this.gateway_load_flag = false;

        // csvデータ作成準備
        this.csvLink = React.createRef();
    }

    getColumnsData() {
        // let inboundGroupType = this.getSelectOption('inbound_group_type_by_search', this.props.langText.SelectOption);

        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.id,
        });
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            //OEM権限以上は会社名を表示
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")}
                />
            });
        }
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)){
            //会社管理者以上は拠点名を表示
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: 'ct62_assgin_external_department_infos.cm13_departments.department_name',
                // filterable: false,
                sortable: false,
                Cell: data => {
                    let elemArr = [];
                    data.row._original.ct62_assgin_external_department_infos.forEach((row, key) => {
                        elemArr.push(
                            <p key={key}>
                                {row.cm13_departments.department_name}
                            </p>
                        );
                    });
                    return (elemArr);
                },
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "ct62_assgin_external_department_infos.cm13_departments.department_name")}
                />
            });
        }
        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            //システム管理者はSYSTEM_FLOOR_FLGがYの時はフロア名を表示
            //拠点管理者以上OEM以下は、所属会社がフロア管理している場合はフロア名を表示
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: 'ct62_assgin_external_department_infos.cm13_departments.floor_name',
                // filterable: false,
                sortable: false,
                Cell: data => {
                    let elemArr = [];
                    data.row._original.ct62_assgin_external_department_infos.forEach((row, key) => {
                        elemArr.push(
                            <p key={key}>
                                {row.cm13_departments.floor_name}
                            </p>
                        );
                    });
                    return (elemArr);
                },
                Filter: () => <FormControl
                    placeholder={this.props.langText.Body.ForwardMatch}
                    onChange={e => this.onFilterChange(e.target.value, "ct62_assgin_external_department_infos.cm13_departments.floor_name")}
                />
            });
        }
        itemArr.push({
            Header: this.props.langText.Body.ExtensionName,
            accessor: 'memo',
            width: this.props.boardWidth.medium,
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "memo")}
            />
        });
        itemArr.push({
            Header: this.props.langText.Body.DisplayNumber,
            accessor: 'display_number',
            width: this.props.boardWidth.CallNumber,
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.PartialMatch}
                onChange={e => this.onFilterChange(e.target.value, "display_number")}
            />
        });
        // itemArr.push({
        //     Header: this.props.langText.Body.ChannelNumber,
        //     accessor: 'channel_number',
        //     Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "channel_number")} />
        // });
        // itemArr.push({
        //     Header: this.props.langText.Body.WaitNumber,
        //     accessor: 'wait_number',
        //     Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "wait_number")} />
        // });
        itemArr.push({
            Header: this.props.langText.Body.Prefix,
            accessor: 'ct62_assgin_external_department_infos.prefix',
            width: this.props.boardWidth.small,
            // filterable: false,
            sortable: false,
            Cell: data => {
                let elemArr = [];
                data.row._original.ct62_assgin_external_department_infos.forEach((row, key) => {
                    elemArr.push(
                        <p key={key}>
                            {row.prefix}
                        </p>
                    );
                });
                return (elemArr);
            },
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "ct62_assgin_external_department_infos.prefix")}
            />
        });
        // itemArr.push({
        //         Header: this.props.langText.Body.InboundGroupType,
        //         accessor: 'inbound_group_type',
        //         Cell: ({value}) => {
        //             let findObj = inboundGroupType.find(row => row.value === value);
        //             return findObj ? findObj.label : "";
        //         },
        //         Filter: ({filter}) => {
        //             return (
        //                 <select
        //                     onChange = {e => this.onFilterChange(e.target.selectedOptions[0].value, "inbound_group_type")}
        //                     value = {filter ? filter.label : ""}>
        //                     <option key={0} value=""></option>
        //                     {inboundGroupType.map(row => <option key={row.label} value={row.value}>{row.label}</option>)}
        //                 </select>
        //             )
        //         }
        //     });
        itemArr.push({
            Header: this.props.langText.Body.InboundGroupName,
            accessor: 'ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.inbound_group_name',
            width: this.props.boardWidth.medium,
            // filterable: false,
            sortable: false,
            Cell: data => {
                let elemArr = [];
                data.row._original.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.forEach((row, key) => {
                    elemArr.push(
                        <p key={key}>
                            {row.inbound_group_name}
                        </p>
                    );
                });
                if (
                    data.row._original.ct30_assign_external_number_schedule_extension_group_infos_display_board &&
                    data.row._original.ct30_assign_external_number_schedule_extension_group_infos_display_board.length > 0
                ) {
                    // 「終了日時＜現在日時」のスケジュール内線グループが1個でも登録されている場合は
                    // 登録されているスケジュール内線グループ情報をSpeechBubbleで表示
                    elemArr.push(
                        <p>
                            <SetDataWithSpeechBubble
                                displayData = {
                                    this.props.langText.Body.ScheduleExtensionGroupIsSet
                                }
                                speechBubbleData = {
                                    <>
                                        <p><b>{this.props.langText.Body.ScheduleExtensionGroupInfo}</b></p>
                                        <table id="scheduled-inbound-group-infos-table">
                                            <tr>
                                                <td>{this.props.langText.Body.ScheduledStartDateTime}</td>
                                                <td>{this.props.langText.Body.ScheduledEndDateTime}</td>
                                                <td>{this.props.langText.Body.InboundGroupName}</td>
                                            </tr>
                                            {data.row._original.ct30_assign_external_number_schedule_extension_group_infos_display_board.map(row =>{
                                                return (
                                                        <tr>
                                                            <td>{moment(row.original_start_datetime).format("YYYY-MM-DD HH:mm")}</td>
                                                            <td>{moment(row.original_end_datetime).format("YYYY-MM-DD HH:mm")}</td>
                                                            <td>
                                                                <table id="scheduled-inbound-group-infos-group-name-table">
                                                                    {row.cm28_inbound_group_infos.map(inboundGroupInfo => {
                                                                        return (<tr><td>{inboundGroupInfo.inbound_group_name}</td></tr>);
                                                                    })}
                                                                </table>
                                                            </td>
                                                        </tr>
                                                )
                                            })
                                        }
                                        </table>
                                    </>
                                }
                                infoIconDisplayFlag = {true}
                                infoIconDisplayPosition = "left"
                                infoIconType = {faIcon.faInfoCircle}
                                infoIconColor = "silver"
                                infoIconSize = "lg"
                                custom="speech-bubble-scheduled-inbound-group"
                            />
                        </p>
                    )
                }
                return (elemArr);
            },
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.inbound_group_name")}
            />

        });
        itemArr.push({
            Header: this.props.langText.Body.BusinessCalendar,
            accessor: 'cm31_business_calendar_infos.business_calendar_name',
            width: this.props.boardWidth.medium,
            // filterable: false
            Filter: () => <FormControl
                placeholder={this.props.langText.Body.ForwardMatch}
                onChange={e => this.onFilterChange(e.target.value, "cm31_business_calendar_infos.business_calendar_name")}
            />
        });

        itemArr.push({
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];

                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize='xsmall'
                                onClick={this.modalShow('update', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='pencil'/>
                            </Button>
                        )
                    } else {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize='xsmall'
                                onClick={this.modalShow('read', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='eye-open'/>
                            </Button>
                        )
                    }

                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize='xsmall'
                                onClick={this.modalShow('delete', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='minus'/>
                            </Button>
                        )
                    }

                    return (
                        <Row className='text-center'>
                            {rowData}
                        </Row>
                    )
                }
            });

        return itemArr;
    }

    validationHandle = (param, index = null) => {
        let nowState = null;
        let {
            gateway_id,
            company_id,
            line_type,
            display_number,
            back_number,
            inbound_type,
            channel_number,
            wait_number,
            inboundGroupcount,
            floor_flg,
            list_id,
            listAppointInboundFlag,
            list_out_action,
            list_count,
            call_over_action,
            display_number_validation_flag,
            back_number_validation_flag,
            timeout_sec,
            dispensing_flag,
            dispensing_time,
            dispensing_count,
            schedule_dispensing_infos,
            waitCallTimeoutSeconds,
            waitCallTimeoutUseFlag
        } = this.state;

        switch (param) {
            case 'gateway_id':
                nowState = this.state[param].length > 0
                    ? null
                    : 'error';
                break;
            case 'company_id':
            case 'inbound_type':
            case 'line_type':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;

            case 'display_number':
                nowState = this.state[param].length > 9 && this.state.display_number_validation_flag
                    ? null
                    : 'error'
                break;

            case 'back_number':
                nowState = this.state[param].length > 0 && this.state.back_number_validation_flag
                    ? null
                    : 'error'
                break;

            case 'channel_number':
            case 'call_over_action':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;
            case 'wait_number':
                nowState = (this.state[param] && this.state[param] >= 0) || this.state[param] === 0
                    ? null
                    : 'error'
                break;
            case 'waitCallTimeoutSeconds':
                nowState =
                    !waitCallTimeoutUseFlag ||
                    (
                        this.state[param] &&
                        this.state[param] <= this.props.systemParameters.WAIT_CALL_TIMEOUT_LIMIT_SECONDS
                    )
                    ? null
                    : 'error'
                break;
            case 'list_id':
            case 'list_out_action':
                if(listAppointInboundFlag && !this.state[param]){
                    nowState = 'error';
                } else {
                    nowState = null;
                }
                break;
            case 'timeout_sec':
                nowState = !this.state[param] || (
                    this.state[param] > 0 && this.state[param] <= 300)
                    ? null
                    : 'error'
                break;
            case 'dispensing_time':
                nowState = !this.state[param] && this.state[param] !== 0 || (
                    this.state[param] > 0 && this.state[param] <= 60)
                    ? null
                    : 'error'
                break;
            case 'dispensing_count':
                nowState = (!channel_number && this.state[param] && this.state[param] > 0) || (
                    this.state[param] && this.state[param] <= channel_number)
                    ? null
                    : 'error'
                break;
            case 'dispensing_flag':
                nowState = !this.state[param] || (
                    dispensing_count && dispensing_count > 0)
                    ? null
                    : 'error'
                break;
            case 'schedule_dispensing':
                // スケジュール着信コントロールに、着信数＞最大着信数のスケジュールが1個でも含まれていたらエラー
                nowState =
                    channel_number &&
                    schedule_dispensing_infos.some(row => {
                        return row.inboundCnt > channel_number
                    }) ? 'error' : null;
                break;
            case 'insert':
                nowState = (
                    gateway_id.length > 0 &&
                    company_id &&
                    display_number.length > 9 &&
                    back_number.length > 0 &&
                    inbound_type &&
                    channel_number &&
                    call_over_action &&
                    ((wait_number && wait_number >= 0) || wait_number === 0) &&
                    inboundGroupcount <= this.props.systemParameters.EXTEN_COUNT &&
                    display_number_validation_flag &&
                    back_number_validation_flag &&
                    (!timeout_sec || (timeout_sec <= 300 && timeout_sec > 0)) &&
                    (!dispensing_flag ||
                        (!dispensing_time && dispensing_time !== 0 ||
                            (dispensing_time > 0 && dispensing_time <= 60)) &&
                        ((!channel_number &&
                                dispensing_count && dispensing_count > 0) ||
                        (dispensing_count &&
                            dispensing_count <= channel_number))) &&
                    ((this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system) && line_type) ||
                        !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) &&
                    (!waitCallTimeoutUseFlag ||
                        (
                            waitCallTimeoutSeconds &&
                            waitCallTimeoutSeconds <= this.props.systemParameters.WAIT_CALL_TIMEOUT_LIMIT_SECONDS
                        )
                    ) &&
                    schedule_dispensing_infos.every(row => {
                        return row.inboundCnt <= channel_number
                    })
                );
                if(listAppointInboundFlag && (!list_id || !list_out_action || !list_count)){
                    //リスト指定着信がチェックされて、リストやリスト外着信時の動作、リスト数が選択されなかったらValidation有効化
                    nowState = false;
                }

                this.state["departmentInfos"].forEach(row => {
                    if (!row.department_id) {
                        nowState = false;
                    }
                    if (floor_flg) {
                        nowState = (nowState && row.floor_id)
                    }
                    if (row.prefix === "" || (row.prefix && row.prefix.length < 3) || row.prefix_duplicationflag || (
                        !this.isSystemUsedPrefix(row.prefix))) {
                        // プレフィックスが未記載or3桁じゃない、またはプレフィックス重複、または490～499(システム予約番号）の場合は登録禁止
                        nowState = false;
                    }
                });
                break;
            case 'update':
                nowState = (
                    gateway_id.length > 0 &&
                    company_id &&
                    back_number.length > 0 &&
                    inbound_type &&
                    channel_number &&
                    ((wait_number && wait_number >= 0) || wait_number === 0) &&
                    inboundGroupcount <= this.props.systemParameters.EXTEN_COUNT &&
                    back_number_validation_flag &&
                    call_over_action &&
                    (!timeout_sec || (timeout_sec <= 300 && timeout_sec > 0)) &&
                    (!dispensing_flag ||
                        (!dispensing_time && dispensing_time !== 0 ||
                            (dispensing_time > 0 && dispensing_time <= 60)) &&
                        ((!channel_number &&
                                dispensing_count && dispensing_count > 0) ||
                        (dispensing_count &&
                            dispensing_count <= channel_number))) &&
                    ((this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system) && line_type) ||
                        !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) &&
                    (!waitCallTimeoutUseFlag ||
                        (
                            waitCallTimeoutSeconds &&
                            waitCallTimeoutSeconds <= this.props.systemParameters.WAIT_CALL_TIMEOUT_LIMIT_SECONDS
                        )
                    ) &&
                    schedule_dispensing_infos.every(row => {
                        return row.inboundCnt <= channel_number
                    })
                );
                if(listAppointInboundFlag && (!list_id || !list_out_action || !list_count)){
                    //リスト指定着信がチェックされて、リストやリスト外着信時の動作、リスト数が選択されなかったらValidation有効化
                    nowState = false;
                }

                this.state["departmentInfos"].forEach(row => {
                    if (!row.department_id) {
                        nowState = false;
                    }
                    if (floor_flg) {
                        nowState = (nowState && row.floor_id)
                    }
                    if (row.prefix) {
                        if((row.prefix.length === 0 || row.prefix.length === 3) && row.prefix_duplicationflag === false 
                        && this.isSystemUsedPrefix(row.prefix)){}
                        else
                        {
                            // プレフィックスが未記載or3桁じゃない、またはプレフィックス重複または490～499(システム予約番号）の場合は登録禁止
                            nowState = false;
                        }
                    }
                });
                break;
            case 'delete':
                nowState = (
                    gateway_id.length > 0
                    && company_id
                    && display_number.length > 9
                    && inbound_type
                    && channel_number
                    && ((wait_number && wait_number >= 0) || wait_number === 0)
                    && inboundGroupcount <= this.props.systemParameters.EXTEN_COUNT
                    && call_over_action
                )
                break;
            case 'department_id':
            case 'floor_id':
                if (this.state["departmentInfos"][index] && this.state["departmentInfos"][index][param]) {
                    nowState = null;
                } else {
                    nowState = "error";
                }
                break;
            case 'prefix':
                if (this.state["departmentInfos"][index] &&
                    this.state["departmentInfos"][index][param]
                ) {
                    if (this.state["departmentInfos"][index][param].length < 3 ||
                        this.state["departmentInfos"][index].prefix_duplicationflag ||
                        !this.isSystemUsedPrefix(this.state["departmentInfos"][index][param])
                    ) {
                        //3桁未満、プレフィックス重複または490～499(システム予約番号）の場合はエラー
                        nowState = "error";
                    }
                } else {
                    // 空白の場合はエラー
                    nowState = "error";
                }

                break;
            default:
                nowState = null;
                break;
        }

        return nowState;
    }

    getCsvSelectValidationCheck = (event, param) => {
        let {
            csv_floor_id,
            floorSelect,
            validationMessage
        } = this.state;

        let message = [];

        const departmentNotSelected = this.sprintf(this.props.langText.Message.NoSelected, this.props.langText.Body.Department);
        const floorNotSelected = this.sprintf(this.props.langText.Message.NoSelected, this.props.langText.Body.Floor);
        
        switch (param) {
            case 'csv_department_id':
                //拠点が選択されてない場合、拠点エラーメッセージ追加
                if (event.length === 0) {
                    message.push(departmentNotSelected);
                //拠点が1個以上選択されてない場合、拠点エラーメッセージ除外
                } else if (event.length > 0) {
                    message = validationMessage.filter(row => row !== departmentNotSelected);
                    //フロアの選択肢がない場合、もしくはフロア選択肢があってフロアが1個以上選択されている場合はフロアエラーメッセージ除外
                    if (floorSelect.length === 0 || (floorSelect.length > 0 && csv_floor_id.length > 0)) {
                        message = message.filter(row => row !== floorNotSelected);
                    } 
                }
                break;
            case 'csv_floor_id':
                //フロアが選択されてない場合、フロアエラーメッセージ追加
                if (event.length === 0) {
                    message.push(floorNotSelected);
                //フロアが1個以上選択されてない場合、フロアエラーメッセージ除外
                } else if (event.length > 0) {
                    message = validationMessage.filter(row => row !== floorNotSelected);
                }
                break;
            default:
                break;
        }
        const set = new Set(message);
        this.setState({
            validationMessage : [...set]
        });
    }

    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "gateway_id" : [],
                "company_id" : [],
                "department_id" : [],
                "inbound_group_id" : [],
                "business_calendar_id" : [],
                "list_id" : [],
                "floor_id" : [],
                "sound_id" : [],
                "call_results_id": [],
                "gateway_load" : [],
                "department_load" : [],
                "inbound_group_load" : [],
                "business_calendar_load" : [],
                "floor_load" : [],
                "sound_load" : [],
                "list_load": [],
                "call_results_load": [],
                "keyword_groups_hint" : [],
                "keyword_groups_filler" : [],
                "keyword_groups_load": []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    usedPrefixSearch = (index) => {
        const departmentInfos = this.state.departmentInfos;
        let {external_id} = this.state;
        // 検索開始
        //該当外線番号が持っているprefixは重複チェックしない
        this.ascAxios("post", `${this.reactContainerPath}/isPrefixDuplicate`, {
            departmentInfos: [this.state["departmentInfos"][index]],
            cm61_id: external_id
        })
            .then(res => {
                departmentInfos[index]["prefix_duplicationflag"] = res.data.result;
                departmentInfos[index]["prefix_searchflag"] = false;
                this.propSetState({ departmentInfos: departmentInfos });
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    departmentInfos_onTextChange_Limit(event,param,index,limitcount,departmentInfos) {
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        if(value.length <= limitcount){
            departmentInfos[index][param] = value.replace(/[^0-9]+/i,'');
        }
        // プレフィックスがクリアされた場合、登録可能プレフィックスを取得
        const emptyPrefix = this.state.emptyPrefix;

        this.ascAxios("post", `${this.reactContainerPath}/getEmptyPrefix`, {
            cm13_id: departmentInfos[index].floor_id || departmentInfos[index].department_id,
        })
            .then(res => {
                emptyPrefix[index] = res.data.emptyPrefix;
                this.propSetState({ emptyPrefix });
            })
            .catch(err => {
                this.showErrorObjectMesssage(err);
            });
        this.propSetState({"departmentInfos": departmentInfos});
    }

    departmentInfoSet(event, param, index) {
        let myValue = null;
        if(event !== null){
            myValue = event.value || event.target.value;
        }

        const emptyPrefix = this.state.emptyPrefix;
        //登録可能プレフィックスの取得
        if (
            ((!this.state.floor_flg && param === "department_id") || param === "floor_id") &&
            myValue
        ) {

            this.ascAxios("post", `${this.reactContainerPath}/getEmptyPrefix`, {
                cm13_id: myValue,
            })
                .then(res => {
                    emptyPrefix[index] = res.data.emptyPrefix;
                    this.propSetState({ emptyPrefix });
                })
                .catch(err => {
                    this.showErrorObjectMesssage(err);
                });
        } else {
            //拠点（フロア）クリア時は登録可能プレフィックスを非表示にする）
            emptyPrefix[index] = null;
            this.propSetState({ emptyPrefix });
        }

        if (param =="department_id") {
            if (this.state.floor_flg) {
                this.state["departmentInfos"][index]['floor_id'] = 0;
                this.state["departmentInfos"][index]['parent_department_id'] = myValue;
            }
            this.state["departmentInfos"][index]['prefix'] = "";
            this.propSetState({departmentInfos: this.state["departmentInfos"]});
        }

        let nowStateData = [...this.state["departmentInfos"]];
        nowStateData[index][param] = myValue;

        if (!this.state.floor_flg) {
            this.getDepartmentSelect(nowStateData);
        } else {
            nowStateData.forEach((row, dataIndex) => {
                if (dataIndex !== index) {
                    if (row.floor_id == myValue){
                        alert(this.props.langText.Message.ExternalNumber_SameFloorError);
                        return nowStateData[index]["floor_id"] = 0;
                    }
                }
            });
        }
        this.propSetState({departmentInfos: nowStateData});
        switch (param) {
            case 'department_id':

                this.floor_load_flag = true;
                this.departmentSelectChanged(true ,{
                    department_id: myValue,
                    floor_flg : this.state.floor_flg,
                    company_id: this.state.company_id,
                    gateway_id: this.state.gateway_id,
                    index
                });
                // // 拠点変えたら問答無用で初期化
                // this.setState({prefix: ''});

            case 'floor_id':
            case 'inbound_type':
                this.group_load_flag_depart = true;
                if(this.state.inbound_type)
                {
                    this.group_load_flag_intype = true;
                }

                let floor_id_arr = [];
                let department_id_arr = [];
                this.state["departmentInfos"].forEach((row,index) => {
                    if (row.department_id) {
                        department_id_arr.push(row.department_id);
                    }
                    if (row.floor_id) {
                        floor_id_arr.push(row.floor_id);
                    }
                });
                let inbound_type = (param === 'inbound_type') ? myValue : this.state.inbound_type;
                let select_dep = this.state.floor_flg ? floor_id_arr : department_id_arr;
                this.inboundGroupTypeSelectChanged({
                    department_id: select_dep,
                    inbound_type
                })
                break;

            default:
        }
    }

    getDepartmentSelect(departmentInfos) {
        let selectArr = [],
            template = this.state.departmentSelect,
            selectValues = departmentInfos.map(row => {
                return row.department_id
            });
        selectValues.forEach((row, index) => {
            let nowTemplate = [...template],
                nowSetSelect = [...selectValues];

            // 僕のデータを選択されたArrから削除
            nowSetSelect.splice(index, 1);

            // Templateから選択されたデータ削除
            nowSetSelect.forEach(selected => {
                if (selected) {
                    let selectedIndex = nowTemplate.findIndex(data => data.value === selected);

                    if (selectedIndex !== -1) {
                        nowTemplate.splice(selectedIndex, 1);
                    }
                }
            });

            selectArr.push(nowTemplate);
        });

        this.propSetState({departmentSelectArr: selectArr});
    }

    setCommonDepartmentSelect = async (company_id, floor_flg) => {
        try {
            let departmentList = await this.ascAxios("post", `Common/departmentSelect`, {
                container: this.reactContainerPath,
                company_id: company_id,
                floor_flg
            });
            this.setState({ 
                csv_department_id: departmentList.data || [],
                csvDepartmentSelect: departmentList.data || [] 
            });
            const departmentId = departmentList.data.map(row => {return row.value});
            this.setCommonFloorSelect(departmentId);
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    setCommonFloorSelect = async (departmentIds) => {
        try {
            let floorList = await this.ascAxios("post", `Common/getFloorsByDepartmentIds`, {
                container: this.reactContainerPath,
                departmentIds
            });
            this.setState({
                csv_floor_id: floorList.data || [],
                csvFloorSelect: floorList.data || [],
            });
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    };

    setCsvSelect = () => {
        //csv出力権限がある場合、会社・拠点・フロアリストを取得
        if (this.props.currentPermission.export && !this.state.modalType) {
            this.getDefaultSelectData({"company_id": this.props.userInfo.company_id});
            this.setCommonDepartmentSelect(this.props.userInfo.company_id, this.props.userInfo.floor_flg);
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        try {
            this.blockUI();
            if (param && param.id) {
                let modalInfo = await this.getModalInfo(param.id);
                param = modalInfo.data ? modalInfo.data[0] : null;
            }

            this.floor_load_flag = false;
            this.group_load_flag_intype = false;
            this.group_load_flag_depart = false;
            this.gateway_load_flag = false;
            this.omni_phone_back_ground_display_color_picker = false;
            this.omni_phone_back_ground_color = GlobalConst.omni_phone_back_ground_color;
            this.omni_phone_font_display_color_picker = false;
            this.omni_phone_font_color = GlobalConst.omni_phone_font_color;

            this.state.display_number_validation_flag = true;
            this.state.back_number_validation_flag = true;

            this.modalshow_commonInit();

            this.setState({
                departmentInfos:[],
                inbound_group_base: [],
                inbound_group_cm51ids: [],
            });

            this.state.previous_back_number = param && param.back_number ? param.back_number : '';
            let analysis_api = param && param.id && (await this.getCallAnalysisApi(param.cm12_companies.id)).data;
            let setData = {
                external_id: param && param.id
                    ? param.id
                    : '',
                gateway_id: param && param.cm91_id
                    ? param.cm91_id
                    : '',
                gateway_name: param && param.cm91_gateway_infos && param.cm91_gateway_infos.gw_name
                    ? param.cm91_gateway_infos.gw_name
                    : '',
                company_id: param && param.cm12_companies && param.cm12_companies.id
                    ? param.cm12_companies.id
                    : '',
                company_name: param && param.cm12_companies && param.cm12_companies.company_name
                    ? param.cm12_companies.company_name
                    : '',
                department_id: param && param.cm13_departments && param.cm13_departments.id
                    ? param.cm13_departments.id
                    : '',
                department_name: param && param.cm13_departments && param.cm13_departments.department_name
                    ? param.cm13_departments.department_name
                    : '',
                floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                    ? param.cm12_companies.floor_flg === "Y"
                    : null,
                display_number: param && param.display_number
                    ? param.display_number
                    : '',
                back_number: param && param.id ? await this.getBackNumbers(param.id) : [],
                memo: param && param.memo
                    ? param.memo
                    : '',
                description: param && param.description
                    ? param.description
                    : '',
                channel_number: param && param.channel_number !== "0"
                    ? param.channel_number
                    : '',
                wait_number: param && ((param.wait_number && param.wait_number >= 0) || param.wait_number === 0)
                    ? param.wait_number
                    : '',
                sound_id: param && param.cm35_id !== "0"
                    ? param.cm35_id
                    : '',
                prefix: param && param.sequence_number
                    ? param.sequence_number
                    : '',
                inbound_type: param && param.inbound_group_type
                    ? param.inbound_group_type
                    : '',
                inbound_group_id: param && param.ct29_assign_external_number_extension_group_infos && param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos
                    ? param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.map(row => row.id)
                    : '',
                inbound_group_name: param && param.ct29_assign_external_number_extension_group_infos && param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos
                    ? param.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.map(row => row.inbound_group_name)
                    : '',
                schedule_extension_group_infos: param && param.ct30_assign_external_number_schedule_extension_group_infos
                    ? param.ct30_assign_external_number_schedule_extension_group_infos
                    : [],
                business_calendar_id: param && param.cm31_business_calendar_infos && param.cm31_business_calendar_infos.id
                    ? param.cm31_business_calendar_infos.id
                    : '',
                business_calendar_name: param && param.cm31_business_calendar_infos && param.cm31_business_calendar_infos.business_calendar_name
                    ? param.cm31_business_calendar_infos.business_calendar_name
                    : '',
                departmentInfos: param && param.ct62_assgin_external_department_infos
                    ? param.ct62_assgin_external_department_infos.map(row => {
                        return {
                            id: row.id,
                            cm61_id: row.cm61_id,
                            cm13_id: row.cm13_departments.id,
                            department_id: row.cm13_departments.id,
                            parent_department_id: row.cm13_departments.parent_department_id,
                            floor_id: row.cm13_departments.floor_id,
                            prefix: row.prefix,
                            full_prefix: row.full_prefix,
                            department_name: row.cm13_departments.department_name,
                            floor_name: row.cm13_departments.floor_name,
                            prefix_duplicationflag: false
                        };
                    })
                    : [],
                omni_phone_back_ground_color: param && param.omni_phone_back_ground_color
                    ? param.omni_phone_back_ground_color
                    : GlobalConst.omni_phone_back_ground_color,
                omni_phone_font_color: param && param.omni_phone_font_color
                    ? param.omni_phone_font_color
                    : GlobalConst.omni_phone_font_color,
                before_omni_phone_back_ground_color: param && param.omni_phone_back_ground_color
                    ? param.omni_phone_back_ground_color
                    : '',
                before_omni_phone_font_color: param && param.omni_phone_font_color
                    ? param.omni_phone_font_color
                    : '',
                listAppointInboundFlag: param && param.cm76_autocall_list && param.cm76_autocall_list.id,
                list_id: param && param.cm76_autocall_list
                    ? param.cm76_autocall_list.id
                    : '',
                list_sound_id: param && param.cm35_id_list_sound
                    ? param.cm35_id_list_sound
                    : '',
                list_out_action: param && param.list_out_action
                    ? param.list_out_action
                    : '',
                list_name: param && param.cm76_autocall_list
                    ? param.cm76_autocall_list.list_name
                    : '',
                list_count: param && param.cm76_autocall_list
                    ? param.cm76_autocall_list.list_count
                    : 0,
                unconnected_action: param && param.unconnected_action
                    ? param.unconnected_action
                    : 'playback',
                unconnected_sound_id: param && param.cm35_id_unconnected_sound
                    ? param.cm35_id_unconnected_sound
                    : '',
                anonymous_action: param && param.anonymous_action
                    ? param.anonymous_action
                    : 'unset',
                anonymous_sound_id: param && param.cm35_id_anonymous_sound
                    ? param.cm35_id_anonymous_sound
                    : '',
                emptyPrefix: [],
                waitCallTimeoutUseFlag: param &&
                                        param.wait_call_timeout_flag &&
                                        param.wait_call_timeout_flag === "Y",
                waitCallTimeoutSeconds: param && param.wait_call_timeout_sec
                    ? param.wait_call_timeout_sec
                    : '',
                waitCallTimeoutSoundId: param && param.cm35_id_wait_call_timeout_sound
                    ? param.cm35_id_wait_call_timeout_sound
                    : '',
                waitCallTimeoutSoundUrl : await this.getSoundURL('WAIT_CALL_TIMEOUT_SOUND'),
                schedule_wait_call_infos: param && param.ct32_assign_external_number_schedule_wait_call_infos
                    ? param.ct32_assign_external_number_schedule_wait_call_infos
                    : [],
                delete_wait_call_infos : [],
                wait_call_infos_last_duplicate_id: 0,
                call_over_sound_id: param && param.cm35_id_call_over_sound
                    ? param.cm35_id_call_over_sound
                    : '',
                call_over_action: param && param.call_over_action
                    ? param.call_over_action
                    : this.state.callOverActionSelect[0].value,
                call_over_action_control: param && param.call_over_action_control
                    ? param.call_over_action_control
                    : 'playback',
                schedule_over_wait_call_infos: param && param.ct33_assign_external_number_schedule_over_wait_call_infos
                    ? param.ct33_assign_external_number_schedule_over_wait_call_infos
                    : [],
                delete_over_wait_call_infos: [],
                over_wait_call_infos_last_duplicate_id: 0,
                abj_flag: param && param.abj_flag && param.abj_flag === "Y",
                before_dial_flag: param && param.before_dial_flag && param.before_dial_flag === "Y",
                before_dial_sound_id: param && param.cm35_id_before_dial
                    ? param.cm35_id_before_dial
                    : '',
                before_dial_sound_url : await this.getSoundURL('BEFORE_DIAL_SOUND'),
                call_over_sound_url : await this.getSoundURL('CALL_OVER_SOUND'),
                line_type: param && param.line_type
                    ? param.line_type
                    : '',
                inboundGroupcount: 0,
                timeout_sec: param && param.timeout_sec
                    ? param.timeout_sec
                    : '',
                cm35_id_timeout_sound: param && param.cm35_id_timeout_sound
                    ? param.cm35_id_timeout_sound
                    : '',
                timeout_playback_flag: param && param.timeout_playback_flag
                    ? param.timeout_playback_flag
                    : "N",
                dial_restriction_flag: param && param.dial_restriction_flag
                    ? param.dial_restriction_flag
                    : "N",
                acd_flag: !!(param && param.acd_flag && param.acd_flag === "Y"),
                dispensing_time: param && param.dispensing_time
                    ? param.dispensing_time
                    : '',
                dispensing_count: param && param.dispensing_count
                    ? param.dispensing_count
                    : '',
                schedule_dispensing_infos: param && param.ct31_assign_external_number_schedule_dispensing_infos
                    ? param.ct31_assign_external_number_schedule_dispensing_infos
                    : [],
                dispensing_flag: !!(param && (param.dispensing_time || param.dispensing_count)),
                callAnalysisFlag:
                    !!(param &&
                    param.cc81_setting_call_quality_external_number &&
                    param.cc81_setting_call_quality_external_number.parameter_value === 1),
                fastOptionFlag:
                    !!(param &&
                    param.cc81_setting_call_quality_external_number &&
                    param.cc81_setting_call_quality_external_number.fast_option ==="Y"),
                addingFastOption:
                    !!(analysis_api &&
                    analysis_api.parameter_value === "ascend_whisper"),
                callEvalFlag:
                    !!(param &&
                    param.cc82_setting_call_eval_external_number &&
                    param.cc82_setting_call_eval_external_number.parameter_value === 1),
                anonymous_sound_url: await this.getSoundURL('ANONYMOUS_DEFAULT_SOUND'),
                extension_unconnected_sound_url: await this.getSoundURL('EXTENSION_UNCONNECTED_SOUND'),
                list_sound_url : await this.getSoundURL('LIST_OUT_SOUND'),
                wait_number_sound_url : await this.getSoundURL('WAIT_NUMBER_SOUND'),
                timeout_sound_url : await this.getSoundURL('TIME_OUT_SOUND'),
                voice_log_expire_external_number: param && param.voice_log_expire_external_number
                    ? param.voice_log_expire_external_number
                    : -1,
                last_duplicate_id: 0,
                dispensing_last_duplicate_id: 0,
                delete_schedule_inbound_group_infos: [],
                delete_dispensing_infos: [],
                call_results: param && param.ct602_assign_call_results
                    ? param.ct602_assign_call_results.map(row => row.cm64_id)
                    : '',
                keyword_groups_hint: param && param.ct82_call_quality_setting_keyword
                    ? param.ct82_call_quality_setting_keyword
                        .filter(row => row.ct81_keyword_group.keyword_type === "hints")
                        .map(keyword => keyword.ct81_id)
                    : [],
                keyword_groups_filler: param && param.ct82_call_quality_setting_keyword
                    ? param.ct82_call_quality_setting_keyword
                        .filter(row => row.ct81_keyword_group.keyword_type === "filler")
                        .map(keyword => keyword.ct81_id)
                    : [],
                show: true,
                modalType
            };

            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                //会社管理社以下の場合
                setData.company_id = this.props.userInfo.company_id
                setData.company_name = this.props.userInfo.company_name
                setData.floor_flg = this.props.userInfo.floor_flg
            }

            if (!param && this.props.currentPermission.scope_code === "department") {
                //拠点管理者の場合
                if (setData.floor_flg) {
                    setData.department_id = this.props.userInfo.parent_department_id
                    setData.department_name = this.props.userInfo.parent_department_name
                } else {
                    setData.department_id = this.props.userInfo.department_id
                    setData.department_name = this.props.userInfo.department_name
                }
            }

            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                //フロア管理者以下の場合
                setData.department_id = this.props.userInfo.department_id
                setData.department_name = this.props.userInfo.department_name
                setData.floor_id = this.props.userInfo.department_id
                setData.floor_name = this.props.userInfo.floor_name
            }

            this.getDefaultSelectData(setData);
            this.setState(setData);

            this.GatewaySelectChanged(false, {
                gateway_id: setData.gateway_id,
                floor_flg: setData.floor_flg,
                company_id: setData.company_id
            });

            if (setData.departmentInfos && setData.departmentInfos.length > 0) {
                let floor_id_arr = [];
                let department_id_arr = [];

                setData.departmentInfos.forEach((row,index) => {
                    if (row.department_id) {
                        department_id_arr.push(row.department_id);
                    }
                    if (row.floor_id) {
                        floor_id_arr.push(row.floor_id);
                    }
                    if (setData.floor_flg) {
                        this.departmentSelectChanged(true ,{
                            department_id: row.department_id,
                            company_id: setData.company_id,
                            floor_flg : setData.floor_flg,
                            gateway_id: setData.gateway_id,
                            index
                        });
                    }
                });
                let inbound_type = setData.inbound_type;
                let select_dep = setData.floor_flg ? floor_id_arr : department_id_arr;

                this.inboundGroupTypeSelectChanged({
                    department_id: select_dep,
                    inbound_type
                })
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
            this.reactTableRefresh();
        }
    }

    getModalInfo(id) {
        return this.ascAxios('post', `${this.reactContainerPath}/modal`, { id });
    }

    onCheckBoxChange(event, param){
        super.onCheckBoxChange(event, param);
        switch (param) {
            case 'listAppointInboundFlag':
                this.setState({
                    list_id: '',
                    list_sound_id: '',
                    list_out_action: '',
                    list_name: '',
                    list_count: 0
                })
                break;
            case 'before_dial_flag':
                this.setState({
                    before_dial_sound_id: '',
                })
                break;
            case 'dispensing_flag':
                this.setState({
                    dispensing_time: null,
                    dispensing_count: null,
                })
                break;
            case 'waitCallTimeoutUseFlag':
                this.setState({
                    waitCallTimeoutSeconds: '',
                    waitCallTimeoutSoundId: ''
                })
                break;
            case 'callAnalysisFlag':
                this.setState({
                    fastOptionFlag: false,
                    callEvalFlag: false,
                    keyword_groups_hint: [],
                    keyword_groups_filler: []
                });
                break;
            case 'callEvalFlag':
                this.setState({
                    keyword_groups_filler: []
                });
                break;
            default:
                break;
        }
    }

    onRadioChange(event, param){
        super.onRadioChange(event, param);
        switch (param) {
            case 'list_out_action':
                this.setState({
                    list_sound_id: '',
                })
                break;
            case 'anonymous_action':
                this.setState({
                    anonymous_sound_id: '',
                })
                break;
            case 'unconnected_action':
                this.setState({
                    unconnected_sound_id: '',
                })
                break;
            case 'timeout_playback_flag':
                this.setState({
                    cm35_id_timeout_sound: null,
                })
                break;
            case 'call_over_action_control':
                this.setState({
                    call_over_sound_id: '',
                    call_over_action: 'busy'
                });
                break;
            default:
                break;
        }
    }

    onSelectChange(event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        const floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : false;

        switch (param) {
            case 'company_id':
                this.companySelectChanged(true, {company_id: value});
                this.inboundGroupTypeSelectChanged({
                    department_id: '',
                    inbound_type: this.state.inbound_type
                });

                // 会社変えたら問答無用で初期化
                this.setState({prefix: '', floor_flg, emptyPrefix: []});
                break;
            case 'csv_company_id':
                this.setState({
                    csv_department_id: [],
                    csv_floor_id: [],
                    floor_flg,
                    validationMessage: []
                });
                this.companySelectChanged(true, {company_id: value});
                this.setCommonDepartmentSelect(value, floor_flg);
                break;
            case 'inbound_type':
                if (param === 'inbound_type'){
                    this.group_load_flag_depart = true;
                    if(this.state.inbound_type)
                    {
                        this.group_load_flag_intype = true;
                    }
                }

                let floor_id_arr = [];
                let department_id_arr = [];
                this.state["departmentInfos"].forEach((row,index) => {
                    if (row.department_id) {
                        department_id_arr.push(row.department_id);
                    }
                    if (row.floor_id) {
                        floor_id_arr.push(row.floor_id);
                    }
                });
                let inbound_type = (param === 'inbound_type') ? value : this.state.inbound_type;
                let select_dep = this.state.floor_flg ? floor_id_arr : department_id_arr;

                this.inboundGroupTypeSelectChanged({
                    department_id: select_dep,
                    inbound_type
                })
                break;
            case 'list_id':
                this.setState({
                    list_name: (event && event.label) ? event.label : "",
                    list_count: (event && event.list_count) ? event.list_count : 0
                });
                break;
            default:
                break;
        }

    }

    onMultiSelectChange = async (event, param) => {
        super.onMultiSelectChange(event, param);
        let value_arr = (event.length > 0) ? [] : "";

        event.forEach((row, key) => {
            value_arr[key] = row['value'];
        });

        switch (param) {
            case 'gateway_id':
                this.GatewaySelectChanged(true, {
                    gateway_id: value_arr,
                    floor_flg: this.state.floor_flg,
                    company_id: this.state.company_id
                });
                this.inboundGroupTypeSelectChanged({
                    department_id: '',
                    inbound_type: this.state.inbound_type
                });
                break;
            case "csv_department_id":
                await this.setCommonFloorSelect(value_arr);
                this.setState({[param]: event});
                await this.getCsvSelectValidationCheck(event, param);
            case "csv_floor_id":
                this.setState({[param]: event});
                await this.getCsvSelectValidationCheck(event, param);
            default:
                break;
        }
    }

    getDefaultSelectData = ({company_id}) => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.companySelectChanged(false, {company_id})
                this.setState({
                    companySelect: res.data,
                })
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        this
            .ascAxios('post', `Common/getLineTypes`, {parameterId: "LINE_TYPE"})
            .then(res => {
                this.setState({
                    lineTypeSelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
    }

    companySelectChanged = (flag, {company_id}) => {
        if (flag) {
            this.setState({
                department_id:        '',
                sound_id:             '',
                waitCallTimeoutSoundId: '',
                call_over_sound_id:   '',
                business_calendar_id: '',
                gateway_id: [],
                departmentInfos: [],
                callAnalysisFlag: false,
                fastOptionFlag: false,
                acd_flag: false,
                callEvalFlag: false
            })
        }
        if(company_id)
        {
            // this.common_buttondisabledLengthCheck(`department_load`,`push`);
            this.common_buttondisabledLengthCheck(`gateway_load`, `push`);
            this.common_buttondisabledLengthCheck(`business_calendar_load`,`push`);
            this.common_buttondisabledLengthCheck(`sound_load`,`push`);
            this.common_buttondisabledLengthCheck(`list_load`,`push`);
            this.common_buttondisabledLengthCheck(`call_results_load`,`push`);
            this.common_buttondisabledLengthCheck(`keyword_groups_load`,`push`);
        }
        else
        {
            // this.common_buttondisabledLengthCheck(`department_load`,`pop`);
            this.common_buttondisabledLengthCheck(`gateway_load`, `pop`);
            this.common_buttondisabledLengthCheck(`business_calendar_load`,`pop`);
            this.common_buttondisabledLengthCheck(`sound_load`,`pop`);
            this.common_buttondisabledLengthCheck(`list_load`,`pop`);
            this.common_buttondisabledLengthCheck(`call_results_load`,`pop`);
            this.common_buttondisabledLengthCheck(`keyword_groups_load`,`pop`);
        }
        let langtext_update = this.props.langText.Body.VoiceListUpdate;
        let langtext_create = this.props.langText.Body.VoiceListCreate;
        this
            .ascAxios(
                'post',
                `${this.reactContainerPath}/getItemsOnCompanySelected`,
                {company_id,langtext_update,langtext_create}
            )
            .then(res => {
                if(this.state.company_id)
                {
                    // this.common_buttondisabledLengthCheck(`department_id`,`push`);
                    this.common_buttondisabledLengthCheck(`gateway_id`, `push`);
                    this.common_buttondisabledLengthCheck(`business_calendar_id`,`push`);
                    this.common_buttondisabledLengthCheck(`sound_id`,`push`);
                    this.common_buttondisabledLengthCheck(`list_id`,`push`);
                    this.common_buttondisabledLengthCheck(`call_results_id`,`push`);
                    // this.common_buttondisabledLengthCheck(`department_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`gateway_load`, `pop`);
                    this.common_buttondisabledLengthCheck(`business_calendar_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`sound_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`list_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`call_results_load`,`push`);
                    this.common_buttondisabledLengthCheck(`keyword_groups_hint`,`push`);
                    this.common_buttondisabledLengthCheck(`keyword_groups_filler`,`push`);
                    this.common_buttondisabledLengthCheck(`keyword_groups_load`,`push`);
                }
                else
                {
                    // this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`business_calendar_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`sound_id`,`pop`);
                    // this.common_buttondisabledLengthCheck(`gateway_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`list_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`keyword_groups_hint`,`pop`);
                    this.common_buttondisabledLengthCheck(`keyword_groups_filler`,`pop`);
                }

                this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
                this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                this.common_buttondisabledLengthCheck(`list_load`,`pop`);
                this.common_buttondisabledLengthCheck(`call_results_load`,`pop`);
                this.common_buttondisabledLengthCheck(`keyword_groups_load`,`pop`);

                const keywordGroupHintSelect = res.data.ct81_keyword_group.filter(
                    (keyword) => keyword.type === "hints"
                );
                const keywordGroupFillerSelect = res.data.ct81_keyword_group.filter(
                    (keyword) => keyword.type === "filler"
                );
                this.setState({
                    // departmentSelect:       res.data.cm13_departments,
                    gatewaySelect: res.data.cm91_gateway_infos,
                    businessCalendarSelect: res.data.cm31_business_calendar_infos,
                    waitNumberSoundSelect:  res.data.cm35_voice_file_infos,
                    listOutSoundSelect: res.data.cm35_voice_file_infos,
                    beforeDialSoundSelect: res.data.cm35_voice_file_infos,
                    listSelect: res.data.cm76_autocall_list,
                    callOverSoundSelect: res.data.cm35_voice_file_infos,
                    UnconnectedSoundSelect: res.data.cm35_voice_file_infos,
                    timeoutSoundSelect: res.data.cm35_voice_file_infos,
                    anonymousSoundSelect: res.data.cm35_voice_file_infos,
                    waitCallTimeoutSoundSelect: res.data.cm35_voice_file_infos,
                    callResultsSelect: res.data.cm64_call_results,
                    keywordGroupHintSelect,
                    keywordGroupFillerSelect
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })

            if(company_id){
                // 会社が選択されている場合、追加オプション情報を確認
                this
                    .ascAxios(
                        'post',
                        `${this.reactContainerPath}/getCompanyAddingOption`,
                        {company_id}
                    )
                    .then(res =>{
                        this.setState({
                            addingOptionCallAnalysis:
                                res.data.parameValueCallAnalysis === "N" ? false : true,
                            addingFastOption:
                                res.data.parameValueFastOption === "ascend_whisper" ? true : false,
                            addingOptionACD:
                                res.data.parameValueACD === "N" ? false : true,
                            addingOptionCallEval:
                                res.data.parameValueCallEval === "N" ? false : true,
                            addingOptionCallResult:
                                res.data.parameValueCallResult === "N" ? false : true
                        });
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data))
                    })
            } else {
                this.setState({ addingOptionCallAnalysis: false, addingFastOption: false, addingOptionACD: false, addingOptionCallEval: false , addingOptionCallResult: false });
            }

        }

    departmentSelectChanged = (flag, {company_id, department_id, floor_flg, gateway_id, index}) => {
        const floorSelect = this.state.floorSelect;
        if (flag) {
            this.setState({
                floor_id: 0,
            })
        }

        if(this.floor_load_flag === true && department_id && company_id)
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`push`);

        }
        else
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
        }
        if (floor_flg) {
            this.ascAxios('post', `Common/floorSelectByGateWay`, {company_id, department_id, gateway_id})
            .then(res => {
                if(department_id)
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                floorSelect[index] = res.data;
                this.setState({
                    floorSelect: floorSelect
                });
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
    }

    GatewaySelectChanged = (flag, {company_id, floor_flg, gateway_id}) => {
        if (flag) {
            this.setState({
                department_id: '',
                departmentInfos: [],
                emptyPrefix: []
            });
        }
        if (company_id) {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        } else {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        let langtext_update = this.props.langText.Body.VoiceListUpdate;
        let langtext_create = this.props.langText.Body.VoiceListCreate;
        this
        .ascAxios(
            'post',
            `${this.reactContainerPath}/departmentSelect`, {
                company_id,
                floor_flg,
                gateway_id
            }
        )
        .then(res => {
            if (this.state.company_id) {
                this.common_buttondisabledLengthCheck(`department_id`,`push`);
                this.common_buttondisabledLengthCheck(`department_load`,`pop`);
            } else {
                this.common_buttondisabledLengthCheck(`department_id`,`pop`);
            }

            this.setState({
                departmentSelect:res.data.cm13_departments,
            })

            this.getDepartmentSelect((this.state["departmentInfos"]) ? this.state["departmentInfos"] : []);
        })
        .catch(err => {
            alert(this.getErrorString(err.response.data))
        })

    }

    getGatewaySelect = (flag, {company_id}) => {
        if (flag) {
            this.setState({
                gateway_id: []
            });
        }
        if(company_id && this.gateway_load_flag === true)
        {
            this.common_buttondisabledLengthCheck(`gateway_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
        }

        this.ascAxios('post', 'Common/companyBygatewaySelect', {company_id})
            .then(res => {
                if(this.state.floor_flg)
                {
                    if(this.state.floor_id)
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`push`);
                        this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);

                    }
                    else
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`pop`);
                    }
                }
                else
                {
                    if(this.state.department_id)
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`push`);
                        this.common_buttondisabledLengthCheck(`gateway_load`,`pop`);
                    }
                    else
                    {
                        this.common_buttondisabledLengthCheck(`gateway_id`,`pop`);
                    }
                }
                this.setState({
                    gatewaySelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            });
    }

    inboundGroupTypeSelectChanged = ({department_id, inbound_type}) => {
        if(this.group_load_flag_intype === true && this.group_load_flag_depart === true)
        {
            this.common_buttondisabledLengthCheck(`inbound_group_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`inbound_group_load`,`pop`);
        }
        this
            .ascAxios(
                'post',
                `${this.reactContainerPath}/inboundGroupInfoSelect`,
                {department_id, inbound_type}
            )
            .then(res => {
                let inbound_group_setup = [];

                if(department_id && Array.isArray(department_id) 
                    && department_id.length !== 0  && this.state.inbound_type
                ) {
                    this.common_buttondisabledLengthCheck(`inbound_group_id`,`push`);
                    this.common_buttondisabledLengthCheck(`inbound_group_load`,`pop`);
                    this.group_load_flag_intype = false;
                    this.group_load_flag_depart = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`inbound_group_id`,`pop`);
                    this.common_buttondisabledLengthCheck(`inbound_group_load`,`pop`);
                }

                let selectids = [];

                if(res.data.cm28_extensions_ids){
                    for(let i = 0; i < res.data.cm28_extensions_ids.length; i++){
                        if(this.state.inbound_group_id.indexOf(res.data.cm28_extensions_ids[i].id) !== -1){
                            if(selectids.length && selectids.findIndex(({cm51_id}) => cm51_id === res.data.cm28_extensions_ids[i].cm51_id) === -1){
                                selectids.push(res.data.cm28_extensions_ids[i]);
                            }
                            else if(!selectids.length){
                                selectids.push(res.data.cm28_extensions_ids[i]);
                            }
                        }
                    }
                }

                let drawlabel = JSON.parse(JSON.stringify(res.data.cm28_inbound_group_infos));
                let {schedule_extension_group_infos} = this.state;
                
                for(let i = 0; i < res.data.cm28_inbound_group_infos.length; i++)
                {
                    if(res.data.cm28_inbound_group_infos[i].count !== 0)
                    {
                        drawlabel[i].label = drawlabel[i].label + '(' + drawlabel[i].count + ')';
                    }
                    this.state.inbound_group_id.map(inbound_group_data => {
                        if (res.data.cm28_inbound_group_infos[i].value == inbound_group_data) {
                            inbound_group_setup.push(inbound_group_data);
                        }
                    });
                }

                //スケジュール内線グループの情報更新
                for (let i = 0; i < schedule_extension_group_infos.length; i++) {
                    const cm28_inbound_group_infos = schedule_extension_group_infos[i].cm28_inbound_group_infos;
                    const group_info = cm28_inbound_group_infos.filter(info=>{
                        return res.data.cm28_inbound_group_infos.find(group=> group.value === info.cm28_id);
                    });

                    if(JSON.stringify(schedule_extension_group_infos[i].cm28_inbound_group_infos) !== JSON.stringify(group_info)){
                        schedule_extension_group_infos[i].dep_change_flag = true;
                    }
                    
                    schedule_extension_group_infos[i].cm28_inbound_group_infos = group_info;
                    
                    //スケジュール内線グループが空になる場合、該当スケジュール内線グループ削除
                    if(group_info.length === 0){
                        schedule_extension_group_infos.splice(i, 1);
                        i--;
                    }
                }

                this.setState({
                    inboundGroupSelect: drawlabel,
                    inboundGroupcount:  selectids? selectids.length: 0,
                    inbound_group_base: drawlabel,
                    inbound_group_cm51ids: res.data.cm28_extensions_ids || [],
                    inbound_group_id: inbound_group_setup,
                    schedule_extension_group_infos
                });
            })
            .catch(err => {
                console.error(err);
                alert(this.getErrorString(err.response.data))
            })
    }

    usedDisplayNumbersearch = async (display_number) => {
        try {
            let display_number_search = await this.ascAxios('post', `${this.reactContainerPath}/displayNumbersearch`,{display_number});

            this.setState({
                display_number_validation_flag: display_number_search.data.result
            });

        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }
    }

    /**
     * 登録済み裏番号の検索
     *
     * @param {} cm61_id
     * @param {} back_numbers
     */
    usedBackNumberSearch = async (cm61_id, back_numbers) => {
        try {
            const result = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/searchBackNumber`,
                {
                    cm61_id,
                    back_numbers: back_numbers.map((obj) => obj.value)
                }
            );

            this.setState({
                back_number_validation_flag: result.data.length === 0,
                registeredBackNumber: result.data.map((obj) => obj.back_number)
            });
        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }
    }

    onClickHandle = modalType => event => {
        let {
            external_id,
            gateway_id,
            company_id,
            department_id,
            display_number,
            back_number,
            channel_number,
            wait_number,
            memo,
            description,
            inbound_type,
            inbound_group_id,
            business_calendar_id,
            sound_id,
            prefix,
            departmentInfos,
            omni_phone_back_ground_color,
            omni_phone_font_color,
            floor_flg,
            list_id,
            list_out_action,
            list_sound_id,
            call_over_action,
            call_over_action_control,
            abj_flag,
            before_dial_flag,
            before_dial_sound_id,
            call_over_sound_id,
            line_type,
            anonymous_sound_id,
            anonymous_action,
            unconnected_action,
            unconnected_sound_id,
            timeout_sec,
            cm35_id_timeout_sound,
            timeout_playback_flag,
            dispensing_time,
            dispensing_count,
            dispensing_flag,
            schedule_dispensing_infos,
            delete_dispensing_infos,
            dial_restriction_flag,
            acd_flag,
            voice_log_expire_external_number,
            addingOptionCallAnalysis,
            callAnalysisFlag,
            fastOptionFlag,
            addingOptionCallEval,
            callEvalFlag,
            schedule_extension_group_infos,
            delete_schedule_inbound_group_infos,
            waitCallTimeoutUseFlag,
            waitCallTimeoutSeconds,
            waitCallTimeoutSoundId,
            call_results,
            schedule_wait_call_infos,
            delete_wait_call_infos,
            schedule_over_wait_call_infos,
            delete_over_wait_call_infos,
            keyword_groups_hint,
            keyword_groups_filler
        } = this.state;
        const keyword_groups = [keyword_groups_hint, keyword_groups_filler]

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    external_id,
                    gateway_id,
                    company_id,
                    display_number,
                    back_number,
                    channel_number,
                    wait_number,
                    memo,
                    description,
                    inbound_type,
                    inbound_group_id,
                    business_calendar_id,
                    sound_id,
                    prefix,
                    departmentInfos,
                    omni_phone_back_ground_color,
                    omni_phone_font_color,
                    list_id,
                    list_out_action,
                    list_sound_id,
                    call_over_action,
                    call_over_action_control,
                    abj_flag,
                    before_dial_flag,
                    before_dial_sound_id,
                    call_over_sound_id,
                    line_type,
                    anonymous_sound_id,
                    anonymous_action,
                    unconnected_action,
                    unconnected_sound_id,
                    timeout_sec,
                    cm35_id_timeout_sound,
                    timeout_playback_flag,
                    dispensing_time,
                    dispensing_count,
                    dispensing_flag,
                    schedule_dispensing_infos,
                    dial_restriction_flag,
                    acd_flag,
                    voice_log_expire_external_number,
                    addingOptionCallAnalysis,
                    callAnalysisFlag,
                    fastOptionFlag,
                    addingOptionCallEval,
                    callEvalFlag,
                    schedule_extension_group_infos,
                    waitCallTimeoutUseFlag,
                    waitCallTimeoutSeconds,
                    waitCallTimeoutSoundId,
                    call_results,
                    keyword_groups,
                    schedule_wait_call_infos,
                    schedule_over_wait_call_infos
                })
                    .then(res => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        this.showErrorObjectMesssage(err);
                    });
                break;

            case "update":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    external_id,
                    gateway_id,
                    company_id,
                    display_number,
                    back_number,
                    channel_number,
                    wait_number,
                    memo,
                    description,
                    inbound_type,
                    inbound_group_id,
                    business_calendar_id,
                    sound_id,
                    departmentInfos,
                    omni_phone_back_ground_color,
                    omni_phone_font_color,
                    floor_flg,
                    list_id,
                    list_out_action,
                    list_sound_id,
                    call_over_action,
                    call_over_action_control,
                    abj_flag,
                    before_dial_flag,
                    before_dial_sound_id,
                    call_over_sound_id,
                    line_type,
                    anonymous_sound_id,
                    anonymous_action,
                    unconnected_action,
                    unconnected_sound_id,
                    timeout_sec,
                    cm35_id_timeout_sound,
                    timeout_playback_flag,
                    dispensing_time,
                    dispensing_count,
                    dispensing_flag,
                    schedule_dispensing_infos,
                    delete_dispensing_infos,
                    dial_restriction_flag,
                    acd_flag,
                    voice_log_expire_external_number,
                    addingOptionCallAnalysis,
                    callAnalysisFlag,
                    fastOptionFlag,
                    addingOptionCallEval,
                    callEvalFlag,
                    schedule_extension_group_infos,
                    delete_schedule_inbound_group_infos,
                    waitCallTimeoutUseFlag,
                    waitCallTimeoutSeconds,
                    waitCallTimeoutSoundId,
                    call_results,
                    keyword_groups,
                    schedule_wait_call_infos,
                    delete_wait_call_infos,
                    schedule_over_wait_call_infos,
                    delete_over_wait_call_infos
                })
                    .then(res => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        this.showErrorObjectMesssage(err);
                    });

                break;

            case "delete":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    external_id,
                    gateway_id,
                    company_id,
                    department_id,
                    display_number,
                    back_number,
                    channel_number,
                    wait_number,
                    memo,
                    inbound_type,
                    inbound_group_id,
                    business_calendar_id,
                    departmentInfos,
                })
                    .then(res => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        this.showErrorObjectMesssage(err);
                    });
                break;

            default:
                alert(this.getErrorString({ code: "modal" }));
        }

    }

    getSoundName = (cm35_id) => {
        let {listOutSoundSelect} = this.state;
        let returnData = '';
        listOutSoundSelect.forEach((row, key)=>{
            if(row.value == cm35_id){
                returnData = row.label
            }
        })
        return returnData;
    }

    getSoundURL = async(param) => {
        try {
            const sound_url = await this.ascAxios("post", `${this.reactContainerPath}/getSoundURL`, {
                parameter_id: param
            });
            if (sound_url.data && sound_url.data.parameter_value) {
                return sound_url.data.parameter_value;
            } else {
                return null;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    /**
     * 裏番号を取得
     *
     * @param {String} cm61_id
     * @return {Array}
     */
    getBackNumbers = async(cm61_id) => {
        try {
            if (!cm61_id) return false;

            const result = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/getBackNumbers`,
                {cm61_id}
            );

            const backNumbers = result.data.map(data => {
                return {label: data.back_number, value:data.back_number}
            });

            return backNumbers;
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    /**
     * 裏番号チェンジイベント
     *
     * @param {Array} event
     * @param {String} param
     * @param {Number} limit
     */
    onMultiTelNumberChangeLimit(event, param, limit){
        super.onMultiTelNumberChangeLimit(event, param, limit);
        this.usedBackNumberSearch(this.state.external_id, event)
    }

    createCsvData = async () => {
        let {
            csv_company_id,
            csv_department_id,
            csv_floor_id,
            csvDepartmentSelect,
            csvFloorSelect,
            csvHeader
        } = this.state;
        try {
            this.blockUI();
            this.setState({ loading: true });
            let reqBody = {
                sorted: [ { id: 'id', desc: true } ],
                filtered: [
                    { 
                        id: 'cm12_companies.id', 
                        value: [csv_company_id] || [] 
                    }
                ]
            }

            //全選択以外の場合だけcm13のフィルターを指定
            let csv_department_ids = csv_department_id.map(row => {return row.value});
            if ((csv_department_id.length !== csvDepartmentSelect.length) ||
                (csv_floor_id.length !== csvFloorSelect.length)) {
                if ((csv_floor_id && csv_floor_id.length > 0)) {
                    csv_floor_id.forEach(row => {
                        csv_department_ids.push(row.value);
                    });
                }
                reqBody.filtered.push({
                    id: 'ct62_assgin_external_department_infos.cm13_departments.id',
                    value: csv_department_ids
                });
            }

            const result = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/board`,
                reqBody
            );

            let companyParameterInfo = await this.ascAxios(
                'post', 
                `Common/getCompanyControlParameters`, 
                {cm12_id : csv_company_id}
            );

            const companyAcdFlag = companyParameterInfo.data.find(row => 
                row.function_id === "ACD_FUNCTION" && row.parameter_value === "Y") 
                ? true 
                : false;

            const companyCallAnalysisFlag = companyParameterInfo.data.find(row => 
                row.function_id === "CALL_ANALYSIS" && row.parameter_value === "Y") 
                ? true 
                : false;

            const companyFastOptionFlag = companyCallAnalysisFlag && companyParameterInfo.data.find(row => 
                row.function_id === "CALL_ANALYSIS_API" && row.parameter_value === "ascend_whisper") 
                ? true 
                : false;

            const companyCallEvalFlag = companyParameterInfo.data.find(row => 
                row.function_id === "CALL_EVAL" && row.parameter_value === "Y")
                ? true
                : false;

            let csvData = [];
            if (result.data.rows.length > 0) { 
                for (const row of result.data.rows) {
                    const department_name = row.ct62_assgin_external_department_infos.map(item => {
                        if (item.cm13_departments.department_name && item.cm13_departments.department_name !== "" 
                            && item.cm13_departments.department_name !== undefined) {
                            return `${item.cm13_departments.department_name.toString()}`
                        }
                    });

                    const department_name_set = new Set(department_name);

                    const floor_name = row.ct62_assgin_external_department_infos.map(item => {
                        if (item.cm13_departments.floor_name && item.cm13_departments.floor_name !== "") {
                            return `${item.cm13_departments.floor_name.toString()}`
                        }
                    });

                    let prefix = "";
                    row.ct62_assgin_external_department_infos.forEach((item, index) => {
                        if (item.full_prefix && item.full_prefix !== "") {
                            if (row.ct62_assgin_external_department_infos.length !== 1 
                                && index !== row.ct62_assgin_external_department_infos.length - 1) {
                                prefix += `${item.full_prefix}, `
                            } else {
                                prefix += `${item.full_prefix}`
                            }
                        }
                    });
    
                    const back_numbers_arr = await this.getBackNumbers(row.id);
                    let back_numbers = "";
                    if (back_numbers_arr) {
                        back_numbers_arr.forEach((item, index) => {
                            if (item.value && item.value !== "") {
                                if (back_numbers_arr.length !== 1 
                                    && index !== back_numbers_arr.length - 1) {
                                    back_numbers += `${item.value}, `
                                } else {
                                    back_numbers += `${item.value}`
                                }
                            }
                        });
                    };
    
                    const gateway_names = row.cm91_id.map((gid) => {
                        const gateway_info = this.state.gatewaySelect.find(option => option.value === parseInt(gid));
                        if (gateway_info) {
                            return gateway_info.label
                        }
                    });
    
                    const line_type = row.line_type && this.state.lineTypeSelect.find(line => line.value === row.line_type)
                        ? this.state.lineTypeSelect.find(line => line.value === row.line_type).label
                        : "";
    
                    const sound_number = row.cm35_id && this.state.waitNumberSoundSelect.length > 0
                        ? this.state.waitNumberSoundSelect.find(item => item.value === row.cm35_id).label
                        : this.props.langText.Body.DefaultAudio;
    
                    const wait_call_time_out_sound = row.cm35_id_wait_call_timeout_sound && this.state.waitCallTimeoutSoundSelect.length > 0
                        ? this.state.waitCallTimeoutSoundSelect.find(item => item.value === row.cm35_id_wait_call_timeout_sound).label
                        : this.props.langText.Body.DefaultAudio;
                    
                    const call_over_action_control = row.call_over_action_control && this.state.callOverActionControlSelect.length > 0
                        ? this.state.callOverActionControlSelect.find(item => item.value === row.call_over_action_control).label
                        : this.props.langText.Body.PlayVoice;

                    const call_over_sound = row.cm35_id_call_over_sound && this.state.callOverSoundSelect.length > 0
                        ? this.state.callOverSoundSelect.find(item => item.value === row.cm35_id_call_over_sound).label
                        : this.props.langText.Body.DefaultAudio;
                    
                    const call_over_action = row.call_over_action && this.state.callOverActionSelect.length > 0
                        ? this.state.callOverActionSelect.find(item => item.value === row.call_over_action).label
                        : "";
                    
                    const inbound_group_type = row.inbound_group_type && this.state.inboundTypeSelect.length > 0 
                        ? this.state.inboundTypeSelect.find(item => item.value === row.inbound_group_type).label
                        : "";
    
                    const abj_sound = row.abj_flag && row.abj_flag === "Y" 
                        ? this.props.langText.Message.ExternalNumber_AbjSettingMessage
                        : "";
    
                    const before_dial_sound = row.cm35_id_before_dial && this.state.beforeDialSoundSelect.length > 0  
                        ? this.state.beforeDialSoundSelect.find(item => item.value === row.cm35_id_before_dial).label
                        : this.props.langText.Body.DefaultAudio;

                    const voice_log_expire = row.voice_log_expire_external_number && row.voice_log_expire_external_number > 0 
                        ? this.state.voice_log_expire_external_number_select.find(item => item.value === row.voice_log_expire_external_number).label
                        : this.props.langText.SelectOption.CompanyOverwrite;
    
                    const inbound_group_name = row.ct29_assign_external_number_extension_group_infos.cm28_inbound_group_infos.map(item => {
                        return item.inbound_group_name
                    });
                    
                    const dial_restriction = row.dial_restriction_flag && this.state.DialRestrictionSelect.length > 0
                        ? this.state.DialRestrictionSelect.find(item => item.value === row.dial_restriction_flag).label
                        : "";

                    const dispensing_sec_cnt = row && row.dispensing_time 
                        ? row.dispensing_time 
                        : this.props.langText.Message.ExternalNumber_DispensingTimeDefault;
    
                    const acd_setting = row.acd_flag && row.acd_flag === "Y" 
                        ? this.props.langText.SelectOption.Use
                        : this.props.langText.SelectOption.Disuse;
    
                    const timeout_audio = row.cm35_id_timeout_sound && this.state.timeoutSoundSelect.length > 0 
                        ? this.state.timeoutSoundSelect.find(item => item.value === row.cm35_id_timeout_sound).label
                        : this.props.langText.Body.DefaultAudio;
    
                    const out_of_list_inbound_action = row.list_out_action && this.state.inboundActionSelect.length > 0 
                        ? this.state.inboundActionSelect.find(item => item.value === row.list_out_action).label
                        : "";

                    const out_of_list_inbound_playback = row.cm35_id_list_sound && this.state.listOutSoundSelect.length > 0 
                        ? this.state.listOutSoundSelect.find(item => item.value === row.cm35_id_list_sound).label
                        : this.props.langText.Body.DefaultAudio;
    
                    const anonymous_action = row.anonymous_action && this.state.anonymousActionSelect.length > 0 
                        ? this.state.anonymousActionSelect.find(item => item.value === row.anonymous_action).label
                        : "";
    
                    const anonymous_action_sound_playback = row.cm35_id_anonymous_sound  && this.getSoundName(row.cm35_id_anonymous_sound)
                        ? this.getSoundName(row.cm35_id_anonymous_sound)
                        : this.props.langText.Body.DefaultAudio;
    
                    const unconnected_action = row.unconnected_action && this.state.UnconnectedActionSelect.length > 0  
                        ? this.state.UnconnectedActionSelect.find(item => item.value === row.unconnected_action).label
                        : "";
    
                    const unconnected_action_sound_playback = row.cm35_id_unconnected_sound && this.state.UnconnectedSoundSelect.length > 0  
                        ? this.state.UnconnectedSoundSelect.find(item => item.value === row.cm35_id_unconnected_sound).label
                        : this.props.langText.Body.DefaultAudio;
    
                    const call_analysis = row.cc81_setting_call_quality_external_number && row.cc81_setting_call_quality_external_number.parameter_value === 1
                        ? this.props.langText.SelectOption.Use
                        : this.props.langText.SelectOption.Disuse;

                    const call_fast_option = call_analysis === this.props.langText.SelectOption.Use 
                        ? (row.cc81_setting_call_quality_external_number && row.cc81_setting_call_quality_external_number.fast_option === "Y" 
                            ? this.props.langText.SelectOption.Use
                            : this.props.langText.SelectOption.Disuse
                        )
                        : "";
                    
                    const call_eval = row.cc82_setting_call_eval_external_number && row.cc82_setting_call_eval_external_number.parameter_value === 1
                        ? this.props.langText.SelectOption.Use
                        : this.props.langText.SelectOption.Disuse;

                    const keyword_groups_name = row.ct82_call_quality_setting_keyword
                        ? row.ct82_call_quality_setting_keyword.map(row => row.ct81_keyword_group.keyword_group_name || "")
                        : [];
                    
                    let resultRow = {
                        CompanyName: row && row.cm12_companies.company_name ? row.cm12_companies.company_name : "",
                        DepartmentName: [...department_name_set].length > 0 ? [...department_name_set].toString() : "",
                        ExternalNumberName: row && row.memo ? row.memo : "",
                        Memo: row && row.description ? row.description.replace(/\n/g, " ") : "",
                        DisplayNumber: row && row.display_number ? row.display_number.toString() : "",
                        Prefix: prefix ? prefix : "",
                        InboundGroupName: inbound_group_name,
                        BusinessCalendar: row && row.cm31_business_calendar_infos ? row.cm31_business_calendar_infos.business_calendar_name : "",
                        FloorName: floor_name.find(row => row !== undefined) ? floor_name.toString() : "",
                        GatewayName: gateway_names.find(row => row !== undefined) ? gateway_names.toString() : "",
                        LineType: line_type,
                        BackNumber: back_numbers,
                        ChannelNumber: row && row.channel_number ? row.channel_number : "",
                        WaitNumber: row && row.wait_number ? row.wait_number : "",
                        SoundNumber: sound_number,
                        WaitCallTimeOutSeconds: row && row.wait_call_timeout_sec ? row.wait_call_timeout_sec : "",
                        WaitCallTimeOutSound: row.wait_call_timeout_flag && row.wait_call_timeout_flag === "Y" ? wait_call_time_out_sound : "",
                        ControlCallOverAction: call_over_action_control,
                        CallOverSound: row.call_over_action_control === "playback" || row.call_over_action_control === null ? call_over_sound : "",
                        CallOverAction: row.call_over_action_control === "playback" || row.call_over_action_control === null ? call_over_action : "",
                        InboundGroupType: inbound_group_type,
                        BeforeDialSound: row.before_dial_flag && row.before_dial_flag === "Y" ? before_dial_sound : "",
                        VoiceLogExpire: voice_log_expire,
                        DialRestrictionFlagSetting: dial_restriction,
                        DispensingSecCnt: row.dispensing_time || row.dispensing_count ? dispensing_sec_cnt : "",
                        DispensingInboundCnt: row && row.dispensing_count ? row.dispensing_count : "",
                        TimeoutSec: row && row.timeout_sec ? row.timeout_sec : this.props.langText.Message.ExternalNumber_DefaultTimeoutSecDefault,
                        TimeoutAudio: row.timeout_playback_flag === "Y" ? timeout_audio : this.props.langText.Body.AudioNonePlay,
                        List: row && row.cm76_autocall_list ? row.cm76_autocall_list.list_name : "",
                        OutOfListInboundAction: out_of_list_inbound_action,
                        OutOfListInboundPlayBack: row.list_out_action === "playback" ? out_of_list_inbound_playback : "",
                        AnonymousAction: anonymous_action,
                        AnonymousSoundPlayBack: row.anonymous_action === "playback" ? anonymous_action_sound_playback : "",
                        UnconnectedAction: unconnected_action,
                        UnconnectedSoundPlayBack: row.unconnected_action === "playback" ? unconnected_action_sound_playback : "",
                    }

                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                        resultRow.AbjSound = abj_sound;
                    }

                    if (companyAcdFlag) {
                        resultRow.ACDSetting = acd_setting;
                    }

                    if (companyCallAnalysisFlag) {
                        resultRow.CallAnalysisTitle = call_analysis;
                        resultRow.KeywordGroup = keyword_groups_name;
                    }

                    if (companyFastOptionFlag) {
                        resultRow.fastOptionTitle = call_fast_option;
                    } 

                    if (companyCallEvalFlag) {
                        resultRow.CallEvalTitle = call_eval;
                    }

                    csvData.push(resultRow);
                }
            }

            let header = csvHeader;

            if (csv_floor_id.length === 0) {
                header = header.filter(item => item.key !== "FloorName");
            }

            if (!this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                header = header.filter(item => item.key !== "AbjSound");
            }

            if (!companyAcdFlag) {
                header = header.filter(item => item.key !== "ACDSetting");
            }

            if (!companyCallAnalysisFlag) {
                header = header.filter(item => item.key !== "CallAnalysisTitle");
                header = header.filter(item => item.key !== "KeywordGroup");
            }

            if (!companyFastOptionFlag) {
                header = header.filter(item => item.key !== "fastOptionTitle");
            }

            if (!companyCallEvalFlag) {
                header = header.filter(item => item.key !== "CallEvalTitle");
            }

            this.setState({
                csvData,
                csvHeader: header,
                loading: false
            });

            if (csvData.length === 0) {
                return this.showErrorObjectMesssage({ message: "Download_Failed" });
            } else {
                return this.csvLink.current.link.click();
            }
        } catch (err) {
            this.setState({ loading: false });
            console.log('err',err);
        } finally {
            this.unblockUI();
        }
    };

    getCsvFileName() {
        const company_name = this.state.csv_company_id && this.state.companySelect.length > 0 
            && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
            ? this.state.companySelect.find(row => row.value === this.state.csv_company_id).label
            : this.props.userInfo.company_name;

        const now_time = moment().utc().add(9, "h").format("YYYYMMDDHHmmss");
        const file_name = company_name + "_" + this.props.langText.Body.ExternalNumberList 
            + "_" + now_time + ".csv";

        return file_name;
    }

    getCallAnalysisApi = async (cm12_id) => {
        return await this.ascAxios("post", `${this.reactContainerPath}/getCallAnalysisApi`, { cm12_id });
    }

    render() {
        return (
            <div className='ExternalNumber'>
                <Row>
                    <Col xs={12} md={12}>
                        <Col>
                            {this.props.currentPermission.create &&
                            <Button
                                id="external-insert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>}
                            {this.props.currentPermission.export && 
                            <Col className="pull-right">
                                <div>
                                    <Dropdown
                                        onClick={e => {if (!e.currentTarget.className.includes("open")) this.setCsvSelect();}}>
                                        <Dropdown.Toggle 
                                            className='table-button'
                                            bsStyle='default'
                                            bsSize='sm'>
                                            {this.props.langText.Body.SettingInfoDownload}
                                        </Dropdown.Toggle>
                                        <SettingMenu bsRole="menu">
                                            <Col>
                                                <Col xs={12} sm={12} md={12} lg={12} className="margin-top-10 margin-bottom-07">
                                                    <Col xs={2} sm={2} md={3} lg={3} className="text-align-left margin-top-05">
                                                        {this.props.langText.Body.CompanyName}
                                                    </Col>
                                                    {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                                                        ?
                                                        <Col xs={10} sm={10} md={9} lg={9}>
                                                            <InlineForm
                                                                key = "csv_company_id"
                                                                controlId = "csv_company_id"
                                                                type = "select"
                                                                onChange = {e => this.onSelectChange(e, "csv_company_id")}
                                                                options = {this.state.companySelect}
                                                                value = {this.state.csv_company_id}
                                                                placeholder = {!this.state.buttondisabledArr.company_id[0] 
                                                                    ? <Glyphicon glyph="refresh" className="spinning"/> : this.props.langText.SelectOption.Placeholder}
                                                                isDisabled = {!this.state.buttondisabledArr.company_id[0]}/>
                                                        </Col>
                                                        :
                                                        <Col xs={10} sm={10} md={9} lg={9} className="padding-top-05 padding-left-30">
                                                            {this.props.userInfo.company_name}
                                                        </Col>
                                                    }
                                                </Col>
                                                {((this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) && this.state.csvDepartmentSelect.length > 0) 
                                                    || !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) &&
                                                    <Col xs={12} sm={12} md={12} lg={12} className="margin-top-03 margin-bottom-07">
                                                        <Col xs={2} sm={2} md={3} lg={3} className="text-align-left margin-top-05">
                                                            {this.props.langText.Body.DepartmentName}
                                                        </Col>
                                                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) 
                                                            ?
                                                            <Col xs={10} sm={10} md={9} lg={9}>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <MultiSelect
                                                                        hasSelectAll={true}
                                                                        options={this.state.csvDepartmentSelect}
                                                                        value = {this.state.csv_department_id}
                                                                        onChange={e => this.onMultiSelectChange(e, "csv_department_id")}
                                                                        overrideStrings={{
                                                                            selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                                                            allItemsAreSelected: this.props.langText.Body.AllSelected,
                                                                            selectAll: this.props.langText.MultiSelect.AllSelect,
                                                                            search: this.props.langText.Body.Search,
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Col>
                                                            :
                                                            <Col xs={10} sm={10} md={9} lg={9} className="padding-top-05 padding-left-30">
                                                                {this.props.userInfo.department_name ? this.props.userInfo.department_name : ""}
                                                            </Col>
                                                        }
                                                    </Col>
                                                }
                                                {((this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) 
                                                    && (this.props.userInfo.floor_flg || this.state.floor_flg) && (this.state.csvFloorSelect.length > 0)) 
                                                    || (!this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) && this.props.userInfo.floor_flg)) &&
                                                    <Col xs={12} sm={12} md={12} lg={12} className="margin-top-03 margin-bottom-07">
                                                        <Col xs={2} sm={2} md={3} lg={3} className="text-align-left margin-top-05">
                                                            {this.props.langText.Body.FloorName}
                                                        </Col>
                                                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)
                                                            ?
                                                            <Col xs={10} sm={10} md={9} lg={9}>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <MultiSelect
                                                                        hasSelectAll={true}
                                                                        options={this.state.csvFloorSelect}
                                                                        value = {this.state.csv_floor_id}
                                                                        onChange={e => this.onMultiSelectChange(e, "csv_floor_id")}
                                                                        overrideStrings={{
                                                                            selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                                                            allItemsAreSelected: this.props.langText.Body.AllSelected,
                                                                            selectAll: this.props.langText.MultiSelect.AllSelect,
                                                                            search: this.props.langText.Body.Search,
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Col>
                                                            :
                                                            <Col xs={10} sm={10} md={9} lg={9} className="padding-top-05 padding-left-30">
                                                                {this.props.userInfo.floor_name ? this.props.userInfo.floor_name : ""}
                                                            </Col>
                                                        }
                                                    </Col>
                                                }{<Col xs={10} sm={10} md={9} lg={9}>
                                                    <label className="margin-top-05 margin-left-1 search-message">
                                                        {this.state.validationMessage.map((row, key) =>
                                                            <p key={key}>{row}</p>
                                                        )}
                                                    </label>
                                                </Col>}
                                                <Col xs={12} sm={12} md={12} lg={12} 
                                                    className="margin-top-07 margin-bottom-07 text-align-center margin-right-1">
                                                    <Col className="pull-right margin-right-15">
                                                        <Button
                                                            className='table-button'
                                                            bsStyle='default'
                                                            bsSize='sm'
                                                            onClick={this.createCsvData}
                                                            disabled = {this.state.validationMessage.length > 0}>
                                                            {this.props.langText.Body.CSVExport}
                                                        </Button>
                                                        <CSVLink
                                                            data={this.state.csvData}
                                                            headers={this.state.csvHeader}
                                                            filename={this.getCsvFileName()}
                                                            className="csv-download-link-format hidden"
                                                            ref={this.csvLink}
                                                        />
                                                    </Col>
                                                </Col>
                                            </Col>
                                        </SettingMenu>
                                    </Dropdown>
                                </div>
                            </Col>}
                        </Col>
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual       = 'manual'
                            columns      = {this.state.columns}
                            data         = {this.state.data}
                            pages        = {this.state.pages}
                            loading      = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData  = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable   = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText     = {this.props.langText.Table.NextText}
                            loadingText  = {this.props.langText.Table.LoadingText}
                            noDataText   = {this.props.langText.Table.NoDataText}
                            rowsText     = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <ExternalNumberModal
                    state = {this.state}
                    userInfo = {this.props.userInfo}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    systemParameters = {this.props.systemParameters}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onIntChange = {this.onIntChange}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onSelectChange = {this.onSelectChange}
                    onMultiTelNumberChangeLimit = {this.onMultiTelNumberChangeLimit}
                    onDepartmentChange = {this.departmentInfoSet}
                    validationHandle = {this.validationHandle}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onMultiSelectChangeExternal = {this.onMultiSelectChangeExternal}
                    getDepartmentSelect = {this.getDepartmentSelect}
                    inboundGroupTypeSelectChanged = {this.inboundGroupTypeSelectChanged}
                    departmentInfos_onTextChange_Limit = {this.departmentInfos_onTextChange_Limit}
                    currentPermission = {this.props.currentPermission}
                    usedPrefixSearch={this.usedPrefixSearch}
                    getScopeGreaterEqual={this.getScopeGreaterEqual}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    numberWithCommas = {this.numberWithCommas}
                    getSoundName = {this.getSoundName}
                    usedDisplayNumbersearch={this.usedDisplayNumbersearch}
                    usedBackNumberSearch={this.usedBackNumberSearch}
                    boardWidth = {this.props.boardWidth}
                    showErrorObjectMesssage = {this.showErrorObjectMesssage}
                    sprintf = {this.sprintf}
                />
            </div>
        )
    }
}

class SettingMenu extends React.Component {

    render() {
        const { children } = this.props;

        return (
            <Dropdown.Menu className="dropdown-menu-right externalNumber-dropdown-menu">
                {React.Children.toArray(children)}
            </Dropdown.Menu>
        );
    }
}
