import React from "react"
import Component from "../../components/AscComponent";
import {Col, ControlLabel} from "react-bootstrap"
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import ASSelect from "../Elements/Select/Select";
import InlineForm from "../Elements/FromGroup/InlineForm"
import DatePicker from "react-datepicker";
import "../Elements/FromGroup/form_select.css"
import moment from "moment";


export default class ExternalNumberScheduleDateTimeElements extends Component {

    constructor(props) {
        super(props);
        // 時
        this.hour_list = this.getSelectOption(props.hourShowList, this.props.langText.SelectOption);
        // 分
        this.min_list = this.getSelectOption(props.minutesShowList, this.props.langText.SelectOption);
        // 時間と分のセレクトボックスのサイズ設定
        this.customStyles = {
            menuList: (provided) => ({
                ...provided,
                maxHeight: '200px',
                overflowY: 'auto'
            })
        };
    }

    scheduleDateTimeItem() {
        let {
            state,
            langText,
            validationHandle,
            onDateChangeHandle,
            scheduleType
        } = this.props;

        let itemArr = [];
        if(!state[`${scheduleType}_date_validation`]){
            itemArr.push(
                <div
                    key = {"schedule-validation"}
                    className = {"schedule-validation"}
                >
                    {langText.Message[state.validate_msg]}
                </div>
            );
        }
        itemArr.push(
            <InlineForm
                key = "start-datetime"
                label = {langText.Body.ScheduledStartDateTime}
                componentClass = {ControlLabel}
                validationState = {validationHandle(`${scheduleType}_start_datetime`)}
            >
                <Col xs={11} sm={11} md={4} lg={4}>
                    <span className="start-datetime">
                        <DatePicker
                            onChange = {e => onDateChangeHandle(e, scheduleType, "start_datetime")}
                            selected = {state[`${scheduleType}_start_datetime`] ? moment(state[`${scheduleType}_start_datetime`]) : null}
                            dateFormat = "YYYY-MM-DD"
                            className = "form-control"
                            todayButton = {this.props.langText.Body.Today}
                            placeholderText = {this.props.langText.Body.StartDate}
                            minDate = {moment({h:0, m:0, s:0, ms:0})}
                            isClearable = {true}
                        />
                    </span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="start_time_hour"
                        validationState = {validationHandle(`${scheduleType}_start_time_hour`)}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            value={state[`${scheduleType}_start_time_hour`]}
                            options={this.hour_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, scheduleType, "start_time_hour")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="start_time_minutes"
                        validationState = {validationHandle(`${scheduleType}_start_time_minutes`)}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="minutes-select"
                            value={state[`${scheduleType}_start_time_minutes`]}
                            options={this.min_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, scheduleType, "start_time_minutes")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                </Col>
            </InlineForm>
        );

        itemArr.push(
            <InlineForm
                key = "end-datetime"
                label = {langText.Body.ScheduledEndDateTime}
                componentClass = {ControlLabel}
                validationState = {validationHandle(`${scheduleType}_end_datetime`)}
            >
                <Col xs={11} sm={11} md={4} lg={4}>
                    <DatePicker
                        onChange = {e => onDateChangeHandle(e, scheduleType, "end_datetime")}
                        selected = {state[`${scheduleType}_end_datetime`] ? moment(state[`${scheduleType}_end_datetime`]) : null}
                        dateFormat = "YYYY-MM-DD"
                        className = "form-control"
                        todayButton = {this.props.langText.Body.Today}
                        placeholderText = {this.props.langText.Body.EndDate}
                        minDate = {state[`${scheduleType}_start_datetime`]? moment(state[`${scheduleType}_start_datetime`]) : null}
                        maxDate = {moment(state[`${scheduleType}_start_datetime`]).add(1,'weeks')}
                        isClearable = {true}
                    />
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="end_time_hour"
                        validationState = {validationHandle(`${scheduleType}_end_time_hour`)}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            styles={this.customStyles}
                            value={state[`${scheduleType}_end_time_hour`]}
                            options={this.hour_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, scheduleType, "end_time_hour")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="end_time_minutes"
                        validationState = {validationHandle(`${scheduleType}_end_time_minutes`)}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            styles={this.customStyles}
                            value={state[`${scheduleType}_end_time_minutes`]}
                            options={this.min_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, scheduleType, "end_time_minutes")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                </Col>
            </InlineForm>
        );

        return itemArr;
    }

    render() {
        return this.scheduleDateTimeItem();
    }
}
