import React from "react";
import InlineForm from "../FromGroup/InlineForm";
import AscComponent from "../../../components/AscComponent";
import MultiValueForm from "../FromGroup/MultiValueForm"

/**
 * キーワードグループ新規登録
 */
export class InsertKeywordGroup extends AscComponent {
    render() {
        let {
            state,
            langText,
            charaLimit,
            onSelectChange,
            validationHandle,
            onTextChange_Limit,
            onMultiValueFormTextChange,
            onMultiValueFormSizeChange,
            checkDuplicateKeywordGroupName,
            onBlurHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm
                key = "keyword_group_name"
                controlId = "keyword_group_name"
                label = {langText.Body.KeywordGroupName}
                type = "text"
                value = {state.keyword_group_name}
                onChange = {e => onTextChange_Limit(
                    e, "keyword_group_name", charaLimit.KeywordGroup_Name)}
                validationState = {validationHandle("keyword_group_name")}
                onBlur={e => checkDuplicateKeywordGroupName(e)}
            />
        );
        if(state.keyword_group_name_duplicate_flag){
            itemArr.push(
                <p key = "keyword_group_name_duplicate" className="text-danger">
                    {langText.Body.KeywordGroupNameDuplicate}
                </p>
            );
        }

        itemArr.push(
            <InlineForm
                key = "keyword_type"
                controlId = "keyword_type"
                label = {langText.Body.KeywordType}
                type = "select"
                onChange = {e => onSelectChange(e, "keyword_type")}
                options = {state.keywordTypeSelect}
                value = {state.keyword_type}
                isClearable = {true}
                validationState = {validationHandle("keyword_type")}
                placeholder = {langText.SelectOption.Placeholder}
            />
        );

        itemArr.push(
            <InlineForm
                key = "memo"
                controlId = "memo"
                label = {langText.Body.Memo}
                type = "text"
                onChange = {e =>onTextChange_Limit(
                    e, "memo",charaLimit.KeywordGroup_Memo)}
                value = {state.memo}
            />
        );

        itemArr.push(
            <>
                <InlineForm
                    label = {langText.Body.Keyword}>
                    <MultiValueForm
                        key = "keywords"
                        controlId = "keywords"
                        value = {state.keywords}
                        isStatic = {state.modalType === "delete"}
                        onTextChange = {onMultiValueFormTextChange}
                        onSizeChange = {onMultiValueFormSizeChange}
                        onBlurHandle = {onBlurHandle}
                        placeholderTag = {langText.Body.Tag}
                        placeholderValue = {langText.Body.Keyword}
                        validationState = {validationHandle("keywords")}
                    />
                </InlineForm>
                {state.keyword_type === "hints" &&
                    `${langText.Body.TotalTokensCount}: ${state.totalCountTokens || 0}/${charaLimit.KeywordGroup_Token}`
                }
            </>
        );

        if (
            state.keyword_type === "hints" &&
            state.totalCountTokens > charaLimit.KeywordGroup_Token
        ) {
            itemArr.push(
                <p key={"tokenLengthExceeded"}>
                    <font color="red">{langText.Message.tokenLengthExceeded}</font>
                </p>
            );
        }

        return itemArr;
    }
}

/**
 * キーワードグループ編集
 */
export class UpdateKeywordGroup extends AscComponent {
    render() {
        let {
            state,
            langText,
            charaLimit,
            onSelectChange,
            validationHandle,
            onTextChange_Limit,
            onMultiValueFormTextChange,
            onMultiValueFormSizeChange,
            onBlurHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "keyword_group_name"
                controlId = "keyword_group_name"
                label = {langText.Body.KeywordGroupName}>
                {state.keyword_group_name ? state.keyword_group_name : ""}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "keyword_type"
                controlId = "keyword_type"
                label = {langText.Body.KeywordType}>
                {state.keyword_type
                    ? state.keywordTypeSelect.filter((type) => type.value === state.keyword_type)[0].label
                    : ""}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm
                key = "memo"
                controlId = "memo"
                label = {langText.Body.Memo}
                type = "text"
                onChange = {e =>onTextChange_Limit(e, "memo",charaLimit.KeywordGroup_Memo)}
                value = {state.memo}
            />
        );

        itemArr.push(
            <InlineForm
                label = {langText.Body.Keyword}>
                <MultiValueForm
                    key = "keywords"
                    controlId = "keywords"
                    value = {state.keywords}
                    isStatic = {state.modalType === "delete"}
                    onTextChange = {onMultiValueFormTextChange}
                    onSizeChange = {onMultiValueFormSizeChange}
                    onBlurHandle = {onBlurHandle}
                    placeholderTag = {langText.Body.Tag}
                    placeholderValue = {langText.Body.Keyword}
                    validationState = {validationHandle("keywords")}
                />
                {state.keyword_type === "hints" &&
                    `${langText.Body.TotalTokensCount}: ${state.totalCountTokens || 0}/${charaLimit.KeywordGroup_Token}`
                }
            </InlineForm>
        );
        if (
            state.keyword_type === "hints" &&
            state.totalCountTokens > charaLimit.KeywordGroup_Token
        ) {
            itemArr.push(
                <p key={"tokenLengthExceeded"}>
                    <font color="red">{langText.Message.tokenLengthExceeded}</font>
                </p>
            );
        }

        return itemArr;
    }
}

/**
 * キーワードグループ削除&閲覧
 */
export class DeleteAndReadKeywordGroup extends AscComponent {
    render() {
        let {
            state,
            langText,
            onMultiValueFormTextChange,
            onMultiValueFormSizeChange
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "keyword_group_name"
                controlId = "keyword_group_name"
                label = {langText.Body.KeywordGroupName}>
                {state.keyword_group_name ? state.keyword_group_name : ""}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key="keyword_type"
                controlId="keyword_type"
                label={langText.Body.KeywordType}>
                {state.keyword_type
                    ? state.keywordTypeSelect.filter((type) => type.value === state.keyword_type)[0].label
                    : ""}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "memo"
                controlId = "memo"
                label = {langText.Body.Memo}>
                {state.memo ? state.memo : ""}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm
                label = {langText.Body.Keyword}>
                <MultiValueForm
                    key = "keywords"
                    controlId = "keywords"
                    value = {state.keywords}
                    isStatic = {state.modalType === "delete" || state.modalType === "read"}
                    onTextChange = {onMultiValueFormTextChange}
                    onSizeChange = {onMultiValueFormSizeChange}
                    placeholderTag = {langText.Body.Tag}
                    placeholderValue = {langText.Body.Keyword}
                />
            </InlineForm>
        );

        return itemArr;
    }
}
