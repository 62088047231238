import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../../Elements/Modal/Modal";
import InlineForm from "../../Elements/FromGroup/InlineForm";
import "../../Elements/FromGroup/form_select.css";

export default class CallAnalysisSummaryTemplateCheckModal extends Component {
    createLabel = (selected, select, call_result_flag=false) => {
        let return_obj = [];

        if (call_result_flag) {
            selected.forEach(row => {
                return_obj.push(select.find(item => item.value.cm64_id === row.value.cm64_id 
                    && item.value.cm65_id === row.value.cm65_id 
                    && item.value.level === row.value.level 
                    && item.value.rank_type === row.value.rank_type).label);
            });
        } else {
            selected.forEach(row => {
                return_obj.push(select.find(item => item.value === row).label);
            });
        }

        return return_obj.map(row => {
            return (
                <p>{row}</p>
            );
        });
    }

    createNumberLabel = (start, end) => {
        let start_flag = false;
        let end_flag = false;
        let label = null;
        let start_label = "";
        let end_label = "";

        start.forEach(row => {
            let default_label = "0";
            let colon = "";
            
            switch (row.id) {
                case "start_hun":
                case "call_start_byo":
                    colon = ":"
                case "start_ji":
                case "call_start_hun":
                    if (row.value) row.value = colon + String(row.value).padStart(2, "0");
                    else default_label = colon + default_label.padStart(2, "0");
                    break;
                default:
                    break;
            }

            if (row.value) {
                start_flag = true;
                start_label += row.value;
            } else {
                start_label += default_label;
            }
        });

        end.forEach(row => {
            let default_label = "100";
            let default_23_label = "23";
            let default_59_label = "59";
            let colon = "";
            
            switch (row.id) {
                case "end_hun":
                case "call_end_byo":
                    colon = ":"
                case "end_ji":
                case "call_end_hun":
                    if (row.value) row.value = colon + String(row.value).padStart(2, "0");
                    else default_label = colon + (row.id.includes("ji") ? default_23_label : default_59_label);
                    break;
                default:
                    break;
            }

            if (row.value) {
                end_flag = true;
                end_label += row.value;
            } else {
                end_label += default_label;
            }
        });

        if (start_flag || end_flag) label = `${start_label} ~ ${end_label}`;

        return label;
    }

    getTemplateInfoItem = () => {
        let {
            propState,
            langText,
            getFilterData
        } = this.props;

        let itemArr = [];

        // テンプレート名
        itemArr.push(
            <InlineForm.static
                key="templateName"
                controlId="templateName"
                label={langText.Body.TemplateName}
            >
                {propState.templateName}
            </InlineForm.static>
        );

        // 会社名
        itemArr.push(
            <InlineForm.static
                key="companyName"
                controlId="companyName"
                label={langText.Body.CompanyName}
            >
                {getFilterData("cm12_id", propState.compare_flag).label || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // 拠点名
        itemArr.push(
            <InlineForm.static
                key="departmentName"
                controlId="departmentName"
                label={langText.Body.DepartmentName}
            >
                {getFilterData("department_id", propState.compare_flag).label || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // フロア名
        let company_info = getFilterData("cm12_id", propState.compare_flag);
        let floor_flg = company_info ? company_info.floor_flg : "N";

        if (floor_flg === "Y") {
            itemArr.push(
                <InlineForm.static
                    key="floorName"
                    controlId="floorName"
                    label={langText.Body.FloorName}
                >
                    {getFilterData("floor_id", propState.compare_flag).label || langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        // 期間指定
        itemArr.push(
            <InlineForm.static
                key="periodSetting"
                controlId="periodSetting"
                label={langText.Body.PeriodSettring}
            >
                {propState.periodSetting.label}
            </InlineForm.static>
        );

        if (propState.periodSetting.value === "custom") {
            // 日付
            itemArr.push(
                <InlineForm.static
                    key="date"
                    controlId="date"
                    label={langText.Body.Date}
                >
                    {`${propState.start_datetime 
                        ? propState.start_datetime.format("YYYY/MM/DD").toString() 
                        : null} ~ ${propState.end_datetime 
                                    ? propState.end_datetime.format("YYYY/MM/DD").toString() 
                                    : null}`}
                </InlineForm.static>
            );
        }

        // 曜日
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="weekOrnament"
                    controlId="weekOrnament"
                    label={langText.Body.WeekOrnament}
                >
                    {propState.weekday_selected && propState.weekday_selected.length > 0 
                        ? this.createLabel(propState.weekday_selected, propState.weekday_select) 
                        : langText.SelectOption.Unset}
                </InlineForm>
            </div>
        );

        // 通話時間帯
        let start_time_arr = [{id: "start_ji", value: propState.start_ji}, {id: "start_hun", value: propState.start_hun}];
        let end_time_arr = [{id: "end_ji", value: propState.end_ji}, {id: "end_hun", value: propState.end_hun}];
        itemArr.push(
            <InlineForm.static
                key="callTimeZone"
                controlId="callTimeZone"
                label={langText.Body.CallTimeZone}
            >
                {this.createNumberLabel(start_time_arr, end_time_arr) || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // オペレーター名
        let operator_selected_id = getFilterData("operator_selected_id", propState.compare_flag);
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="operatorName"
                    controlId="operatorName"
                    label={langText.Body.OperatorName}
                >
                    {operator_selected_id && operator_selected_id.length > 0 
                        ? this.createLabel(operator_selected_id, propState.operator_info_select) 
                        : langText.SelectOption.Unset}
                </InlineForm>
            </div>
        );
        
        // 内線番号
        let extension_number_selected = getFilterData("extension_number_selected", propState.compare_flag);
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="extensionNumber"
                    controlId="extensionNumber"
                    label={langText.Body.ExtensionNumber}
                >
                    {extension_number_selected && extension_number_selected.length > 0 
                        ? this.createLabel(extension_number_selected, propState.extension_number_select) 
                        : langText.SelectOption.Unset}
                </InlineForm>
            </div>
        );

        // 外線番号
        let external_number = getFilterData("external_number", propState.compare_flag);
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="externalNumber"
                    controlId="externalNumber"
                    label={langText.Body.ExternalNumber}
                >
                    {external_number && external_number.length > 0 
                        ? this.createLabel(external_number, propState.external_number_select) 
                        : langText.SelectOption.Unset}
                </InlineForm>
            </div>
        );
        
        // 発信区分
        itemArr.push(
            <InlineForm.static
                key="inOutType"
                controlId="inOutType"
                label={langText.Body.InOutType}
            >
                {
                    getFilterData("inout_type", propState.compare_flag) === "all" ? langText.Body.All
                    : getFilterData("inout_type", propState.compare_flag) === "in" ? langText.Body.Inbound
                    : langText.Body.Call                   
                }
            </InlineForm.static>
        );

        // 通話結果
        let call_result = getFilterData("call_result", propState.compare_flag);
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="callHistory"
                    controlId="callHistory"
                    label={langText.Body.CallHistory}
                >
                    {call_result && call_result.length > 0 ? this.createLabel(call_result, propState.call_result_select) : langText.SelectOption.Unset}
                </InlineForm>
            </div>
        );

        // コール結果絞り込み条件
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="callResultFilter"
                    controlId="callResultFilter"
                    label={langText.Body.CallResultFilter}
                >
                    {
                        propState.compare_flag 
                        ? propState.compare_call_result_filter && propState.compare_call_result_filter.length > 0 
                            ? this.createLabel(propState.compare_call_result_filter, propState.compare_call_result_filter_select, true) 
                            : langText.SelectOption.Unset 
                        : propState.call_result_filter && propState.call_result_filter.length > 0 
                            ? this.createLabel(propState.call_result_filter, propState.call_result_filter_select, true) 
                            : langText.SelectOption.Unset
                    }
                </InlineForm>
            </div>
        );

        // コール結果除外条件
        itemArr.push(
            <div className="static-form">
                <InlineForm
                    key="callResultExclusion"
                    controlId="callResultExclusion"
                    label={langText.Body.CallResultExclusion}
                >
                    {
                        propState.compare_flag 
                        ? propState.compare_call_result_exclusion && propState.compare_call_result_exclusion.length > 0 
                            ? this.createLabel(propState.compare_call_result_exclusion, propState.compare_call_result_exclusion_select, true) 
                            : langText.SelectOption.Unset 
                        : propState.call_result_exclusion && propState.call_result_exclusion.length > 0 
                            ? this.createLabel(propState.call_result_exclusion, propState.call_result_exclusion_select, true) 
                            : langText.SelectOption.Unset
                    }
                </InlineForm>
            </div>
        );

        // 通話分類タグ
        let tags = getFilterData("tags", propState.compare_flag);
        itemArr.push(
            <InlineForm.static
                key="tag"
                controlId="tag"
                label={langText.Body.CallCategoryTag}
            >
                {
                    (tags && tags.length > 0) ?
                    tags.map((row, index) => {
                        return (
                            <span
                                key={index}
                                style={{
                                    marginRight: '8px'
                                }}
                            >
                                {row.label}
                            </span>
                        )
                    }) :
                    langText.SelectOption.Unset
                }
            </InlineForm.static>
        );

        return itemArr;
    }

    // 詳細条件
    getTemplateDetailInfoItem = () => {
        let {
            propState,
            langText,
            getFilterData
        } = this.props;
        let itemArr = [];

        // 通話時間
        let start_time_arr = [{id: "call_start_hun", value: getFilterData("call_start_hun", propState.compare_flag)},
                {id: "call_start_byo", value: getFilterData("call_start_byo", propState.compare_flag)}];
        let end_time_arr = [{id: "call_end_hun", value: getFilterData("call_end_hun", propState.compare_flag)}, 
                {id: "call_end_byo", value: getFilterData("call_end_byo", propState.compare_flag)}];
        itemArr.push(
            <InlineForm.static
                key="talkingTime"
                controlId="talkingTime"
                label={langText.Body.TalkingTime}
            >
                {this.createNumberLabel(start_time_arr, end_time_arr) || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // 総合評価
        let min_score_arr = [{id: "min_score", value: getFilterData("min_score", propState.compare_flag)}];
        let max_score_arr = [{id: "max_score", value: getFilterData("max_score", propState.compare_flag)}];
        itemArr.push(
            <InlineForm.static
                key="score"
                controlId="score"
                label={langText.Body.Score}
            >
                {this.createNumberLabel(min_score_arr, max_score_arr) || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // OP会話比率
        let min_talk_rate_arr = [{id: "min_talk_rate", value: getFilterData("min_talk_rate", propState.compare_flag)}];
        let max_talk_rate_arr = [{id: "max_talk_rate", value: getFilterData("max_talk_rate", propState.compare_flag)}];
        itemArr.push(
            <InlineForm.static
                key="talkRate"
                controlId="talkRate"
                label={langText.Body.TalkRate}
            >
                {this.createNumberLabel(min_talk_rate_arr, max_talk_rate_arr) || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // 会話速度差
        let min_speech_speed_arr = [{id: "min_speech_speed", value: getFilterData("min_speech_speed", propState.compare_flag)}];
        let max_speech_speed_arr = [{id: "max_speech_speed", value: getFilterData("max_speech_speed", propState.compare_flag)}];
        itemArr.push(
            <InlineForm.static
                key="talkSpeedDifference"
                controlId="talkSpeedDifference"
                label={langText.Body.TalkSpeedDifference}
            >
                {this.createNumberLabel(min_speech_speed_arr, max_speech_speed_arr) || langText.SelectOption.Unset}
            </InlineForm.static>
        );

        // 集計方法
        itemArr.push(
            <InlineForm.static
                key="summaryMethod"
                controlId="summaryMethod"
                label={langText.Body.SummaryMethod}
            >
                {getFilterData("summary_method", propState.compare_flag) === "average" ? langText.Body.AverageValue : langText.Body.MedianValue}
            </InlineForm.static>
        );

        return itemArr;
    }

    render() {
        return (
            <div className="CallAnalysisTemplateCheckModal">
                <AscModal
                    state={this.props.state}
                    propSetState={this.props.propSetState}
                    langText={this.props.langText}
                    validationHandle={this.props.validationHandle}
                    customFunc={() => {
                        if (this.props.state.modalType === "templateDelete") this.props.onClick(this.props.state.modalType, this.props.state.compare_flag);
                        else this.props.onClick()
                    }}
                >
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.getTitle()}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            {this.getTemplateInfoItem()}
                            {this.getTemplateDetailInfoItem()}
                        </Panel.Body>
                    </Panel>
                </AscModal>
            </div>
        );
    }
}
