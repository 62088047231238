import React, { Component } from "react";
import { Button, Modal, Form, Row, Col, Glyphicon } from "react-bootstrap";
import "./Modal.css";
import ReactLoading from "react-loading";

export default class AscModal extends Component {
    // get modal title
    getTitle = (type) => {
        let title = null;
        let okBtnDisplayFlg = true;

        switch (this.props.state.modalType) {
            case "insert":
            case "create":
            case "dispensing_insert":
            case "wait_call_infos_insert":
            case "over_wait_call_infos_insert":
                title =
                    type === "title"
                        ? this.props.langText.Body.InsertTitle
                        : this.props.langText.Body.Insert;
                break;
            case "insertBatch":
                title =
                    type === "title"
                        ? this.props.langText.Body.InsertBatchTitle
                        : this.props.langText.Body.Insert;
                break;
            case "update":
            case "gwAssignPbx":
            case "templateSelect":
            case "dispensing_update":
            case "wait_call_infos_update":
            case "over_wait_call_infos_update":
                title =
                    type === "title"
                        ? this.props.langText.Body.UpdateTitle
                        : this.props.langText.Body.Update;
                break;
            case "updateExtension":
                title =
                    type === "title"
                        ? this.props.langText.Body.UpdateAllExternalNum
                        : this.props.langText.Body.Insert;
                break;
            case "delete":
                title =
                    type === "title"
                        ? this.props.langText.Body.DeleteTitle
                        : this.props.langText.Body.Delete;
                break;
            case "verify":
                title =
                    type === "title"
                        ? this.props.langText.Body.Verify
                        : this.props.langText.Body.Submit;
                break;
            case "submitNewPassword":
                title =
                    type === "title"
                        ? this.props.langText.Body.ResetPassword
                        : this.props.langText.Body.ResetPassword;
                break;
            case "resetPassword":
                title =
                    type === "title"
                        ? this.props.langText.Body.CodeSubmit
                        : this.props.langText.Body.CodeSubmit;
                break;
            case "csvInsert":
                title =
                    type === "title"
                        ? this.props.langText.Body.CustomerCsvImport
                        : this.props.langText.Body.Insert;
                break;
            case "calendar_set":
                title =
                    type === "title"
                        ? this.props.langText.Body.BusinessHourSetting
                        : this.props.langText.Body.Set;
                break;
            case "calendar_ire":
                title =
                    type === "title"
                        ? this.props.langText.Body.AddIrregular
                        : this.props.langText.Body.Add;
                break;
            case "download":
                title =
                    type === "title"
                        ? this.props.langText.Body.DownloadFlag
                        : this.props.langText.Body.Download;
                break;
            case "irregularBatchSetting":
                title =
                    type === "title"
                        ? this.props.langText.Body.IrregularBatchSetting
                        : this.props.langText.Body.Check;
                break;
            case "irregularBatchCheck":
                title =
                    type === "title"
                        ? this.props.langText.Body.IrregularBatchSetting
                        : this.props.langText.Body.Insert;
                break;
            case "copy":
            case "dispensing_copy":
            case "wait_call_infos_copy":
            case "over_wait_call_infos_copy":
                title =
                    type === "title"
                        ? this.props.langText.Body.CopyTitle
                        : this.props.langText.Body.Copy;
                break;
            case "read":
                title = this.props.langText.Body.ReadTitle;
                okBtnDisplayFlg = false;
                break;
            case "setting":
                title = this.props.langText.Body.Set;
                break;
            case "autoCallListManagement":
                title =
                    type === "title"
                        ? this.props.langText.Body.AutoCallListManagement
                        : this.props.langText.Body.Update;
                break;
            case "multi_delete":
                title =
                    type === "title"
                        ? this.props.langText.Body.BatchDelete
                        : this.props.langText.Body.Delete;
                break;
            case "analysis":
                title =
                    type === "title"
                        ? this.props.langText.Body.AnalysisContent
                        : this.props.langText.Body.Check;
                break;
            case "statistics":
                title = this.props.langText.Body.StatisticsTitle;
                okBtnDisplayFlg = false;
                break;
            case "historyStatistics":
                title = this.props.langText.Body.HistoryStatisticsTitle;
                okBtnDisplayFlg = false;
                break;
            case "updateSelfPassword":
                title =
                    type === "title"
                        ? this.props.langText.Body.ChangePasswordTitle
                        : this.props.langText.Body.Update;
                break;
            case "templateInsert":
                title =
                    type === "title"
                        ? this.props.langText.Body.CallAnalysisTemplateInsert
                        : this.props.langText.Body.Insert;
                break;
            case "templateUpdate":
                title =
                    type === "title"
                        ? this.props.langText.Body.CallAnalysisTemplateUpdate
                        : this.props.langText.Body.Update;
                break;
            case "templateDelete":
                title =
                    type === "title"
                        ? this.props.langText.Body.CallAnalysisTemplateDelete
                        : this.props.langText.Body.Delete;
                break;
            case "templateCopy":
                title =
                    type === "title"
                        ? this.props.langText.Body.CallAnalysisTemplateCopy
                        : this.props.langText.Body.Copy;
                break;
            case "callHistoryDownload":
                title =
                    type === "title"
                        ? this.props.langText.Body.CallHistoryDownload
                        : this.props.langText.Body.Exec;
                break;
            case "callContentDownload":
                title =
                    type === "title"
                        ? this.props.langText.Body.CallContentDownload
                        : this.props.langText.Body.Exec;
                break;
            case "downloadHistoryDetail":
                title = this.props.langText.Body.DownloadHistoryDetail
                okBtnDisplayFlg = false;
                break;
            case "redo":
                title =
                    type === "title"
                        ? this.props.langText.Body.Redo
                        : this.props.langText.Body.Exec;
                break;
            case "errorContent":
                title =
                    type === "title"
                        ? this.props.langText.Body.ErrorTitle
                        : this.props.langText.Body.Copy;
                break;
            case "callRecordingPlay":
                title = this.props.langText.Body.callRecordingPlayTitle;
                okBtnDisplayFlg = false;
                break;
            default:
        }

        return { title, okBtnDisplayFlg };
    };

    customBtn1 = () => {
        return (
            <Button
                className={this.props.customBtn1Class}
                onClick={this.props.customBtn1Func}
                disabled={
                    this.props.customBtn1Disabled
                        ? !this.props.customBtn1Disabled(this.props.state.modalType)
                        : false
                }>
                {this.props.customBtn1Msg}
            </Button>
        );
    };

    customBtn2 = () => {
        return (
            <Button
                className={this.props.customBtn2Class}
                onClick={this.props.customBtn2Func}
                disabled={
                    this.props.customBtn2Disabled
                        ? !this.props.customBtn2Disabled(this.props.state.modalType)
                        : false
                }>
                {this.props.customBtn2Msg}
            </Button>
        );
    };

    customOkBtn = () => {
        let result = this.getTitle("ok");

        if (result.okBtnDisplayFlg) {
            return (
                <Button
                    className="ok-execute"
                    onClick={this.props.customFunc}
                    bsStyle={this.props.state.modalType !== "delete" ? "primary" : "danger"}
                    disabled={!this.props.validationHandle(this.props.state.modalType)}>
                    {this.props.customMsg || result.title}
                </Button>
            );
        } else {
            return null;
        }
    };

    getOkBtn = () => {
        let result = this.getTitle("ok");

        if (result.okBtnDisplayFlg) {
            return (
                <Button
                    className="ok-execute"
                    onClick={this.props.onClick(this.props.state.modalType)}
                    bsStyle={this.props.state.modalType !== "delete" ? "primary" : "danger"}
                    disabled={!this.props.validationHandle(this.props.state.modalType)}>
                    {result.title}
                </Button>
            );
        } else {
            return null;
        }
    };

    getCancelBtn = () => {
        return (
            <Button
                className="cancel-execute"
                onClick={(e) => this.props.propSetState({ show: false })}
                variant="outline-secondary">
                {this.props.langText.Body.Cancel}
            </Button>
        );
    };

    getOmniPhoneLogOutBtn = () => {
        let { state, omniphoneLoginStatusDelete } = this.props;

        return (
            <Button
                bsStyle="danger"
                className="cancel-execute"
                onClick={(e) => omniphoneLoginStatusDelete(state.omniphone_login_data)}
                variant="outline-secondary">
                {this.props.langText.Body.OmniPhoneLogOut}
            </Button>
        );
    };

    getCancelLockingBtn = () => {
        return (
            <Button
                className="cancel-locking"
                onClick={this.props.onClickCancelLockingBtn(this.props.state.userId)}
                variant="outline-secondary"
            >
                {this.props.langText.Body.CancelLocking}
            </Button>
        );
    };

    getXBtn = () => {
        return (
            <Button className="x-execute" onClick={(e) => this.props.propSetState({ show: false })}>
                <Glyphicon glyph="remove" />
            </Button>
        );
    };

    modalLoadingDisplay = () => {
        if (this.props.state.loadingDisplayFlag) {
            return (
                <div className="asc-block-ui">
                    <div className="asc-block-icon-box">
                        <ReactLoading
                            className="asc-block-icon"
                            type="spinningBubbles"
                            color="#fff"
                        />
                        <label className="asc-block-label">
                            {this.props.langText.Table.LoadingText}
                        </label>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    };

    render() {
        let { children, state, customFunc, customBtn1Func, customBtn2Func } = this.props;
        let result = this.getTitle("title");
        return (
            <Modal
                dialogClassName={this.props.dialogClassName}
                scrollable="true"
                show={state.show}
                bsSize="large"
                aria-labelledby="contained-modal-title-lg">
                <Form horizontal={true}>
                    {this.modalLoadingDisplay()}
                    <Modal.Header>
                        <Modal.Title>
                            <Row>
                                <Col className="pull-left">{result.title}</Col>
                                <Col className="pull-right">
                                    <span>{this.getXBtn()}</span>
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>{children}</Modal.Body>

                    <Modal.Footer>
                        <Row>
                            <Col className="panel-button pull-right">
                                {state.omniphone_logout_btn_display_flag
                                    ? this.getOmniPhoneLogOutBtn()
                                    : ""}
                                {state.loginLockFlag
                                    ? this.getCancelLockingBtn()
                                    : ""}
                                <span>
                                    {customBtn1Func ? this.customBtn1() : this.getCancelBtn()}
                                </span>
                                {customBtn2Func ? (
                                    <span>{this.customBtn2()}</span>
                                ) : customFunc ? (
                                    <span>{this.customOkBtn()}</span>
                                ) : (
                                    <span>{this.getOkBtn()}</span>
                                )}
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
