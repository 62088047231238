export default {

    // 会社管理「追加オプション」の各ラジオボタンに設定するlabel
    CallRecordingOptionLabel : "通話録音",
    IvrOptionLabel : "IVR",
    IvrTranslateOptionLabel : "多言語翻訳機能",
    CallAnalysisOptionLabel : "通話品質分析",
    CallAnalysisApiOptionLabel : "┗ 通話品質分析API",
    CallAutoTagOptionLabel: "通話分類タグ",
    CallEvalOptionLabel: "応対品質分析",
    AcdOptionLabel : "ACD機能",
    OptionLabelTree : "┗ ",
    CallResultOptionLabel : "コール結果",

    // cm98.memo設定値
    CallRecordingOptionAllowed : "通話録音オプション許可",
    IvrOptionAllowed : "IVRオプション許可",
    IvrTranslateOptionAllowed : "IVR多言語翻訳オプション許可",
    CallAnalysisOptionAllowed : "通話品質分析オプション許可",
    CallAnalysisApiOptionAllowed : "通話品質分析APIオプション設定",
    CallAutoTagOptionAllowed: "通話分類オプション許可",
    CallEvalOptionAllowed: "応対品質分析オプション許可",
    AcdOptionAllowed : "ACD機能オプション許可",
    CallResultOptionAllowed : "コール結果・ランク許可",

    call_analysis_api_select: [
        { value: "speech_to_text", label: "Speech To Text"},
        { value: "whisper", label: "Whisper"},
        { value: "ascend_whisper", label: "Ascend Whisper"}
    ]
}
