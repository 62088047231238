import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Glyphicon} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import ExternalNumberScheduleDateTimeElements from "./ExternalNumberScheduleDateTimeElements";


export default class ExternalNumberScheduleExtensionModal extends Component {

    constructor(props) {
        super(props);
    }

    getScheduleExtensionInfoItem() {
        let {
            state,
            langText,
            systemParameters,
            validationHandle
        } = this.props;

        let itemArr = [];

        itemArr.push(
            <InlineForm
                key = "schedule_extenxion_group"
                controlId = "schedule_extenxion_group"
                label = {langText.Body.InboundGroupName}
                type = "select"
                onChange = {e => this.props.onMultiSelectChangeExternal(e, "schedule_extenxion_group")}
                options = {state.inboundGroupSelect}
                value = {state.schedule_extenxion_group}
                isClearable = {true}
                validationState = {validationHandle("schedule_extenxion_group")}
                isMulti = {true}
                closeMenuOnSelect={false}
                placeholder = {this.props.propState.buttondisabledArr.inbound_group_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                isDisabled = {!this.props.propState.buttondisabledArr.inbound_group_id[0]}
                />
        );
        let drawdata = '';
        let totalExtensionCount = 0;
        if (
                ( state.modalType === "update" || state.modalType === "copy" ) &&
                state.schedule_extenxion_group.length > 0 &&
                state.inboundGroupcount === 0
        ) {
            // 編集orコピーモーダルかつ初期表示の場合、選択済み内線グループの合計内線数を設定
            state.schedule_extenxion_group.forEach(selectedGroupValue => {
                totalExtensionCount += state.inboundGroupSelect.find(allGroup => allGroup.value === selectedGroupValue).count;
            });
        } else {
            totalExtensionCount = state.inboundGroupcount;
        }

        let drawcolor = 'black';
        if(totalExtensionCount > systemParameters.EXTEN_COUNT)
        {
            drawdata = totalExtensionCount + " / " + systemParameters.EXTEN_COUNT + " " + langText.Body.ExtenCountOver_1 + systemParameters.EXTEN_COUNT + langText.Body.ExtenCountOver_2;
            drawcolor = 'red';
        }
        else
        {
            drawdata = totalExtensionCount + " / " + systemParameters.EXTEN_COUNT;
            drawcolor = 'black';
        }
        itemArr.push(
            <InlineForm.static
                key = "exten_count"
                controlId = "exten_count"
                label = {langText.Body.ExtenCount}>
                    <font color={drawcolor}>
                        {drawdata}
                    </font>
            </InlineForm.static>
        )

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ScheduleExtensionGroupSettings}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <ExternalNumberScheduleDateTimeElements
                            state = {this.props.state}
                            validationHandle = {this.props.validationHandle}
                            langText = {this.props.langText}
                            onDateChangeHandle = {this.props.onDateChangeHandle}
                            scheduleType = "inbound_group"
                            hourShowList = "hour_show_list"
                            minutesShowList = "minute_every_five_show_list"
                        />
                        {this.getScheduleExtensionInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
