import React from "react";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import { Button, Row, Col, Glyphicon, FormControl } from "react-bootstrap";
import * as GlobalConst from "../../components/AscConstants";
import SkillManagementModal from "./SkillManagementModal";
import SkillCsvImportModal from "./SkillCsvImportModal";
import Favi from "../Elements/Favi/Favi";
import moment from "moment";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import Select from "react-select";
import { parseInt } from "lodash";
import { CSVLink } from "react-csv";
export default class SkillManagement extends AscComponent {
    constructor(props) {
        super(props);
        this.modalShow = this.modalShow.bind(this);
        this.state = Object.assign(
            {
                columns: this.getColumnsData(),
                data: [],
                filtered: [],
                pages: null,
                loading: false,
                pageSize: null,
                csvData: [],
                insert_modal_column: this.getColumData_insertModal(),
                refresh_flag: false
            },
            this.initState()
        );
        this.setTableData();

        // csvデータ作成準備
        this.csvLink = React.createRef();
        this.header_json = [
            {
                label: this.props.langText.Body.OperatorId,
                key: "cm16_operators.operator_id"
            },
            {
                label: this.props.langText.Body.SkillRank,
                key: "skill_rank"
            },
            {
                label: this.props.langText.Body.Memo,
                key: "memo"
            }
        ];

        window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
            event.returnValue = "";
        });
    }

    initState() {
        return {
            show: false,
            modalType: "",
            task_id: this.props.location.state ? this.props.location.state.display_data.id : "",
            task_company_id: this.props.location.state
                ? this.props.location.state.display_data.cm12_id
                : "",
            task_company_floor_flg:
                this.props.location.state.display_data.cm12_companies.floor_flg === "Y"
                    ? true
                    : false,
            task_external_numbers: this.props.location.state
                ? this.props.location.state.display_data.task_external_numbers.map((row) => {
                      return row.dist_cm61_id;
                  })
                : [],
            business_name: this.props.location.state
                ? this.props.location.state.display_data.business_name
                : "",
            memo: "",
            operator_id: [],
            operatorIdSelect: [],
            departmentSelect: [],
            department_id: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)
                ? ""
                : this.props.userInfo.department_id,
            selected_department: "",
            skill_ranks: this.setSkillRanks(),
            selected_skill: "",
            floorSelect: [],
            selected_floor: "",
            // 削除モーダル用
            target_id: "",
            target_task_id: "",
            target_operator: [],
            target_skill_rank: "",
            target_memo: "",
            csvFile: false,
            insert_csv_data: [],
            error_message: [],
            exchange_flag: false
        };
    }

    // 画面を開いた際、dataを取得する
    async setTableData() {
        this.setState({ loading: true });
        try {
            let res = await this.ascAxios("post", `${this.reactContainerPath}/board`, {
                searchData:
                    this.props.location.state === undefined
                        ? null
                        : this.props.location.state.display_data.id
            });

            let count = 0;
            if (Array.isArray(res.data.count)) {
                count = res.data.count.length;
            } else {
                count = res.data.count;
            }
            let data = res.data.rows;
            data.map((rows) => (rows.row_status = ""));

            this.setState({
                data,
                csvData: data,
                count: count,
                loading: false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                data: [],
                pages: null,
                count: null,
                loading: false
            });
            if (
                !(e.status && e.status === 401) &&
                !(e.response && e.response.status && e.response.status === 401)
            ) {
                this.showErrorObjectMesssage(e, "GetDataError_Server");
            }
        }
    }

    // スキルランクプルダウンセット
    setSkillRanks() {
        const max_rank = 16;
        let rank_level = "";
        let skill_ranks = [];

        for (let i = 1; i <= max_rank; i++) {
            if (i === 1) {
                rank_level = "(高)";
            } else if (i === max_rank) {
                rank_level = "(低)";
            } else {
                rank_level = "";
            }
            skill_ranks.push({ label: `SR:${i}${rank_level}`, value: i });
        }

        return skill_ranks;
    }

    // モーダルを閉じた際、stateデータを初期化する
    componentDidUpdate(prevProps, prevState) {
        if (this.state.show === false && prevState.show === true) {
            this.setState(this.initState());
        }
        if (this.state.refresh_flag !== prevState.refresh_flag) {
            this.setTableData();
        }
    }

    getColumnsData = () => {
        let skillStatusList = this.getSelectOption(
            "skill_status_list",
            this.props.langText.SelectOption
        );
        return [
            {
                Header: this.props.langText.Body.OperatorId,
                accessor: "cm16_operators.operator_id",
                width: this.props.boardWidth.large,
                Filter: () => (
                    <FormControl
                        onChange={(e) =>
                            this.onFilteredChangeCustom(
                                e.target.value,
                                "cm16_operators.operator_id"
                            )
                        }
                    />
                )
            },
            {
                Header: this.props.langText.Body.OperatorName,
                accessor: "cm16_operators.operator_name",
                width: this.props.boardWidth.large,
                Filter: () => (
                    <FormControl
                        onChange={(e) =>
                            this.onFilteredChangeCustom(
                                e.target.value,
                                "cm16_operators.operator_name"
                            )
                        }
                    />
                )
            },
            {
                id: "skill_rank",
                Header: this.props.langText.Body.SkillRank,
                accessor: (data) => data.skill_rank,
                width: this.props.boardWidth.xxlarge,
                Filter: () => (
                    <Select
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        isMulti={true}
                        onChange={(e) => {
                            let select_options = [].slice.call(e).map((e_row) => {
                                return e_row.value;
                            });
                            this.onFilteredChangeCustom(select_options, "skill_rank");
                        }}
                        options={this.state.skill_ranks}
                        placeholder={this.props.langText.SelectOption.Placeholder}
                    />
                ),
                Cell: (data) => {
                    return (
                        <Select
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            value={this.state.skill_ranks.find(
                                (row) => row.value === data.row._original.skill_rank
                            )}
                            onChange={(e) => {
                                this.onChange(e, "skill_rank", data);
                            }}
                            options={this.state.skill_ranks}
                            placeholder={this.props.langText.SelectOption.Placeholder}
                        />
                    );
                }
            },
            {
                id: "memo",
                Header: this.props.langText.Body.Memo,
                accessor: (data) => data.memo,
                Filter: () => (
                    <FormControl
                        onChange={(e) => this.onFilteredChangeCustom(e.target.value, "memo")}
                    />
                ),
                Cell: (data) => {
                    return (
                        <Row className="text-center">
                            {" "}
                            <FormControl
                                key="memo"
                                onChange={(e) => {
                                    this.onChange(e.target, "memo", data);
                                }}
                                value={data.row._original.memo}
                            />
                        </Row>
                    );
                }
            },
            {
                Header: this.props.langText.Body.SkillStatus,
                accessor: "row_status",
                width: this.props.boardWidth.small,
                filterable: false,
                Cell: (data) => {
                    let status = skillStatusList.find((row) => row.value === data.value);
                    return <p className="h5">{status ? status.label : null}</p>;
                }
            },
            {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: (data) => {
                    let rowData = [];

                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize="xsmall"
                                onClick={(event) =>
                                    this.modalShow(event, "delete", data.row._original)
                                }
                                className="control-button">
                                <Glyphicon glyph="minus" />
                            </Button>
                        );
                    }

                    return <Row className="text-center">{rowData}</Row>;
                }
            }
        ];
    };

    // csvインポート用のモーダルカラム設定
    getColumData_insertModal = () => {
        return [
            {
                Header: this.props.langText.Body.OperatorId,
                accessor: "cm16_operators.operator_id"
            },
            {
                Header: this.props.langText.Body.SkillRank,
                width: this.props.boardWidth.xxlarge,
                accessor: "skill_rank"
            },
            {
                Header: this.props.langText.Body.Memo,
                accessor: "memo"
            }
        ];
    };

    onFilteredChangeCustom = (value, accessor) => {
        let my_filtered = [...this.state.filtered],
            target_index = my_filtered.findIndex((row) => row.id === accessor);

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }
        if (accessor !== "skill_rank") {
            value = this.escapeHtml(value);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: accessor,
                value
            });
        }
        this.table.current.state.page = 0;

        this.setState({ filtered: my_filtered });
    };

    onChange(event, param, data) {
        let t_data = [...this.state.data];
        const find_index = t_data.findIndex(
            (row, index) =>
                row.cm16_operators.id === data.row._original.cm16_operators.id
        );

        // 新規作成されたデータの場合、statusをmodifiedに変更しない
        t_data[find_index][param] = event.value;
        if (t_data[find_index].id) {
            t_data[find_index].row_status = "modified";
        }

        this.setState({ data: t_data });
    }

    setDeletedData(cm16_id) {
        let t_data = [...this.state.data];

        // 一括削除の場合
        if (Array.isArray(cm16_id)) {
            cm16_id.forEach((cm16_row_id) => {
                const find_index = t_data.findIndex(
                    (row) => row.cm16_operators.id === parseInt(cm16_row_id)
                );

                if (t_data[find_index].id) {
                    t_data[find_index].row_status = "deleted";
                } else {
                    t_data[find_index].del_flag = "Y";
                }
            });

            let filtered_data = t_data.filter((row) => !row.del_flag);

            this.setState({ data: filtered_data });
        } else {
            const find_index = t_data.findIndex((row) => row.cm16_operators.id === cm16_id);

            if (t_data[find_index].id) {
                t_data[find_index].row_status = "deleted";
            } else {
                t_data.splice(find_index, 1);
            }

            this.setState({ data: t_data });
        }
    }

    async modalShow(event, modalType, param) {
        try {
            event.preventDefault();
            this.setState({
                modalType: modalType,
                show: true
            });
            // フロア有の場合、フロア名を表示する
            if (this.state.task_company_floor_flg) {
                this.setState({ floor_name: this.props.userInfo.floor_name });
            }

            switch (modalType) {
                case "insert":
                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                        // 拠点選択プルダウン用拠点情報を取得する
                        // this.setCommonDepartmentSelect(this.state.task_company_id);
                        this.setCommonDepartmentSelect(
                            this.state.task_company_id,
                            this.state.task_company_floor_flg
                        );
                    }
                    if (
                        (this.props.currentPermission.scope_code === "department" && !this.state.task_company_floor_flg) ||
                        this.props.currentPermission.scope_code === "floor"
                       )
                    {
                        // 【拠点管理者かつフロア無し】または【フロア管理者】の場合、
                        // 初期表示時に所属拠点／フロアに紐づくオペレーターIDを表示
                        this.getOperatorIdSelect({
                            department_id: this.state.department_id,
                            type: modalType
                        });
                    }
                    // 拠点管理者以下の場合、拠点選択無しでフロアリストを取得する
                    if (
                        this.state.task_company_floor_flg &&
                        this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                    ) {
                        this.setCommonFloorSelect({
                            department_id: this.props.userInfo.parent_department_id
                        });
                    }
                    if (
                        this.state.task_company_floor_flg &&
                        this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                    ) {
                        this.setCommonFloorSelect({
                            department_id: this.state.department_id
                        });
                    }
                    break;
                case "delete":
                    this.setState({
                        target_id: param.id,
                        target_task_id: param.cm21_task.id,
                        target_department_id: param.cm16_operators.cm13_id,
                        target_operator: param.cm16_operators,
                        target_skill_rank: param.skill_rank,
                        target_memo: param.memo
                    });
                    break;
                case "multi_delete":
                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                        // 拠点選択プルダウン用拠点情報を取得する
                        this.setCommonDepartmentSelect(
                            this.state.task_company_id,
                            this.state.task_company_floor_flg
                        );
                    }
                    if (
                        (this.props.currentPermission.scope_code === "department" && !this.state.task_company_floor_flg) ||
                        this.props.currentPermission.scope_code === "floor"
                       )
                    {
                        // 【拠点管理者かつフロア無し】または【フロア管理者】の場合、
                        // 初期表示時に所属拠点／フロアに紐づくオペレーターIDを表示
                        this.getOperatorIdSelect({
                            department_id: this.state.department_id,
                            type: modalType
                        });
                    }
                    // 拠点管理者以下の場合、拠点選択無しでフロアリストを取得する
                    if (
                        this.state.task_company_floor_flg &&
                        this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                    ) {
                        this.setCommonFloorSelect({
                            department_id: this.props.userInfo.parent_department_id
                        });
                    }
                    if (
                        this.state.task_company_floor_flg &&
                        this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                    ) {
                        this.setCommonFloorSelect({
                            department_id: this.state.department_id
                        });
                    }
                    break;
                default:
                    break;
            }
        } catch (err) {
            this.unblockUI();
            this.showErrorObjectMesssage(err, "ProcessingFailedError");
        }
    }

    setCommonDepartmentSelect = (company_id, floor_flg) => {
        super.setCommonDepartmentSelect({
            container: "/BusinessManagement",
            company_id: company_id,
            floor_flg: floor_flg
        });
    };

    setCommonFloorSelect = ({ department_id }) => {
        super.setCommonFloorSelect({
            container: "/BusinessManagement",
            departmentIds: department_id
        });
    };

    validationHandle = (param) => {
        let validation_flag = false;

        switch (param) {
            case "insert":
                validation_flag =
                    this.state.task_id &&
                    (this.state.selected_department || this.state.department_id) &&
                    this.state.selected_skill &&
                    this.state.operator_id.length > 0;
                break;
            case "delete":
                validation_flag = this.state.target_department_id && this.state.target_task_id;
                break;
            case "multi_delete":
                validation_flag =
                    (this.state.selected_department || this.state.department_id) &&
                    this.state.operator_id.length > 0;
                break;
            case "csvInsert":
                validation_flag = !!this.state.csvFile;
                break;
            default:
                break;
        }

        return validation_flag;
    };

    onClickHandle = (modalType) => async (event) => {
        let {
            task_id,
            task_company_id,
            operator_id,
            selected_skill,
            memo,
            target_operator,
            csvFile
        } = this.state;

        switch (modalType) {
            case "insert":
                let operators_info = await this.getOperators(operator_id);
                this.insertRowData(task_id, operators_info.data, selected_skill, memo);
                this.setState({ show: false });
                break;
            case "delete":
                if (target_operator.id) {
                    this.setDeletedData(target_operator.id);
                    this.setState({ show: false });
                }
                break;
            case "multi_delete":
                this.setDeletedData(this.state.operator_id);
                this.setState({ show: false });
                break;
            case "csvInsert":
                this.blockUI();
                this.ascAxios("post", `/BusinessManagement/getAllOperator`, {
                    task_company_id: task_company_id
                }).then(async (res) => {
                    let t_data = [...this.state.data];
                    let csv_data = [...csvFile];
                    let csv_header = csv_data.splice(0, 1);

                    // ファイルの有効性チェック
                    let err_msg = await this.CheckFileValidation(res, csvFile, csv_header);

                    // csvデータに問題がなかった場合
                    if (err_msg.length <= 0) {
                        // 既存データ削除チェックボックス処理 → 既存テーブルデータを初期化し、csvデータのみ表示する
                        if (this.state.exchange_flag) {
                            t_data = []; // テーブルデータ初期化

                            csv_data.forEach((row) => {
                                // 当案件の会社に所属されている全オペレーターIDから入力されたcsvのオペレーターIDと一致しているデータを探す
                                let filter_data = res.data.filter(
                                    (operator_info) => operator_info.operator_id === row.data[0]
                                );
                                if (filter_data.length > 0) {
                                    t_data.push({
                                        cm21_task: { id: task_id },
                                        cm16_operators: {
                                            id: parseInt(filter_data[0].id),
                                            cm13_id: parseInt(filter_data[0].cm13_departments.id),
                                            operator_id: filter_data[0].operator_id
                                        },
                                        id: null,
                                        memo: row.data[2],
                                        skill_rank: parseInt(row.data[1]),
                                        row_status: "created"
                                    });
                                }
                            });
                        } else {
                            csv_data.forEach((row) => {
                                // 当案件の会社に所属されている全オペレーターIDから入力されたcsvのオペレーターIDと一致しているデータを探す
                                let filter_data = res.data.filter(
                                    (operator_info) => operator_info.operator_id === row.data[0]
                                );
                                // 既存のオペレーターIDと一致しているデータを取得
                                let match_data = t_data.filter(
                                    (data_row) =>
                                        data_row.cm16_operators.operator_id ===
                                        row.data[0]
                                );

                                // 現在リストにあるデータの場合、新規登録ではなく編集処理する
                                if (match_data.length > 0) {
                                    match_data[0].cm16_operators.cm13_id = parseInt(
                                        filter_data[0].cm13_departments.id
                                    );
                                    match_data[0].cm16_operators.operator_id =
                                        filter_data[0].operator_id;
                                    match_data[0].cm16_operators.id = parseInt(
                                        filter_data[0].id
                                    );
                                    match_data[0].memo = row.data[2];
                                    match_data[0].skill_rank = parseInt(row.data[1]);
                                    match_data[0].row_status = "modified";
                                } else {
                                    if (filter_data.length > 0) {
                                        // 新規登録の場合
                                        t_data.unshift({
                                            cm21_task: { id: task_id },
                                            cm16_operators: {
                                                id: parseInt(filter_data[0].id),
                                                cm13_id: parseInt(
                                                    filter_data[0].cm13_departments.id
                                                ),
                                                operator_id: filter_data[0].operator_id
                                            },
                                            id: null,
                                            memo: row.data[2],
                                            skill_rank: parseInt(row.data[1]),
                                            row_status: "created"
                                        });
                                    }
                                }
                            });
                        }
                        this.setState({ data: t_data, show: false });
                    } else {
                        this.setState({ error_message: err_msg });
                    }
                });
                break;
            default:
                break;
        }
    };

    // ファイルの有効性チェック
    CheckFileValidation(operators, csv_data, csv_header) {
        let err_msg = [];
        let max_skill_rank = 16;
        let headers = csv_header[0].data;
        let origin_header = [];
        let csv_file_data = [...csv_data];

        // ヘッダーデータ排除
        let csv_file_header = csv_file_data.splice(0, 1);
        csv_file_data = csv_file_data.filter((row) => row.data.length > 1);

        this.state.columns.forEach((header) => {
            // 状態・操作カラムをヘッダーから削除
            if (header.Header !== this.props.langText.Body.SkillStatus &&
                header.Header !== this.props.langText.Body.OperatorName &&
                header.Header !== this.props.langText.Body.Control
            ) {
                origin_header.push(header.Header);
            }
        });

        if (JSON.stringify(origin_header) !== JSON.stringify(headers)) {
            err_msg.push(this.props.langText.Message.SkillCsvImportError_Header);
        }

        // 当会社・拠点の管理外のオペレーターID番号を検出
        let invalid_operator_id = csv_file_data.map((file_data, index) => {
            if (
                operators.data.findIndex(
                    (operator_data) => file_data.data[0] === operator_data.operator_id
                ) === -1
            ) {
                return this.sprintf(this.props.langText.Message.SkillCsvImportError_Info, index + 2, file_data.data[0]);
            }
        });

        let invalid_operator_id_data = invalid_operator_id.filter((row) => row !== undefined);

        if (invalid_operator_id_data.length > 0) {
            err_msg.push(
                this.sprintf(this.props.langText.Message.SkillCsvImportError_Unregistered, invalid_operator_id_data)
            );
        }

        // オペレーターID、スキルランクのないデータの検出
        let lack_essential = csv_file_data.map((file_data, index) => {
            if (
                file_data.data[0] === undefined ||
                file_data.data[0] === "" ||
                file_data.data[1] === undefined ||
                file_data.data[1] === ""
            ) {
                return this.sprintf(this.props.langText.Message.SkillCsvImportError_Info, index + 2, file_data.data[0]);
            }
        });

        let lack_essential_data = lack_essential.filter((row) => row !== undefined);

        if (lack_essential_data.length > 0) {
            err_msg.push(
                this.sprintf(this.props.langText.Message.SkillCsvImportError_Required, lack_essential_data)
            );
        }

        // スキルランクの不正な値検出
        let invalid_skill_rank = csv_file_data.map((file_data, index) => {
            if (
                isNaN(file_data.data[1]) === true ||
                parseInt(file_data.data[1]) > max_skill_rank ||
                parseInt(file_data.data[1]) <= 0
            ) {
                return this.sprintf(this.props.langText.Message.SkillCsvImportError_Info, index + 2, file_data.data[0]);
            }
        });

        let invalid_skill_data = invalid_skill_rank.filter((row) => row !== undefined);

        if (invalid_skill_data.length > 0) {
            err_msg.push(
                this.sprintf(this.props.langText.Message.SkillCsvImportError_Max, max_skill_rank, invalid_skill_data)
            );
        }

        return err_msg;
    }

    // 新しいスキルをdata配列に登録
    insertRowData(task_id, operators_info, selected_skill, memo) {
        let t_data = [...this.state.data];
        operators_info.forEach((row) => {
            t_data.unshift({
                cm21_task: { id: task_id },
                cm16_operators: {
                    id: parseInt(row.id),
                    cm13_id: parseInt(row.cm13_id),
                    operator_id: row.operator_id,
                    operator_name: row.operator_name
                },
                id: null,
                memo: memo,
                skill_rank: selected_skill,
                row_status: "created"
            });
        });

        this.setState({ data: t_data });

        return true;
    }

    onChangeDepartment = async (event, param) => {
        if (param === "department_id" && event && event.value) {
            // 当業務会社にフロアがなかった場合、拠点を選択するとオペレーター情報を取得する
            this.getOperatorIdSelect({
                department_id: event.value,
                type: "insert"
            });
            this.setState({ operator_id: [], selected_skill: "" });
        } else if (param === "department_id_for_del" && event && event.value) {
            // 当業務会社にフロアがなかった場合、拠点を選択するとオペレーター情報を取得する
            this.getOperatorIdSelect({
                department_id: event.value,
                type: "delete"
            });
            this.setState({ operator_id: [], selected_skill: "" });
        } else if (event === null) {
            this.setState({
                selected_department: "",
                selected_floor: "",
                operator_id: [],
                selected_skill: ""
            });
        }
    };

    setFloorSelects = async (event, param) => {
        if (this.state.task_company_floor_flg && event && event.value) {
            this.setCommonFloorSelect({ department_id: event.value });
            this.setState({ selected_floor: "", operatorIdSelect: [] });
        }
    };

    getOperatorIdSelect = ({ department_id, type }) => {
        this.ascAxios("post", `/BusinessManagement/operatorIdSelect`, {
            department_id
        })
            .then((res) => {
                let cm16_datas;

                if (type === "insert") {
                    // 登録モーダルの場合、現在リストにないオペレーターIDのみ表示する
                    cm16_datas = res.data.cm16Operators.filter(
                        (row) =>
                            this.state.data.findIndex(
                                (data_row) => data_row.cm16_operators.id === row.value
                            ) === -1
                    );
                } else {
                    // 一括削除モーダルの場合、現在リストにあるオペレーターIDのみ表示する
                    cm16_datas = res.data.cm16Operators.filter((row) =>
                        this.state.data.find(
                            (data_row) => data_row.cm16_operators.id === row.value
                        )
                    );
                }
                let operatorIdSelectData = cm16_datas.map((row) => {
                    let labeldata = (
                        <div>
                            {row.label}
                        </div>
                    );
                    return {
                        search: row.label,
                        label: labeldata,
                        value: row.value
                    };
                });
                this.setState({ operatorIdSelect: operatorIdSelectData });
            })
            .catch((err) => {
                alert(this.getErrorString(err.response.data));
            });
    };

    async getOperators(cm16_ids) {
        return this.ascAxios("post", "/BusinessManagement/getOperators", cm16_ids);
    }

    // 全体テーブル編集項目セーブ
    skillSave(event) {
        let t_data = [...this.state.data];

        // 編集、または追加したテーブルローデータのみ選別する
        let target_data = t_data.filter((row) => row.row_status);

        // テーブルに変更ない駅がなかったら動作しない
        if (target_data.length > 0) {
            this.ascAxios("post", "/BusinessManagement/skillSave", {
                data: t_data,
                task_company_id: this.state.task_company_id
            })
                .then((res) => {
                    this.setState({ refresh_flag: !this.state.refresh_flag });
                    alert(this.props.langText.Message.DataInsertSuccess);
                })
                .catch((err) => {
                    this.showErrorObjectMesssage(err, "DataInsertError");
                });
        }
    }

    filteredData(data, filtered) {
        let table_data = data;
        const filtered_data = [];

        data.map((data_row) => {
            filtered.map((filtered_row) => {
                let sort_id = filtered_row.id;
                let accessor_data = data_row;

                sort_id = sort_id.split(".");

                sort_id.forEach((sort_row) => {
                    accessor_data = accessor_data[sort_row];
                });

                if (filtered_row.value.length > 1) {
                    if (
                        Array.isArray(filtered_row.value) &&
                        accessor_data !== "" &&
                        filtered_row.value.includes(accessor_data)
                    ) {
                        // スキルランクフィルター
                        filtered_data.push(data_row);
                    } else if (accessor_data.toString().includes(filtered_row.value)) {
                        // スキルランク以外の１単語以上フィルター
                        filtered_data.push(data_row);
                    }
                } else {
                    if (
                        accessor_data !== "" &&
                        accessor_data.toString().includes(filtered_row.value.toString())
                    ) {
                        filtered_data.push(data_row);
                    }
                }
            });
        });

        // 中腹データ削除
        const set = new Set(filtered_data);

        const filtered_table_data = [...set];

        return filtered_table_data.length === 0 && filtered.length === 0
            ? table_data
            : filtered_table_data;
    }

    // accessorからidを取得し、該当する列をソートする
    setSortOptions(event) {
        let t_data = [...this.state.data];
        // ソートするidを逆順に処理するため
        let reverse_event = event
            .slice(0)
            .reverse()
            .map((row) => row);

        reverse_event.map((row) => {
            let sort_id = row.id;

            // オペレーターIDの場合、idがcm16_operators.operator_idの形になっているため配列に接近する際、idを.を基準で分離して使う
            sort_id = sort_id.split(".");

            t_data.sort((a, b) => {
                let upperCaseA = a;
                let upperCaseB = b;

                sort_id.forEach((row) => {
                    upperCaseA = upperCaseA[row];
                    upperCaseB = upperCaseB[row];
                });

                if (row.id !== "skill_rank") {
                    upperCaseA = upperCaseA.toUpperCase();
                    upperCaseB = upperCaseB.toUpperCase();
                }

                if (row.desc === false) {
                    if (upperCaseA > upperCaseB) return 1;
                    if (upperCaseA < upperCaseB) return -1;
                    if (upperCaseA === upperCaseB) return 0;
                } else {
                    if (upperCaseA < upperCaseB) return 1;
                    if (upperCaseA > upperCaseB) return -1;
                    if (upperCaseA === upperCaseB) return 0;
                }
            });
        });

        this.setState({ data: t_data });
    }

    createCsvData = async () => {
        try {
            this.blockUI();
            const csv_data = [...this.state.csvData];

            if (csv_data.length === 0) {
                return this.showErrorObjectMesssage({ message: "Download_Failed" });
            } else {
                return this.csvLink.current.link.click();
            }
        } catch (err) {
            return this.showErrorObjectMesssage(err, "DataSelectError");
        } finally {
            this.unblockUI();
        }
    };

    getCsvFileName() {
        const business_name = this.props.location.state
            ? this.props.location.state.display_data.business_name
            : "";
        const now_time = moment().utc().add(9, "h").format("YYYYMMDDhhmmss");
        const file_name = business_name + "_" + now_time + ".csv";

        return file_name;
    }

    // CSVファイルをアップロードまたはドラッグした際の読み込み処理
    handleOnDrop = (data, file) => {
        let jsonData = data;
        let alert_size = this.props.langText.Message.CsvSizeLimit;
        let alert_message = "";
        alert_message = this.sprintf(this.props.langText.Message.Upload_sizeLimit, alert_size);

        // ファイル名チェック
        if (!file.name.match(/.csv$/i)) {
            alert(this.props.langText.Message.NotCsvFile);
            return false;
        }

        if (file.size >= GlobalConst.CSV_IMPORT_SIZE_LIMIT.default) {
            alert(alert_message);
            this.setState({ csvFile: false });
        } else {
            this.setState({ csvFile: jsonData });
            this.setCsvInsertModalData();
        }
    };

    setCsvInsertModalData = () => {
        let file_data = [...this.state.csvFile];
        let insert_modal_data = [];
        let file_header = file_data.splice(0, 1);

        file_data = file_data.filter((row) => row.data.length > 1);
        file_data.forEach((row) => {
            insert_modal_data.push({
                cm21_task: this.state.task_id,
                cm16_operators: {
                    operator_id: row.data[0]
                },
                memo: row.data[2],
                skill_rank: row.data[1]
            });
        });

        this.setState({ insert_csv_data: insert_modal_data });
    };

    // CSVファイルのエラー処理
    handleOnError = () => {
        this.setState({ csvFile: false });
    };

    // アップロード済みのCSVファイルの削除処理
    handleOnRemoveFile = () => {
        this.setState({ csvFile: false, error_message: [] });
    };

    onCheckBoxChange(event, param) {
        super.onCheckBoxChange(event, param);
    }

    getModalBranch = () => {
        switch (this.state.modalType) {
            case "csvInsert":
                return (
                    <SkillCsvImportModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        onClick={this.onClickHandle}
                        onFileChange={this.onFileChange}
                        handleOnDrop={this.handleOnDrop}
                        handleOnError={this.handleOnError}
                        handleOnRemoveFile={this.handleOnRemoveFile}
                        validationHandle={this.validationHandle}
                        onCheckBoxChange={this.onCheckBoxChange}
                    />
                );
            default:
                return (
                    <SkillManagementModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        onChangeDepartment={this.onChangeDepartment}
                        setFloorSelects={this.setFloorSelects}
                        onTextChange_Limit={this.onTextChange_Limit}
                        validationHandle={this.validationHandle}
                        onSelectChange={this.onSelectChange}
                        currentPermission={this.props.currentPermission}
                        onMultiSelectChange={this.onMultiSelectChange}
                    />
                );
        }
    };

    render() {
        return (
            <div className="SkillList">
                <SetBreadCrumb
                    displayItems={[
                        {
                            link: "/BusinessManagement",
                            name: this.props.langText.Body.BusinessManagement
                        },
                        { name: this.state.business_name }
                    ]}
                />
                <Row>
                    <Col sm={12} md={2} lg={12}>
                        <b className="itemCount">総件数：{this.state.data.length}件</b>
                    </Col>
                </Row>
                <Row className="text-danger">
                    <Col xs={12} md={12}>
                        {this.props.langText.Message.SkillSaveMessage}
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} xs={12} md={6} sm={6}>
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor) ? (
                            <Button
                                className="table-button"
                                id="board_InsertButton"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={(event) => this.modalShow(event, "insert")}>
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        ) : null}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor) ? (
                            <Button
                                className="table-button"
                                id="skill_save"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={(event) => this.skillSave(event)}>
                                {this.props.langText.Body.Save}
                            </Button>
                        ) : null}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor) ? (
                            <Button
                                className="table-button"
                                id="multi_delete"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={(event) => this.modalShow(event, "multi_delete")}>
                                {this.props.langText.Body.BatchDelete}
                            </Button>
                        ) : null}
                    </Col>
                    <Col lg={4} xs={12} md={6} sm={6}>
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor) ? (
                            <Button
                                className="table-button pull-right"
                                id="skill_csv_import"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={(event) => this.modalShow(event, "csvInsert")}>
                                {this.props.langText.Body.SkillCsvImport}
                            </Button>
                        ) : null}
                        <Button
                            className="table-button pull-right"
                            id="skill_csv_export"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={(event) => this.createCsvData()}>
                            {this.props.langText.Body.CSVExport}
                        </Button>
                        <CSVLink
                            data={this.state.csvData}
                            headers={this.header_json}
                            filename={this.getCsvFileName()}
                            className="csv-download-link-format hidden"
                            ref={this.csvLink}
                        />
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.filteredData(this.state.data, this.state.filtered)}
                            loading={this.state.loading}
                            filtered={this.state.filtered}
                            filterable={true}
                            onSortedChange={(val) => {
                                this.setSortOptions(val);
                            }}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPagination={false}
                        />
                    </Col>
                </Row>

                {this.getModalBranch()}
            </div>
        );
    }
}
