export default(column_name, lang) => {


    let CompanyControlParametersOption = {
        ADDING_OPTIONS: [
            // 通話録音オプション
            {
                name: "call_record_option",
                label: `${lang.CallRecordingOptionLabel}`,
                function_id: "CALL_RECORD",
                parameter_id: "CALL_RECORD_OPTION",
                parameter_value: "Y",
                memo: `${lang.CallRecordingOptionAllowed}`
            },
            // IVRオプション
            {
                name: "ivr_option",
                label: `${lang.IvrOptionLabel}`,
                function_id: "BUSINESS_CALENDAR",
                parameter_id: "SCRIPT_SELECT",
                parameter_value: "N",
                memo: `${lang.IvrOptionAllowed}`
            },
            // IVR多言語翻訳機能オプション
            {
                name: "ivr_translate_option",
                label: `${lang.OptionLabelTree + lang.IvrTranslateOptionLabel}`,
                function_id: "TRANSLATE",
                parameter_id: "TRANSLATE_OPTION",
                parameter_value: "N",
                memo: `${lang.IvrTranslateOptionAllowed}`
            },
            // 通話履歴オプション
            {
                name: "call_analysis_option",
                label: `${lang.CallAnalysisOptionLabel}`,
                function_id: "CALL_ANALYSIS",
                parameter_id: "CALL_ANALYSIS_OPTION",
                parameter_value: "N",
                memo: `${lang.CallAnalysisOptionAllowed}`
            },
            // 音声品質分析API選択オプション
            {
                name: "call_analysis_api_option",
                label: `${lang.CallAnalysisApiOptionLabel}`,
                function_id: "CALL_ANALYSIS_API",
                parameter_id: "CALL_ANALYSIS_API_OPTION",
                parameter_value: lang.call_analysis_api_select[0].value,
                memo: `${lang.CallAnalysisApiOptionAllowed}`,
                select_option: lang.call_analysis_api_select,
            },
            // 通話分類タグ
            {
                name: "call_auto_tag_option",
                label: `${lang.OptionLabelTree + lang.CallAutoTagOptionLabel}`,
                function_id: "CALL_AUTO_TAG",
                parameter_id: "CALL_AUTO_TAG_OPTION",
                parameter_value: "N",
                memo: `${lang.CallAutoTagOptionAllowed}`
            },
            // 応対品質オプション
            {
                name: "call_eval_option",
                label: `${lang.OptionLabelTree + lang.CallEvalOptionLabel}`,
                function_id: "CALL_EVAL",
                parameter_id: "CALL_EVAL_OPTION",
                parameter_value: "N",
                memo: `${lang.CallEvalOptionAllowed}`
            },
            // ACD機能オプション
            {
                name: "acd_option",
                label: `${lang.AcdOptionLabel}`,
                function_id: "ACD_FUNCTION",
                parameter_id: "ACD_FUNCTION_OPTION",
                parameter_value: "N",
                memo: `${lang.AcdOptionAllowed}`
            },
            // コール結果オプション
            {
                name: "call_result_option",
                label: `${lang.CallResultOptionLabel}`,
                function_id: "CALL_RESULT_RANK",
                parameter_id: "CALL_RESULT_RANK_OPTION",
                parameter_value: "N",
                memo: `${lang.CallResultOptionAllowed}`
            }

        ],
        // 追加オプションラジオボタンの情報
        ADDING_OPTION_SETTING_VALUE_INFOS : [
            {value :"Y",label:"ON"},
            {value :"N",label:"OFF"}
        ]
    };

    return CompanyControlParametersOption[column_name];
}
