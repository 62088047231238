import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Col, Row, Glyphicon, Radio} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import AscAudio from "../Elements/Audio/Audio";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import ExternalNumberScheduleDateTimeElements from "./ExternalNumberScheduleDateTimeElements";


export default class ExternalNumberScheduleOverWaitCallInfosModal extends Component {

    constructor(props) {
        super(props);
    }

    getScheduleOverWaitCallInfosItem() {
        let {
            state,
            langText,
            validationHandle,
            onSelectChange
        } = this.props;

        let itemArr = [];

        // 待ち呼超過時の着信動作
        itemArr.push(
            <InlineForm
                key = "schedule_call_over_action_form"
                controlId = "schedule_call_over_action_form"
                label = {langText.Body.ControlCallOverAction}
                validationState = {validationHandle("over_wait_call_infos_schedule_action")}
            >
                {this.props.propState.callOverActionControlSelect.map((row, index) => (
                    <Radio
                        key = {index}
                        value = {row.value}
                        validationState = {validationHandle("over_wait_call_infos_schedule_action")}
                        onChange = {e => this.props.onRadioChange(e, "over_wait_call_infos_schedule_action")}
                        checked = {row.value === state.over_wait_call_infos_schedule_action}
                    >
                        {row.label}
                    </Radio>))
                }
            </InlineForm>
        );

        if (state.over_wait_call_infos_schedule_action === "playback") {
            // 待ち呼超過音声
            itemArr.push(
                <Row key = "schedule_call_over_sound_id">
                    <Col xs = {6} sm = {6} md = {11} lg = {11}>
                        <InlineForm
                            controlId = "schedule_call_over_sound_id"
                            label = {langText.Body.CallOverSound}
                            type = "select"
                            onChange={e => onSelectChange(e, "over_wait_call_infos_schedule_voice_file_id")}
                            options = {this.props.propState.callOverSoundSelect}
                            value = {state.over_wait_call_infos_schedule_voice_file_id}
                            isClearable = {true}
                            placeholder = {this.props.propState.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                            isDisabled = {!this.props.propState.buttondisabledArr.sound_id[0]}
                        />
                    </Col>
                    <Col xs = {6} sm = {6} md = {1} lg = {1}>
                        <AscAudio
                            type = "url"
                            data = {
                                    this.props.propState.callOverSoundSelect[this.props.propState.callOverSoundSelect.findIndex((v) => v.value === state.over_wait_call_infos_schedule_voice_file_id)] ?
                                    this.props.propState.callOverSoundSelect[this.props.propState.callOverSoundSelect.findIndex((v) => v.value === state.over_wait_call_infos_schedule_voice_file_id)].url
                                    :
                                    this.props.propState.call_over_sound_url
                                }
                            className = "set_audio_button"
                        />
                    </Col>
                </Row>
            );

            // 待ち呼超過時の着信ステータス
            itemArr.push(
                <InlineForm
                    key = "schedule_call_over_action"
                    controlId = "schedule_call_over_action"
                    label = {
                                <SetDataWithSpeechBubble
                                    displayData = {langText.Body.CallOverAction}
                                    speechBubbleData = {langText.Body.CallOverActionExp}
                                    infoIconDisplayFlag = {true}
                                    infoIconType = {faIcon.faInfoCircle}
                                    infoIconClassName = "modal-info-icon"
                                    infoIconColor = "silver"
                                    infoIconSize = "lg"
                                />
                            }
                    type = "select"
                    onChange = {e => onSelectChange(e, "over_wait_call_infos_schedule_call_status")}
                    options = {this.props.propState.callOverActionSelect}
                    value = {state.over_wait_call_infos_schedule_call_status}
                    validationState = {validationHandle("over_wait_call_infos_schedule_call_status")}
                    placeholder = {langText.SelectOption.Placeholder}
                />
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ScheduleOverWaitCallInfosSettings}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <ExternalNumberScheduleDateTimeElements
                            state = {this.props.state}
                            validationHandle = {this.props.validationHandle}
                            langText = {this.props.langText}
                            onDateChangeHandle = {this.props.onDateChangeHandle}
                            scheduleType = "over_wait_call_infos"
                            hourShowList = "hour_show_list"
                            minutesShowList = "minute_every_five_show_list"
                        />
                        {this.getScheduleOverWaitCallInfosItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
