import React from "react"
import Component from "../../components/AscComponent";
import {Panel} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import ExternalNumberScheduleDateTimeElements from "./ExternalNumberScheduleDateTimeElements";


export default class ExternalNumberScheduleDispensingModal extends Component {

    constructor(props) {
        super(props);
    }

    getScheduleDispensingInfoItem() {
        let {
            state,
            langText,
            validationHandle,
            onIntChange,
            charaLimit,
            onKeyDownIntCheck,
            checkDispensingTime,
            checkDispensingCount
        } = this.props;

        let itemArr = [];

        // 秒数
        itemArr.push(
            <InlineForm
                key = "dispensing_time"
                controlId = "dispensing_time"
                label = {langText.Body.SecCnt}
                type = "text"
                onKeyDown = {e => onKeyDownIntCheck(e)}
                onBlur={() => checkDispensingTime()}
                onChange={onIntChange("dispensing_schedule_time",charaLimit.External_dispensing_time)}
                value = {state.dispensing_schedule_time}
                placeholder={langText.Message.ExternalNumber_DispensingTime}
                validationState = {validationHandle("dispensing_schedule_time")}
            />
        );

        if(state.dispensingScheduleTimeValidationFlag){
                itemArr.push(
                <p key="dispensing-time-limit" className="text-danger">
                    {langText.Message.ExternalNumber_DispensingTimeLimit}
                </p>
                );
            }
        ;

        // 着信数
        itemArr.push(
            <InlineForm
                key = "dispensing-count"
                controlId = "dispensing-count"
                label = {langText.Body.InboundCnt}
                type = "text"
                onKeyDown = {e => onKeyDownIntCheck(e)}
                onBlur={() => checkDispensingCount()}
                onChange={onIntChange("dispensing_schedule_count",charaLimit.External_dispensing_count)}
                value = {state.dispensing_schedule_count}
                validationState = {validationHandle("dispensing_schedule_count")}
            />
        );

        if(state.dispensingScheduleCountValidationFlag){
            itemArr.push(
            <p key="dispensing-count-limit" className="text-danger">
                {langText.Message.ExternalNumber_DispensingTimeOverChannelNumber}
            </p>
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ScheduleDispensingSettings}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <ExternalNumberScheduleDateTimeElements
                            state = {this.props.state}
                            validationHandle = {this.props.validationHandle}
                            langText = {this.props.langText}
                            onDateChangeHandle = {this.props.onDateChangeHandle}
                            scheduleType = "dispensing"
                            hourShowList = "hour_show_list"
                            minutesShowList = "minute_show_list"
                        />
                        {this.getScheduleDispensingInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
