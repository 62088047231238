import React from "react";
import { Button, Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import CommonTable from "../../../containers/Elements/Table/CommonTable";
import AscComponent from "../../../components/AscComponent";
import AscAudio from "../../../containers/Elements/Audio/Audio";
import SetBreadCrumb from "../../Elements/AscElements/SetBreadCrumb";
import 'react-datepicker/dist/react-datepicker.css';
import * as GlobalConst from '../../../components/AscConstants';
import ButtonSpeechBubble from "../../Elements/AscElements/SetButtonWithSpeechBubble";
import { CSVLink } from "react-csv";
import HistoryDetailSearchMenu from "./HistoryDetailSearchMenu";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";

const SearchMenu = styled.div`
    width: ${(p) => p.width || 0}px;
    height: ${(p) => p.height || 0}px;
    position: fixed;
    right: 0;
    overflow-y: auto;

    transition: 0.5s;
    background-color: ${(p) => p.background || "#eef3f9"};
    box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
    border-radius: 4px;
    z-index: 1;
`;

export default class HistoryDetail extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            pageSize: null,
            show: false,
            modalType: null,
            loading: false,
            list: true,
            csvData: [],
            searchData: [],
            loading: false,
            searchMenuHeight: window.innerHeight - 235,
            searchMenuWidth: 0,
            menu_flag: false,
            resultSelectOptions: this.getSelectOption('predictive_call_result', this.props.langText.SelectOption),
            resultSelected: [],
            fixedFiltered: [
                {
                    id: "cm80_id",
                    value: this.props.location.state && this.props.location.state.historySummaryData
                        ? this.props.location.state.historySummaryData.id
                        : ""
                },
                {
                    id: "ct77_id",
                    value: this.props.location.state && this.props.location.state.displayData
                        ? this.props.location.state.displayData.id
                        : ""
                },
                {
                    id: "execution_date",
                    value: this.props.location.state && this.props.location.state.displayData
                        ? this.props.location.state.displayData.execution_time.substr(0, 10)
                        : ""
                }
            ],
            filtered: [
                {
                    id: "cm80_id",
                    value: this.props.location.state && this.props.location.state.historySummaryData
                        ? this.props.location.state.historySummaryData.id
                        : ""
                },
                {
                    id: "ct77_id",
                    value: this.props.location.state && this.props.location.state.displayData
                        ? this.props.location.state.displayData.id
                        : ""
                },
                {
                    id: "execution_date",
                    value: this.props.location.state && this.props.location.state.displayData
                        ? this.props.location.state.displayData.execution_time.substr(0, 10)
                        : ""
                }
            ]
        };

        this.table = React.createRef();
        this.csvLink = React.createRef();

        this.headers = [
            {
                label: this.props.langText.Body.DestUserNumber,
                key: "ct60_call_histories.dest_tel_no"
            },
            {
                label: this.props.langText.Body.ExtensionNumber,
                key: "ct60_call_histories.extension_number"
            },
            {
                label: this.props.langText.Body.AutoCallStartDateTime,
                key: "ct60_call_histories.start_datetime"
            },
            {
                label: this.props.langText.Body.AutoCallConnectDateTime,
                key: "ct60_call_histories.answer_datetime"
            },
            {
                label: this.props.langText.Body.AutoCallOperatorAnsweredDateTime,
                key: "connect_datetime"
            },
            {
                label: this.props.langText.Body.AutoCallEndDateTime,
                key: "ct60_call_histories.end_datetime"
            },
            {
                label: this.props.langText.Body.TalkingTime,
                key: "ct60_call_histories.formattedBillsec"
            },
            {
                label: this.props.langText.Body.AutoCallConnectTime,
                key: "connect_time"
            },
            {
                label: this.props.langText.Body.AutoCallOperatorAnsweredTime,
                key: "answered_time"
            },
            {
                label: this.props.langText.Body.AutoCallDisConnectTime,
                key: "disconnect_time"
            },
            {
                label: this.props.langText.Body.Result,
                key: "result"
            }
        ];
    }

    // 初期設定
    componentDidMount = async () => {
        window.addEventListener('resize', this.resizeHandle);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.resizeHandle);
    }

    getColumnsData = () => {

        const resultSelectOptions = this.getSelectOption('predictive_call_result', this.props.langText.SelectOption);

        let columnsDataList = [
            {
                Header: this.props.langText.Body.DestUserNumber,
                accessor: "ct60_call_histories.dest_tel_no",
                filterable: false,
                sortable: false
            },
            {
                Header: this.props.langText.Body.ExtensionNumber,
                accessor: "ct60_call_histories.extension_number",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.AutoCallStartDateTime,
                accessor: "ct60_call_histories.start_datetime",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.AutoCallConnectDateTime,
                accessor: "ct60_call_histories.answer_datetime",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.AutoCallOperatorAnsweredDateTime,
                accessor: "connect_datetime",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.AutoCallEndDateTime,
                accessor: "ct60_call_histories.end_datetime",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.TalkingTime,
                accessor: "ct60_call_histories.formattedBillsec",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.AutoCallConnectTime,
                accessor: "connect_time",
                filterable: false,
                sortable: true,
            },
            {
                Header: this.props.langText.Body.AutoCallOperatorAnsweredTime,
                accessor: "answered_time",
                filterable: false,
                sortable: true,
            },
            {
                Header: this.props.langText.Body.AutoCallDisConnectTime,
                accessor: "disconnect_time",
                filterable: false,
                sortable: true
            },
            {
                Header: this.props.langText.Body.Result,
                accessor: "result",
                filterable: false,
                sortable: true,
                Cell: data => {
                    let resultObj = resultSelectOptions.find(row => row.value === data.value);
                    return resultObj ? resultObj.label : "";
                }
            }
        ];

        let call_record_operation_flag = true;
        let search_company_parameter_call_record = this.getUserCompanyControlParametersData("CALL_RECORD");

        // 会社範囲以下は会社パラメタテーブルから操作フラグを決める
        if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
            && (search_company_parameter_call_record !== null &&
                typeof search_company_parameter_call_record !== "undefined")) {
            //コール履歴「操作」表示フラグ
            if (search_company_parameter_call_record.parameter_value === "N") {
                call_record_operation_flag = false;
            }
        }

        if (call_record_operation_flag){
            //操作
            columnsDataList.push({
                Header: this.props.langText.Body.Control,
                accessor: "ct60_call_histories.voice_log_url",
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];
                    if (
                        data.value &&
                        data.row._original.ct60_call_histories.disposition === "ANSWERED" &&
                        data.row.connect_datetime !== "---"
                    ) {
                        if (this.props.currentPermission.playback && data.value !== "expired") {
                            rowData.push(
                                <AscAudio
                                    key = {"audio" + data.index}
                                    type = "url"
                                    data = {data.value}
                                    className = "control-button"
                                    onPlayHandle={this.onPlayHandle}/>
                            );
                        } else if (this.props.currentPermission.playback && data.value === "expired") {
                            rowData.push(
                                <ButtonSpeechBubble
                                    key = {"audio" + data.index}
                                    bsSize="xsmall"
                                    className="control-button-disabled"
                                    DisplayIcon="play"
                                    speechBubble={this.props.langText.Body.CallHistoryExpired}
                                />
                            );
                        }
                    }
                    return (
                        <Row className = "text-center" key = {"row"+ data.index }>
                            {rowData}
                        </Row>
                    );
                }
            });
        }

        return columnsDataList;
    }

    getUserCompanyControlParametersData(param) {
        let data = null
        if (this.props.userCompanyControlParameters.length > 0) {
            data = this.props.userCompanyControlParameters.find(function(item) {return item.function_id === param})
        }
        return data;
    }

    historyDetailCsvDL = async () => {
        try {
            this.blockUI();
            const resultSelectOptions = this.getSelectOption('predictive_call_result', this.props.langText.SelectOption);
            let result = await this.ascAxios('post', `${this.reactContainerPath}/board`,
                { filtered: this.state.filtered }
            );
            if (result.data.rows.length === 0) {
                return this.showErrorObjectMesssage({message: "Download_Failed"});
            } else {
                let convertedResult = {};
                // 「結果」の値を日本語へ変換
                result.data.rows.forEach(row => {
                    convertedResult = resultSelectOptions.find(options=> options.value === row.result);
                    row.result = convertedResult ? convertedResult.label : "";
                })
                await this.setState({csvData: result.data.rows});
                return this.csvLink.current.link.click();
            }
        } catch (e) {
            console.error(e);
            return this.showErrorObjectMesssage(e, "DataSelectError");
        } finally {
            this.unblockUI();
        }
    }

    getCsvFileName = () => {
        let date = this.getFilterData("start_datetime");
        let file_name = "";
        if (!!date) {
            file_name = moment(this.getFilterData("start_datetime")).format("YYYYMMDD") + "_";
        }
        let stringArr = [];
        stringArr.push(this.state.filtered.find(row => {return row.id === "dest_tel_no";}));
        stringArr.forEach(row => {
            if (row) {
                let value = row.value.value ? row.value.value : row.value;
                file_name = file_name + value + "_";
            }
        })

        let nowTime = moment().utc().add(9, 'h').format("YYYYMMDDhhmmss");

        return file_name + this.props.langText.Body.CallHistoryCsvName + "_" + nowTime + ".csv";
    }

    searchMenuHandle = () => {
        this.setState({
            menu_flag: !this.state.menu_flag
        }, () => {
            this.resizeHandle();
        });
    }

    resizeHandle = () => {
        let {
            menu_flag
        } = this.state;

        let height = window.innerHeight - 235;
        let width = menu_flag ? 450 : 0;

        this.setState({
            searchMenuHeight: height,
            searchMenuWidth: width
        });
    }

    onSearch = (searchMenuFilter) => {
        let filter = this.state.fixedFiltered.concat(searchMenuFilter);

        this.setState({
            filtered: filter
        },()=>{
            //検索時にpageを1ページする
            this.table.current.state.page = 0;
            this.fetchData(this.table.current.state, this.table.current.instance);
        });
    }

    getFilterData(param) {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    render() {
        return (
            <React.Fragment>
                <Col xs={0} sm={0}>
                    <SearchMenu
                        width={this.state.searchMenuWidth}
                        height={this.state.searchMenuHeight}>
                        <HistoryDetailSearchMenu
                            state={this.state}
                            langText={this.props.langText}
                            getFilterData={this.getFilterData}
                            getScopeGreaterEqual={this.getScopeGreaterEqual}
                            onSearch={this.onSearch}
                        />
                    </SearchMenu>
                </Col>
                <div className="search-menu-div">
                    <Button
                        className={`predictive-call-search-menu-button ${this.state.menu_flag ? "open" : ""}`}
                        onClick={() => this.searchMenuHandle()}
                    >
                        {
                            this.state.menu_flag
                            ? this.props.langText.Body.Quotation
                            : this.props.langText.Body.LessThan
                        }
                    </Button>
                </div>
                <Row>
                    <Col>
                        <SetBreadCrumb
                            displayItems={[
                                { link: "/AutoCall", name: this.props.langText.Body.AutoCall },
                                { link: "/AutoCall/PredictiveCall", name: this.props.langText.Body.PredictiveCall },
                                { onClick: () => {
                                    this.props.historyPush({
                                        pathname: "/AutoCall/PredictiveCall/Histories",
                                        state: { displayData: this.props.location.state.historySummaryData}
                                    });
                                }, name: `${this.props.langText.Body.PredictiveCallHistory}（${this.props.location.state.historySummaryData.task_name}）` },
                                { name: `${this.props.langText.Body.PredictiveCallHistoryDetail}（${this.props.location.state.displayData.execution_time}）` }
                            ]}
                        />
                        <Col className="pull-right">
                            <Button
                                id="callhistory-download"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.historyDetailCsvDL}
                            >
                                <FontAwesomeIcon
                                    icon={faIcon.faCloudDownloadAlt}
                                    size="lg"
                                />
                            </Button>
                            <CSVLink
                                data={this.state.csvData}
                                headers={this.headers}
                                filename={this.props.location.state.historySummaryData.task_name + "_" + this.props.location.state.displayData.execution_time}
                                className="hidden"
                                ref={this.csvLink}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <CommonTable
                            className="predictive-call-history-detail-table"
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "ct60_call_histories.start_datetime", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
