import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Col, Row, Glyphicon} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import AscAudio from "../../containers/Elements/Audio/Audio";
import ExternalNumberScheduleDateTimeElements from "./ExternalNumberScheduleDateTimeElements";


export default class ExternalNumberScheduleWaitCallInfosModal extends Component {

    constructor(props) {
        super(props);
    }

    getScheduleWaitCallInfosItem() {
        let {
            state,
            langText,
            validationHandle,
            onIntChange,
            onSelectChange,
            charaLimit,
            onKeyDownIntCheck
        } = this.props;

        let itemArr = [];

        // 最大待ち呼数
        itemArr.push(
            <InlineForm
                key = "wait_call_infos_schedule_time"
                controlId = "wait_call_infos_schedule_time"
                label = {langText.Body.WaitNumber}
                type = "text"
                onKeyDown = {e => onKeyDownIntCheck(e)}
                onChange={onIntChange("wait_call_infos_schedule_wait_number",charaLimit.External_wait_number)}
                value = {state.wait_call_infos_schedule_wait_number}
                validationState = {validationHandle("wait_call_infos_schedule_wait_number")}
            />
        );

        // 待ち呼音声
        itemArr.push(
            <Row key = {"sound_id"}>
                <Col xs = {6} sm = {6} md = {11} lg = {11}>
                    <InlineForm
                        controlId = "sound_id"
                        label = {langText.Body.SoundNumber}
                        type = "select"
                        onChange={e => onSelectChange(e, "wait_call_infos_schedule_voice_file_id")}
                        options = {this.props.propState.waitNumberSoundSelect}
                        value = {state.wait_call_infos_schedule_voice_file_id}
                        isClearable = {true}
                        placeholder = {this.props.propState.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                        isDisabled = {!this.props.propState.buttondisabledArr.sound_id[0]}
                    />
                </Col>
                <Col xs = {6} sm = {6} md = {1} lg = {1}>
                    <AscAudio
                        type = "url"
                        data = {
                                this.props.propState.waitNumberSoundSelect[this.props.propState.waitNumberSoundSelect.findIndex((v) => v.value === state.wait_call_infos_schedule_voice_file_id)]
                                    ? this.props.propState.waitNumberSoundSelect[this.props.propState.waitNumberSoundSelect.findIndex((v) => v.value === state.wait_call_infos_schedule_voice_file_id)].url
                                    : this.props.propState.wait_number_sound_url
                                }
                        className = "set_audio_button"
                    />
                </Col>
            </Row>
        );

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ScheduleWaitCallInfosSettings}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <ExternalNumberScheduleDateTimeElements
                            state = {this.props.state}
                            validationHandle = {this.props.validationHandle}
                            langText = {this.props.langText}
                            onDateChangeHandle = {this.props.onDateChangeHandle}
                            scheduleType = "wait_call_infos"
                            hourShowList = "hour_show_list"
                            minutesShowList = "minute_every_five_show_list"
                        />
                        {this.getScheduleWaitCallInfosItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
