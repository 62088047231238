import React from "react";
import AscComponent from "../../components/AscComponent";
import { Row, Col, Button, ButtonGroup, Glyphicon, Checkbox} from "react-bootstrap";
import InlineForm from "../Elements/FromGroup/InlineForm";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import * as GlobalConst from "../../components/AscConstants";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import CallTagSelect from "../Elements/Select/CallTagSelect";
import KeywordSelect from "../Elements/Select/KeywordSelect";
import TemplateSelect from "../Elements/Select/TemplateSelect";

export default class CallAnalysisMenu extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            search_company_parameter_call_auto_tag: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.state.search_company_parameter_call_auto_tag !== this.props.state.search_company_parameter_call_auto_tag) {
            this.setState({
                search_company_parameter_call_auto_tag: this.props.state.search_company_parameter_call_auto_tag
            });
        }
    }

    getSearchBtn = () => {
        let {
            state,
            onSearch,
            langText,
        } = this.props;

        return (
            <Button
                className="ok-execute-btn button-size"
                bsStyle="info"
                onClick={e => onSearch(state.filtered)}
                disabled={state.loading || state.searchValidation}
            >
                {langText.Body.Search}
            </Button>
        )
    }

    getClearBtn = () => {
        let {
            onClear,
            langText
        } = this.props;

        return (
            <Button
                className="default-button button-size clear-button"
                onClick={e => onClear()}>
                {langText.Body.Clear}
            </Button>
        );
    }

    getHeaderConditionInfo = () => {
        let {
            state,
            langText,
            getFilterData,
            getScopeGreaterEqual,
            onChange,
            modalShow,
            onSelectChange
        } = this.props;

        let itemArr = [];

        // エラーメッセージ
        if (state.message) {
            itemArr.push(
                <Col sm={12} md={12}>
                    <label className="search-message">{state.message}</label>
                </Col>
            );
        }

        // テンプレート
        itemArr.push(
            <InlineForm
                key="templateSelectForm"
                controlId="templateSelectForm"
                label={
                    <div className="template-title-form">
                        <span>{langText.Body.Template}</span>
                        <Button
                            id="template-button"
                            bsStyle='primary'
                            bsSize='sm'
                            onClick={modalShow(null, "templateInsert")}
                            disabled={state.loading || state.searchValidation}
                        >
                            {langText.Body.CallAnalysisTemplateInsert}
                        </Button>
                    </div>
                }
            >
                <TemplateSelect
                    className="padding-bottom-05"
                    state={state}
                    onChange={onSelectChange}
                    langText={langText}
                    modalShow={modalShow}
                />
            </InlineForm>
        );

        // OEM以上は会社選択表示
        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key="company_id"
                    controlId="company_id"
                    className="padding-bottom-05"
                    label={langText.Body.CompanyName}
                    type="select"
                    onChange={e => onChange(e, "cm12_id")}
                    options={state.companySelect}
                    value={getFilterData("cm12_id") ? getFilterData("cm12_id").value : ""}
                    placeholder={!state.companySelect[0] ?
                        <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder
                    }
                />
            );
        }

        // システム管理者は通話履歴ID検索表示
        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
            itemArr.push(
                <Col sm={12} md={12} className="padding-left-0 padding-right-0 padding-bottom-05">
                    <InlineForm
                        key="CallHistoryId"
                        controlId="CallHistoryId"
                        label={
                            <SetDataWithSpeechBubble
                                displayData={langText.Body.CallHistoryId}
                                speechBubbleData={langText.Message.IdOnlySearch}
                                infoIconDisplayFlag={true}
                                infoIconType={faIcon.faInfoCircle}
                                infoIconClassName="margin-left-05"
                                infoIconColor="silver"
                                infoIconSize="lg"
                            />
                        }
                        onChange={e => onChange(e, "ct60_id")}
                        value={getFilterData("ct60_id")}
                    />
                </Col>
            );
        }

        return itemArr;
    }

    getConditionInfo = () => {
        let {
            state,
            langText,
            getFilterData,
            onChange,
            hourShowList,
            minuteShowList,
            onInputChangeHandle,
            propSetState,
            createTooltipContent
        } = this.props;

        let itemArr = [];

        // 外線番号
        itemArr.push(
            <InlineForm
                key="ExternalNumberForm"
                controlId="ExternalNumberForm"
                label={langText.Body.ExternalNumber}
            >
                <Col className="padding-bottom-35 summary-period">
                    <InlineForm 
                        key="ExternalNumber"
                        controlId="ExternalNumber"
                        type="select"
                        value={getFilterData("display_number") ? getFilterData("display_number").value : ""}
                        onChange={e => onChange(e, "display_number")}
                        options={state.externalArr}
                        placeholder={langText.SelectOption.Placeholder_defaultexternal}
                        colWidth={12}
                    />    
                </Col>  
            </InlineForm>
        );

        // 開始日
        itemArr.push(
            <InlineForm
                key="StartDatetime"
                controlId="StartDatetime"
                label={langText.Body.StartDate}
            >
                <Col className="padding-bottom-05">
                    <Col sm={12} md={6} lg={6} className="padding-left-0 padding-right-0">
                        <DatePicker
                            onChange={e => onChange(e, "start_datetime")}
                            selected={getFilterData("start_datetime") ? getFilterData("start_datetime") : null}
                            dateFormat="YYYY-MM-DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            placeholderText={langText.Body.StartDate}
                            maxDate={getFilterData("end_datetime") ? getFilterData("end_datetime") : null}
                        />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="padding-right-0">
                        <Select
                            key="start_ji"
                            value={getFilterData("start_ji")}
                            onChange={
                                e => onChange(e, "start_ji")
                            }
                            options={hourShowList}
                            isDisabled={(!state.filtered.some(row => row.id === "start_datetime"))}
                            placeholder="---"
                        />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="padding-right-0 padding-bottom-05">
                        <Select
                            key="start_hun"
                            value={getFilterData("start_hun")}
                            onChange={
                                e => onChange(e, "start_hun")
                            }
                            options={minuteShowList}
                            isDisabled={(!state.filtered.some(row => row.id === "start_datetime"))}
                            placeholder="---"
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        // 終了日
        itemArr.push(
            <InlineForm
                key="EndDatetime"
                controlId="EndDatetime"
                label={langText.Body.EndDate}
            >
                <Col className="padding-bottom-05">
                    <Col sm={12} md={6} lg={6} className="padding-left-0 padding-right-0">
                        <DatePicker
                            onChange={e => onChange(e, "end_datetime")}
                            selected={getFilterData("end_datetime") ? getFilterData("end_datetime") : null}
                            dateFormat="YYYY-MM-DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            placeholderText={langText.Body.EndDate}
                            minDate={getFilterData("start_datetime") ? getFilterData("start_datetime") : null}
                        />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="padding-right-0">
                        <Select
                            key="end_ji"
                            value={getFilterData("end_ji")}
                            onChange={
                                e => onChange(e, "end_ji")
                            }
                            options={hourShowList}
                            isDisabled={(!state.filtered.some(row => row.id === "end_datetime"))}
                            placeholder="---"
                        />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="padding-right-0 padding-bottom-05">
                        <Select
                            key="end_hun"
                            value={getFilterData("end_hun")}
                            onChange={
                                e => onChange(e, "end_hun")
                            }
                            options={minuteShowList}
                            isDisabled={(!state.filtered.some(row => row.id === "end_datetime"))}
                            placeholder="---"
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        // キーワード
        itemArr.push(
            <InlineForm
                key="KeywordFrom"
                controlId="KeywordFrom"
                label={
                    <SetDataWithSpeechBubble 
                        displayData={langText.Body.Keyword}
                        speechBubbleData={createTooltipContent("KeywordRegExpDescription")}
                        infoIconDisplayFlag={true}
                        infoIconType={faIcon.faInfoCircle}
                        infoIconClassName="margin-left-05"
                        infoIconColor="silver"
                        infoIconSize="lg"
                        placement="top"
                        custom="custom"
                    />
                }
            >
                <Col className="padding-bottom-05">
                    <KeywordSelect
                        state={state}
                        onChange={onChange}
                        langText={langText}
                        onInputChangeHandle={onInputChangeHandle}
                        propSetState={propSetState}
                    />
                </Col>
                <Col>
                    <Col sm={12} md={7} lg={7} className="padding-left-0 padding-bottom-05">
                        <Select
                            onChange={e => onChange(e, "keyword_term")}
                            options={langText.SelectOption.KeywordTermOption}
                            value={getFilterData("keyword_term")}
                        />
                    </Col>
                    <Col sm={12} md={5} lg={5} className="padding-left-0 padding-bottom-05">
                        <Checkbox
                            key="PhraseSearch"
                            checked={getFilterData("phrase_search_flag")}
                            onChange={e => onChange(e, "phrase_search_flag")}
                        >
                            {langText.Body.PhraseSearch}
                        </Checkbox>
                    </Col>
                </Col>
                <Col>
                    <ButtonGroup className="analysis-btn padding-bottom-05">
                        <Button
                            value="all"
                            onClick={e => onChange(e , "speech_person")}
                            className={state.filtered.map(row => row.id === "speech_person" && row.value === "all" ? "analysis-btn-focus":"")}
                        >
                            {langText.Body.AllSpeechPeople}
                        </Button>
                        <Button
                            value="out"
                            onClick={e => onChange(e , "speech_person")}
                            className={`${state.filtered.some(row => row.id === "speech_person" && row.value === "out") ? "analysis-btn-focus":""}`}
                        >
                            {langText.Body.SpeechOperator}
                        </Button>
                        <Button
                            value="in"
                            onClick={e => onChange(e , "speech_person")}
                            className={`${state.filtered.some(row => row.id === "speech_person" && row.value === "in") ? "analysis-btn-focus":""}`}
                        >
                            {langText.Body.Customer}
                        </Button>
                    </ButtonGroup>
                </Col>
            </InlineForm>
        );

        // 通話分類タグ
        if (this.state.search_company_parameter_call_auto_tag) {
            itemArr.push(
                <InlineForm
                    key="CallCategoryTag"
                    controlId="CallCategoryTag"
                    label={langText.Body.CallCategoryTag}
                >
                    <Col className="padding-bottom-05">
                        <CallTagSelect
                            options={state.tagSelect}
                            onChange={onChange}
                            value={getFilterData("tag")}
                            langText={langText}
                            param={"tag"}
                        />
                    </Col>
                </InlineForm>
            );
        }

        return itemArr;
    }

    // 詳細条件
    getDetailConditionInfo = () => {
        let {
            state,
            langText,
            getFilterData,
            onChange,
            onClickAccordion,
            onKeyDownIntCheck,
            onTimeChange
        } = this.props;

        let itemArr = [];

        // 発信区分
        itemArr.push(
            <Accordion allowZeroExpanded>
                <AccordionItem key="detailSettings" uuid="detailSettings">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div onClick={(e) => onClickAccordion()} className="padding-left-10 fa-11">
                                <span className="accordion-menu font-blue">
                                    {state.accordion_flag ? langText.Body.LessThan : langText.Body.Quotation}
                                </span>
                                <b className="font-blue">{langText.Body.DetailCondition}</b>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {/* 発信区分 */}
                        <InlineForm
                            key="InOutType"
                            controlId="InOutType"
                            label={langText.Body.InOutType}
                        >
                            <ButtonGroup className="analysis-btn padding-bottom-05">
                                <Button
                                    value="all"
                                    onClick={e => onChange(e , "inout_type")}
                                    className={state.filtered.map(row => row.id === "inout_type" && row.value === "all" ? "analysis-btn-focus":"")}
                                >
                                    {langText.Body.All}
                                </Button>
                                <Button
                                    value="in"
                                    onClick={e => onChange(e , "inout_type")}
                                    className={`${state.filtered.some(row => row.id === "inout_type" && row.value === "in") ? "analysis-btn-focus":""}`}
                                >
                                    {langText.Body.Inbound}
                                </Button>
                                <Button
                                    value="out"
                                    onClick={e => onChange(e , "inout_type")}
                                    className={`${state.filtered.some(row => row.id === "inout_type" && row.value === "out") ? "analysis-btn-focus":""}`}
                                >
                                    {langText.Body.Call}
                                </Button>
                            </ButtonGroup>
                        </InlineForm>
                        {/* 確認 */}
                        <InlineForm
                            key="Check"
                            controlId="Check"
                            label={langText.Body.Check}
                        >
                            <ButtonGroup className="analysis-btn padding-bottom-05">
                                <Button
                                    value="all"
                                    onClick={e => onChange(e , "check_flag")}
                                    className={state.filtered.map(row => row.id === "check_flag" && row.value === "all" ? "analysis-btn-focus":"")} 
                                >
                                    {langText.Body.All}
                                </Button>
                                <Button
                                    value="true"
                                    onClick={e => onChange(e , "check_flag")}
                                    className={state.filtered.map(row => row.id === "check_flag" && row.value === "true" ? "analysis-btn-focus":"")} 
                                >
                                    {langText.Body.Confirmed}
                                </Button>
                                <Button
                                    value="false"
                                    onClick={e => onChange(e , "check_flag")}
                                    className={state.filtered.map(row => row.id === "check_flag" && row.value === "false" ? "analysis-btn-focus":"")} 
                                >
                                    {langText.Body.Unconfirmed}
                                </Button>
                            </ButtonGroup>
                        </InlineForm>
                        {/* 通話時間 */}
                            <InlineForm
                                key="TalkingTime"
                                controlId="TalkingTime"
                                label={langText.Body.TalkingTime}
                            >
                                <Col sm={12} md={12} className="call-time">
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="CallStartHun"
                                            controlId="CallStartHun"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_start_hun")}
                                            value={getFilterData("call_start_hun")}
                                            placeholder={langText.Body.Minute}
                                        />
                                    </Col>
                                    <Col sm={12} md={1} lg={1} className="padding-left-0 padding-right-0 colon">
                                        <span>{langText.Body.Colon}</span>
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="CallStartByo"
                                            controlId="CallStartByo"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_start_byo")}
                                            value={getFilterData("call_start_byo")}
                                            placeholder={langText.Body.Second}
                                        />  
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0 from-to">
                                        <span>{langText.Body.FromTo}</span>
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="CallEndHun"
                                            controlId="CallEndHun"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_end_hun")}
                                            value={getFilterData("call_end_hun")}
                                            placeholder={langText.Body.Minute}
                                        />
                                    </Col>  
                                    <Col sm={12} md={1} lg={1} className="padding-left-0 padding-right-0 colon">
                                        <span>{langText.Body.Colon}</span>
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="CallEndByo"
                                            controlId="CallEndByo"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_end_byo")}
                                            value={getFilterData("call_end_byo")}
                                            placeholder={langText.Body.Second}
                                        />
                                    </Col>    
                                </Col>
                            </InlineForm>
                        {/* 相手先番号 */}
                        <Col sm={12} md={12} className="padding-left-0 padding-right-0 padding-bottom-05">
                            <InlineForm
                                key="DestUserNumber"
                                controlId="DestUserNumber"
                                label={langText.Body.DestUserNumber}
                                onChange={e => onChange(e, "dest_tel_no")}
                                value={getFilterData("dest_tel_no")}
                            />                        
                        </Col>
                        {/* コメント */}
                        <InlineForm
                            key="Comment"
                            controlId="Comment"
                            label={langText.Body.Comment}
                            onChange={e => onChange(e, "comment")}
                            value={getFilterData("comment")}
                        />
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );

        return itemArr;
    }

    render() {
        return (
            <Row className="menu-row">
                <Col className="title-col col-style" xs={12} sm={12}>
                    <div className="margin-left-05 margin-top-05">
                        {this.getClearBtn()}
                        {this.getSearchBtn()}
                    </div>
                    <div>
                        {this.getHeaderConditionInfo()}
                        <hr className="menu-hr" />
                    </div>
                </Col>
                <Col className="col-style padding-top-0" xs={12} sm={12}>
                    {this.getConditionInfo()}
                    {this.getDetailConditionInfo()}
                </Col>
            </Row>
        );
    }
}
